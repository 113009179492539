@import '/src/variables';

.container-job-details {
  width: 1200px;

  .job-card {
    transition: all 0.5 linear;

    &.card-blue-color {
      background: #f3f8ff;
    }

    &.shadow {
      box-shadow: 0 0 3px 0 rgb(163 163 163 / 100%) !important;
    }
    padding: 30px;
  }
}

.bg-gray {
  background: #f9f9f9;
}

.border-left-5 {
  border-left: 3px solid blue;
}

.education-icon {
  width: 50px;
  height: 50px;
  background: #f9f9f9;
}

.overflow-scroll-cs {
  overflow-x: scroll;
  width: 1200px;
  height: calc(100vh - 20px);
  padding: 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
    border: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }
}

.verified-row {
  background: #f9f9f9;

  .verify-icon {
    width: 34px;
    height: 34px;
  }
}

.theme-color-bg {
  background-color: $palette-error-main;
}

.theme-color-text {
  color: $palette-error-main;
}

.theme-color-text-white {
  color: $palette-title-bar-main !important;
}

.capitalize-text {
  text-transform: capitalize;
}

.image-thumb {
  width: 150px;
  max-width: 150px;
  padding: 0 10px;
  min-height: 120px;
  background: #f8f8f8;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  .card-img {
    width: 100px;
    height: 100px;
  }
}

.elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elipsis-line {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.auto-height-client-portal {
  overflow-x: scroll;
  max-height: calc(100vh - 254px);
  row-gap: unset !important;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
    border: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }
}

.skeleton {
  width: 100%;
  height: 200px;
  padding: 50px;
  margin: 0;
  margin-bottom: 50px;
  border-radius: 6px;
  background: linear-gradient(170deg, #f9f9f9 40%, #fff, #f9f9f9 60%) right / 300% 100%;
  animation: skeleton-loading 1.5s linear infinite;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200deg 20% 80%);
  }

  100% {
    background-color: hsl(200deg 20% 95%);
  }
}

.icon-circle {
  background: #333;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.white {
  color: #fff;
}

.chip-primary {
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  height: 18px;
  margin-top: -3px;
}

.t-dot {
  color: #999;
  padding: 0 5px;
}

.t-dot-2 {
  color: #999;
  padding: 0 8px;
}

.profile-image-container {
  width: 200px;

  .card-big-image {
    width: 120px;
    height: 120px;
  }

  .card-big-image-small {
    width: 56px;
    height: 56px;
  }
}

.sticky-top-header {
  top: -10px;
}

.pad-top-container {
  padding-top: 50px !important;
}

.opacity-70 {
  opacity: 0.7;
}

.toolbar {
  position: absolute;
  bottom: 30px;
  left: 0;

  .toolbar-card {
    width: 1176px;
    margin-left: 8px;

    .circle-text {
      width: 80px;
      height: 40px;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 12px;
      border-radius: 10px;
      border: 2px solid #eee;
      color: #fff;
    }

    .button-style {
      color: #fff;
      min-width: 200px;

      &:hover {
        box-shadow: none !important;
        background: transparent;
      }

      &:disabled {
        opacity: 0.6;
      }
    }
  }
}

.overview-scroll-parent.scrolled-parent {
  padding-bottom: 23px;

  .scrolled {
    height: 110px;

    .hide-on-scroll {
      visibility: hidden !important;
      opacity: 0;
    }
  }
}

.job-card.scrolled-2 {
  margin-top: 120px;
}

#client-suite-drawer {
  .MuiPaper-elevation {
    max-width: 1230px;
  }
}

.no-feedback {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.chip-status {
  padding-left: 10px !important;
  padding-right: 10px !important;
  display: inline-block;
  border: 1px solid transparent;
  border-left-width: 8px;
}
