@import '/src/variables';

.help-icon-button {
  // transform: scale(0.9);
}

.help-icon {
  color: $mui-help-icon;
  width: 27px;
}
