@import '/src/variables';

.roles-back-button {
  color: $palette-primary;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#input-wrapper {
  width: 30vw;
  border: 1px solid $cool-gray;
  background-color: $white;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: $tera-cotta;
  }

  &.focused {
    border-color: $tera-cotta;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  }

  & input {
    background-color: $white;
    color: $dark-gray;
    height: 35px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
}

#role-listbox {
  width: 30vw;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: $white;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;
    cursor: pointer;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: $notification-menu-background;
    font-weight: 600;

    & svg {
      color: $tera-cotta;
    }
  }

  & li.focused {
    background-color: $notification-menu-background;
    cursor: pointer;

    & svg {
      color: $tera-cotta;
    }
  }
}

.role-tag {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 2px;
  line-height: 22px;
  background-color: $notification-menu-background;
  border: 1px solid $switch-back-gray;
  border-radius: 20px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
}
