@import '/src/variables';
.radio-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.radio-button-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio-button-item label {
  width: 100%;
  text-align: center;
}

.line-horizontal {
  min-width: 2%;
  height: 3px;
  margin-top: 25px;
}

.radio-button-item input[type='radio'] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.radio-button-item input[type='radio'] + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  height: 16px;
  border: 1px solid $black;
  border-radius: 50%;
}

.radio-button-item input[type='radio']:checked + label:before {
  background-color: $black;
}
