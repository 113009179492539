@import '/src/variables';

#team-info {
  overflow: auto;

  .tab-view-content {
    flex: 2 0;

    // .team-info-table-wrapper{
    //     max-height: 425px;
    //   }
  }

  // .team-info-table {
  //   border: none;
  //   background: $white;
  //   box-shadow: 0 0 8px $box-shadow-team;
  //   overflow: hidden;
  //   border-radius: $border-radius;
  //   white-space: nowrap;
  // }

  .accordion {
    background-color: $palette-primary-main;
  }

  .title-text {
    color: $white;
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: $white;
  }

  .profile-image-container {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .image-containers {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $image-border;
    display: flex;
    justify-content: center;
  }

  .css-vn73je-MuiTableCell-root {
    text-align: center;
  }

  .table-header {
    background: $content-background;
    font-weight: 700;
    line-height: 18px;
    color: $palette-heading-main;
    text-align: center;
  }

  .close-icon {
    color: $palette-error-main;
  }

  .total-content {
    color: $gray;
  }

  .css-1s1ppv9-MuiFormHelperText-root.Mui-error {
    color: $error-color;
  }

  .validation-error {
    .sum-value {
      color: $error-color;
    }
  }

  .text-red {
    color: $error-color;
  }
}

#education-experiences .DatePickerStyle .MuiInputBase-root {
  width: 18vw !important;
}

#education-experiences .billing-notes-field .MuiInputBase-root {
  width: 38vw;
}
