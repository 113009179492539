@import '/src/variables';

.header-component {
  background-color: $palette-primary-main;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.header-logo {
  // width: 10vw;
  color: $white;
  height: auto;
}
