.contact-notes {
  margin-bottom: 20px;
}

.popup-header {
  padding: 20px 30px;
  background: #f5f5f5;
}

.popup-height {
  max-height: 550px !important;
}
