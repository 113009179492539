.rich-text-popup-content .rich-text-popup-header {
  text-align: left !important;
}

.footer-text {
  color: rgb(255 165 0);
  font-style: italic;
  font-size: 12px;
}

.confirmation-projectDate-popup-content.confirmation-projectDate {
  width: 60% !important;
  padding: 0 !important;
  background: #f4f4f4 !important;
  overflow: auto;
}
