@import '/src/variables';

#billing-client-info {
  .accordion {
    background-color: $palette-primary-main;
  }

  .title-text {
    color: $white;
  }

  .MuiAccordionSummary-root {
    width: 100%;
    height: 10px;
    margin-bottom: 10px;
  }

  .css-qh07hp-MuiPaper-root-MuiAccordion-root {
    margin: 16px 0;
  }
}
