@import '/src/variables';

.sub-tab-layout {
  background-color: $white;
}

.drawer-tab-layout {
  height: 52vh;
}

.drawer-tab-layout-close {
  height: 69vh;
}

#tab-layout {
  text-transform: 'none';

  .tab-background {
    background-color: $palette-bg-main !important;
    border-radius: $border-radius $border-radius 0 0;
  }

  .active-tab {
    min-width: 10vw;
    font-weight: bold;
    border-radius: $border-radius $border-radius 0 0;
    background: $palette-secondary-main !important;
    color: $white !important;

    &:hover {
      color: $white !important;
    }
  }

  .non-active-tab {
    min-width: 10vw;
    font-weight: normal;
    color: $cool-gray;
  }

  .non-active-tab-drawer {
    max-width: 10vw;
    height: 40px;
    overflow-y: hidden;
    font-weight: normal;
    color: $cool-gray;
  }

  .active-tab-drawer {
    height: 40px;
    overflow-y: hidden;
    font-weight: bold;
    color: $palette-heading-main !important;
    border-radius: $border-radius $border-radius 0 0;
    box-shadow: 4px 0 8px $box-shadow;
    background-color: $white;
  }

  .MuiBox-root {
    .sub-tab-layout {
      .MuiTabs-root {
        min-height: 38px !important;

        .MuiTabs-scroller {
          .MuiTabs-flexContainer {
            margin-top: 10px;

            button.MuiButtonBase-root {
              min-width: 7rem;
              max-width: unset;
              min-height: 38px !important;
              font-size: 0.8rem;

              &:hover {
                color: $palette-heading-main !important;
              }
            }
          }
        }
      }
    }
  }
}

#sub-tab {
  .drawer-tab {
    text-transform: none;
    min-width: 10vw;
    font-size: 16px;
    font-weight: normal;
    background-color: $white;
    color: $cool-gray;

    &:hover {
      color: $palette-heading-main !important;
      opacity: 1;
    }

    &.Mui-selected {
      color: $palette-heading-main !important;
      font-weight: bold;
    }

    &:focus {
      color: $palette-heading-main !important;
    }
  }

  .non-drawer-tab {
    text-transform: none;
    font-weight: 400;
    min-width: 10vw;
    overflow-y: auto;
    background-color: $white;
    color: $cool-gray;

    &:hover {
      color: $palette-heading-main !important;
      opacity: 1;
    }

    &.Mui-selected {
      color: $palette-heading-main !important;
      font-weight: bold;
    }

    &:focus {
      color: $palette-heading-main !important;
    }
  }
}

.personality-modal-textbox,
.create-contact-hilighlight,
.rich-text-modal-action-box,
.client-portal-rating-modal,
.competency-editor-section,
.position-profile-rich-text,
.education-input-base {
  #CustomRichText {
    .react-quill-container {
      .MuiStack-root {
        top: 4px;
        width: 50%;
        border: 0;

        .MuiStack-root {
          width: 100%;
        }
      }

      .quill {
        .ql-toolbar {
          &:hover {
            background: transparent !important;
          }
        }

        .ql-container {
          border-top: 1px solid #e0e4ec !important;

          .ql-editor {
            padding-top: 10px !important;

            &:focus {
              background: transparent !important;
            }
          }
        }
      }
    }
  }
}
