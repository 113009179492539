@import '/src/variables';

.customButton {
  .btn {
    cursor: pointer;
    border-radius: $border-radius;
  }

  .btn:focus {
    box-shadow: none;
  }

  .small {
    height: 32px;
  }

  .medium {
    height: 42px;
  }

  .large {
    height: 52px;
  }

  .primary {
    background-color: $palette-primary-main;
    color: $white;
    border: 1px solid $palette-primary-main;
  }

  .error {
    background-color: $error-color;
    color: $white;
    border: 1px solid $error-color;
  }

  .success {
    background-color: $success-green;
    color: $white;
    border: 1px solid $success-green;
  }

  .outline-primary {
    background-color: $white;
    color: $palette-primary-main;
    border: 1px solid $palette-primary-main;

    &:hover {
      background-color: $palette-primary-main;
      color: $white;
    }
  }

  .secondary {
    border: 1px solid $palette-primary-main;
    color: $palette-primary-main;
  }

  .secondary:hover {
    background-color: $palette-primary-main;
    color: $white;
  }

  .tertiary-error {
    color: $error-color;
  }

  .tertiary-error:hover {
    background-color: $white;
    color: $error-color;
  }

  .tertiary-error-filled {
    color: $white;
    background-color: $reddish-orange;
  }

  .tertiary {
    color: $palette-primary-main;
    box-shadow: none;
  }

  .tertiary:hover {
    // color: $black !important;
    background-color: $white;
    box-shadow: none;
  }

  .danger {
    border: 1px solid $error-color;
    color: $error-color;
  }

  .danger:hover {
    background-color: $error-color;
    color: $white;
  }
}
