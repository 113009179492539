@import '/src/variables';

#contact-search {
  padding: 0 16px;
  border-radius: 4px;
  background-color: rgb(149 159 183 / 9%);
  box-sizing: border-box;
  color: #a8a8a8;
  text-align: left;
  display: flex;
  height: 40px;

  //  margin-left: 20px;

  .loader-class {
    color: $palette-primary-main !important;
  }

  .search-icon {
    height: 40px;
    width: 40px;
    padding: 0;
    border: 1px solid #dddde2;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .search {
    box-sizing: border-box;
    color: #a8a8a8;
    width: 255px;
    padding: 6px;
    font-size: 14;
  }

  .search:disabled {
    background-color: #f4f4f4;
    box-sizing: border-box;
    color: #c6c6c6;
  }

  .grid-search {
    top: 10;
    width: 300;
    height: 40;
    font-size: 13;
  }
}

.header_search {
  display: flex;
  justify-content: space-around;
  margin: 0 1rem;
}

.theme-bg-white {
  background-color: $palette-title-bar-main !important;
}

.project-user-sub-footer-text {
  font-size: 0.7rem;
  line-height: 1;
}

.accordion-header {
  background-color: $palette-primary-main;
  color: $palette-title-bar-main;
}

.accordion-expand-icon {
  color: $palette-title-bar-main !important;
}

.accordion-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab-layout-content {
  #view-company {
    width: 100%;

    .communication-section {
      width: 100%;

      .MuiBox-root {
        width: 100%;

        .field-label-input {
          width: 100%;
        }
      }
    }
  }
}
