@import '/src/variables';

#view-more-title {
  display: flex;
  justify-content: space-between;

  .delete-icons {
    color: $palette-error-main;
    cursor: pointer;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;

    img {
      width: 14px !important;
    }
  }

  .edit-icon {
    max-width: 14px;
    max-height: 14px;

    img {
      color: $white;
    }

    .MuiSvgIcon-root {
      height: 14px;
    }
  }

  .security-icon {
    padding: inherit;
    float: right;
    cursor: default;

    img {
      height: 16px;
      width: 16px;
    }
  }

  .green-bg {
    background-color: $palette-primary-main !important;
    border-radius: $border-radius !important;
    color: $white !important;
  }
}

.content-value {
  max-width: 40vw;
  max-height: 80vh;
  min-width: 35vw;
}
