@import '/src/variables';

.contact-pipeline {
  background-color: $white;
  border-radius: 0.3rem;

  // margin-left: 0.3rem;
  color: $white;
}

.disabled-linkedin-link {
  fill: $gray;
  cursor: not-allowed;
}

.enabled-linkedin-link {
  fill: $dark-cerulean;
}

.stage-text {
  font-size: 12px;
}

.name-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.red {
  color: $error-color;
}

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 4px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0.5rem 0 0;
}

.stage-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.3rem;
  margin: 0.2rem;
  padding: 0.8rem 0.6rem;
  cursor: pointer;
}

.stage-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  color: $white;
  margin-bottom: 1rem;
  border-radius: 3px;
}

#myGrid.candidate-pipeline-grid {
  // height: calc(100vh - 370px);
}

.ag-grid-container {
  // padding: 0.7rem 0.3rem;
  height: 95%;
  width: 100%;
}

.white-divider {
  background-color: $white;
  margin: 0 0.5rem;
}

.container-secondary {
  height: 97%;
  background-color: $white;
  box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
  margin: 1.2rem 0 0 0.5rem;
}

.margin7 {
  margin: 0.7rem;
}

.margin5 {
  margin: 0.5rem;
}

.stage-drop-down {
  color: $white !important;
  border-radius: 20px;
  height: 24px;
  margin: 0;
  border: 0;
  font-size: 14px;
}

.stage-drop-down svg {
  color: #fff;
}

.font-white {
  color: $white;
}

.flex-center-w-full {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.circle-avatar {
  width: 50;
  height: 50;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-color: darkgray;
}

.grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

// .ag-theme-alpine .ag-filter {
//   width: fit-content;
// }

// .ag-theme-alpine .ag-react-container {
//   display: block;
// }

/* Ensure the filter menu button is visible */
// .ag-theme-alpine .ag-header-icon {
//   opacity: 1 !important;
//   color: $white;
// }

/* Ensure the filter menu button is visible */
// .ag-theme-alpine .ag-header-icon:hover {
//   opacity: 1 !important;
//   color: $white;
//   cursor: pointer;
// }

.rich-text-popup-container {
  .ql-editor {
    min-height: auto;
  }
}

.MuiFormControl-root.productTag {
  .MuiInputBase-root .MuiChip-root {
    height: auto;

    .MuiChip-label {
      font-size: 0.9rem;
    }
  }
}

.MuiMenu-paper {
  .MuiList-root.MuiMenu-list {
    .MuiButtonBase-root.MuiMenuItem-root {
      font-size: 0.9rem;
    }
  }
}

.icon-font-size {
  font-size: 28px;
  margin-right: 10px;

  @media screen and (width <= 992px) {
    font-size: 22px;
  }
}

.project-Avatar {
  width: 94px;
  height: 94px;

  @media screen and (width <= 992px) {
    width: 82px;
    height: 82px;
  }
}

.project-Avatar-small {
  width: 32px;
  height: 32px;
}

.banner-primary-color {
  font-size: 16px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  padding: 0 14px;

  svg {
    margin-right: 10px;
  }
}

.off-color {
  color: #999;
}

#account-menu {
  ul {
    padding: 15px;

    .pannel-menu-items {
      border-radius: 3px;
      height: 36px;
      margin-bottom: 10px;

      .pannel-menu-icon svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.test-ellipsis-small,
.test-ellipsis-3 {
  // width: calc(100% - 40px);
  // text-overflow: ellipsis;
  // text-wrap: nowrap;
  // white-space: pre;
  // overflow: hidden;
  // display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.test-ellipsis-small {
  max-width: 200px;

  @media screen and (width <= 768px) {
    display: none;
  }
}

.heading-test-ellipsis {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: pre;
  overflow: hidden;
  display: inline-block;

  @media screen and (width <= 992px) {
    max-width: 30%;
  }

  @media screen and (width <= 600px) {
    max-width: 20%;
  }
}

.panel-text-link {
  cursor: pointer;
  text-decoration: underline;
}

.small-screen-padding-left {
  padding-left: 16px !important;
}

.hide-1450 {
  @media screen and (width <= 1450px) {
    display: none !important;
  }
}

.hide-1200 {
  @media screen and (width <= 1200px) {
    display: none !important;
  }
}

.hide-992 {
  @media screen and (width <= 992px) {
    display: none !important;
  }
}

.hide-768 {
  @media screen and (width <= 768px) {
    display: none !important;
  }
}

.invite-to-client-portal {
  height: calc(100vh - 280px);
}

.project-location-content {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.pipeline-comment-richtext {
  .ql-editor-custom .ql-editor {
    padding-top: 10px !important;
  }
}

.set-background {
  background: #f0f1f6;
}
