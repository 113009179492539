@import '/src/variables';

.roles-back-button {
  color: $palette-primary;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-component {
  width: 300px;
}
