@import '/src/variables';

.light {
  font-weight: bold;
  color: $gray;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.job-card-container {
  background-color: $white;
  width: 100%;

  .top-part {
    line-height: 1rem;
  }

  .stat-part {
    display: grid;
    grid-template-columns: 20% 30% 50%;

    .stat {
      .job-number-id {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.add-resume-button {
  border-color: $palette-primary-main;
}

.button-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.card-button {
  z-index: 0 !important;
}
