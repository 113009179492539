@import '~quill/dist/quill.snow.css';
@import '/src/variables';

.quick-add-company-content {
  height: auto !important;
  background: #f5f5f6 !important;

  .quick-add-company {
    height: auto !important;
    overflow: auto;
    width: 100% !important;
    background-color: #fff;

    .add-quick-item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .quickaddlabel {
        font-size: 14px;
        margin-right: 50px;
        margin-bottom: 20px;
        text-transform: capitalize;
        color: #000 !important;
        border-radius: 0% !important;
      }

      .react-tel-input {
        width: 50% !important;
        margin-left: 24px;

        .selected-flag {
          .flag {
            position: absolute;
            top: 37%;
            margin-top: -2px;
            margin-left: 5px;
          }
        }
      }

      .add-quick-input {
        width: 50%;
        max-width: 100%;
        max-height: 64px;
        margin-bottom: 20px !important;
        padding-right: 20px !important;
        margin-left: 20px !important;
        border-radius: 0%;
      }

      .add-quick-input-industries {
        overflow-y: auto;
      }

      .add-quick-input-phone {
        width: 92%;
        max-width: 100%;
        max-height: 64px;
        border-radius: 0%;
      }

      .add-quick-input-phone div {
        border-radius: 0 !important;
      }
    }
  }
}

.add-to-search-header {
  .success-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #31394d;
  }

  .search-sub-title {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.004em;
    color: #1a1e3c;
  }
}

.intersection-page-view-telephone {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  color: black;
  text-transform: capitalize;

  .page-label-telephone {
    font-size: 14px !important;
    line-height: 20px;
    color: #000;
    margin: 0;
    padding-top: 0.25rem !important;
  }

  .page-field,
  .page-label-value-telephone {
    width: 50%;
    max-width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #373956;
    word-break: break-word;
    margin-left: 55px !important;
    overflow: hidden;

    &.h-overflow {
      max-height: fit-content !important;
    }

    &.multiple-selection-list {
      overflow: auto;

      .input-form-field {
        overflow: auto;
      }
    }
  }

  .multiple-view-selection-list {
    display: block;
    overflow: auto;
  }
}

.react-tel-input .country-list .search {
  position: relative;
}

.drawer-tab-layout {
  height: 52vh;
}

.tab-layout-content {
  background-color: #fff;
  box-sizing: border-box;
  min-height: calc(100vh - 240px);

  .compensation-info {
    color: $palette-heading-main !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .compensation-confirmation-typo {
    font-size: 10px;
    position: relative;
    top: 5px;
  }

  .second-page-layout {
    padding-bottom: 20px;
    background-color: rgb(149 159 183 / 5%);
    border-radius: $border-radius;

    .currency-block {
      padding-top: 32px;
      display: flex;
      align-items: center;
      gap: 27px;

      .input-field {
        .MuiAutocomplete-input {
          background-color: transparent;
          border: none;
        }
      }

      .auto-root {
        border-radius: $border-radius;
        border: 1px solid #959fb7;
        background-color: #fff;
        color: #000;
        text-align: left;
        width: 97px;
      }

      .compensation-typo {
        color: $palette-heading-main;
        min-width: 140px !important;
        font-size: 14px;
      }

      .text-input {
        border: 1px solid #959fb7;
        background-color: white;
        border-radius: $border-radius !important;
      }

      .compensation-check-typo {
        display: flex;
        align-items: center;
        color: $palette-heading-main;
        font-size: 8px;

        .compensation-check-text {
          color: $palette-heading-main;
          font-size: 12px;
        }
      }

      .compensation-checkbox {
        padding: 2px;
      }

      .text-root-area {
        border-radius: $border-radius;
        border: 1px solid #959fb7;
        background-color: #fff;
        box-sizing: border-box;
        color: #000;
        text-align: left;
      }

      .remove-another-button {
        cursor: pointer;
        margin-top: 30px;
        width: 24px;
      }
    }
  }

  .compensation-submit {
    max-width: 1063px;
    height: 50px;
    margin-top: 27px;
    position: relative;

    #compensation-submit-item {
      padding-top: 30px;
      position: absolute;
      display: flex;
      width: fit-content;
      align-items: normal;
      gap: 10px;
      bottom: 0;
      right: 0;

      #button-cancle {
        color: $palette-error-main;
      }

      #button-add {
        width: 96px;
        height: 32px;
        padding: 2px;
        border-radius: $border-radius;
        background-color: rgb(0 157 129 / 80%);
        font-family: OpenSans-Regular, 'Open Sans', sans-serif !important;
        color: #fff;
        align-items: center;
      }
    }
  }
}

#container-personality {
  .personality_sub {
    display: flex;
    margin-bottom: 15px;
    gap: 10px;
  }
}

.personality-head {
  height: auto;
  padding: 20px;
  border-radius: $border-radius;
  gap: 30px;
  background-color: rgb(149 159 183 / 4%);
  box-sizing: border-box;

  .sub-personality-label {
    margin-right: 37px;
  }
}

.padding-left-20 {
  padding-left: 20px !important;
}

.add-another-buttons {
  margin-top: 11px;
  margin-left: 0;
}

// .view-more-title {
// 	display: flex;
// 	justify-content: space-between;
// 	margin-right: 20px;

// 	.delete-icons {
// 		color: $persian-red;
// 		cursor: pointer;
// 		height: 18px;
// 		width: 18px;
// 		display: flex;
// 		align-items: center;

// 		img {
// 			width: 14px !important;
// 		}

// 	}

// 	.edit-icon {
// 		max-width: 14px;
// 		max-height: 14px;

// 		img {
// 			color: white;
// 		}

// 		.MuiSvgIcon-root {
// 			height: 14px;
// 		}

// 	}

// 	.security-icon {
// 		padding: inherit;
// 		float: right;
// 		cursor: default;

// 		img {
// 			height: 16px;
// 			width: 16px;
// 		}
// 	}

// 	.green-bg {
// 		background-color: green !important;
// 		border-radius: 3px !important;
// 		color: white !important;
// 	}
// }

.delete-icon {
  color: $palette-error-main;
  cursor: pointer;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;

  img {
    height: 15px;
    width: 15px;
    object-fit: cover;
  }
}

// .green-bg {
//   background-color: green !important;
//   border-radius: 3px !important;
//   color: white !important;
// }

// .edit-icons {
//   color: white;
//   cursor: pointer;
//   background: #009d81;
//   border-radius: 5px;
//   height: 18px;
//   width: 18px;
//   padding: 2px;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   img {
//     height: 8px;
//     width: 8px;
//   }
// }

// .security-icon {
//   padding: inherit;
//   float: right;
//   cursor: pointer;

//   img {
//     height: 20px;
//     width: 20px;
//   }
// }

// .card-title {
//   font-size: 16px;
//   color: #000;
//   text-align: left;
//   font-weight: 900;
// }

// .view-form-label {
//   font-weight: 600 !important;
//   font-size: 14px !important;
// }

// .card-head {
//   padding: 20px 0;
// }

.card-containt {
  color: $cool-gray;
  text-align: left;
  word-break: break-word;
  overflow-y: auto;
}

.view-text {
  color: $black;
  line-height: normal;
}

// .bottom-level {
//   float: right;
// }

.personality-common {
  width: 753px;
  height: 425px;
  padding: 2px;
  background-color: rgb(255 255 255 / 0%);
  box-sizing: border-box;
}

.custom-rich-text-box {
  pointer-events: none;
  opacity: 0.5;
}

.button-section-personality {
  padding-right: 10px;

  #button-cancle {
    color: $palette-error-main;
    font-family: OpenSans-Regular, 'Open Sans', sans-serif !important;
    padding-right: 10px;
    text-transform: capitalize;
  }

  #button-add {
    width: 96px;
    height: 32px;
    padding: 2px;
    border-radius: $border-radius;
    background-color: rgb(0 157 129 / 80%);
    color: #fff;
    align-items: center;
    text-transform: capitalize;
  }
}

.card-container {
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 20px;

  .left-arrow {
    text-align: center;
    display: inline-grid;
    align-content: center;
  }
}

.my-cards {
  flex-direction: column;
  width: 100%;
}

.form-section {

  /* background-color: gray; */

  /* margin-bottom: 20px; */
  display: flex;
  justify-content: center;
  gap: 10px;
}

.fit-content {
  align-items: center;
  width: max-content;
}

// .personality-card {
//   width: 482px;
//   height: 220px;
//   margin-left: 7px;
//   position: relative;

//   .personality-icon {
//     display: flex;
//   }

//   .personality-card-action {
//     position: absolute;
//     bottom: 0;
//     right: 0;

//     button {
//       color: #009d81;

//       span {
//         font-size: smaller !important;
//       }
//     }

//     .button-another-add {
//       text-transform: capitalize;
//     }
//   }
// }

.custom-card {
  // margin-right: 20px;
  margin-top: 17px;
}

.pagination {
  padding-top: 10px;
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preButton {
  margin-left: auto;
  box-shadow: 0 1px 1px 1px lightgrey;
  border-radius: $border-radius;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.text-label {
  color: $palette-heading-main !important;
  font-weight: 600 !important;

  //   font-size: 16px !important;
}

.section-content {
  padding: 10px;
  gap: 10px 10px;
  background-color: rgb(149 159 183 / 3.53%);
  border-radius: $border-radius;
  width: max-content;
}

.input-field {
  width: auto;
  min-width: 235px;
  border-radius: $border-radius !important;

  .MuiOutlinedInput-root {
    border-radius: $border-radius;
  }

  .MuiSelect-root .MuiSelect-select {
    padding: 1px;
    height: 20px;
  }
}

.nextButton {
  margin-left: auto;
  box-shadow: 0 1px 1px 1px lightgrey;
  border-radius: $border-radius;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.page-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

.page-data div {
  margin: 0 5px;
}

.active-page {
  box-shadow: 0 1px 1px 1px lightgrey;
  border-radius: $border-radius;
  width: 17px;
  display: flex;
  justify-content: center;
}

.checkbox-bio {
  margin-top: 12px;

  .checkbox-component {
    padding: '0 5px';

    svg {
      width: 31px !important;
      height: 31px !important;
    }
  }

  .label-text {
    font-size: 12px;
  }
}

#custom-personality-popup {
  .custom-personality-input {
    div div .MuiOutlinedInput-root {
      width: 40vw;
    }
  }

  .view-custom-data {
    border: 1px solid transparent;
    color: $palette-heading-main;
    word-break: break-word;
  }

  .view-custom-data:hover {
    border: 1px solid $palette-bg-main;
  }

  /* .is-approved-checkbox{
	 	.MuiSvgIcon-root {
	 		height: 20px;
	 		width: 13px;
	 	}
	
	} */

  .custom-action-button {
    .MuiSvgIcon-root {

      /*	height: 20px;
			width: 13px; */
      font-size: 20px;
    }
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }
}

#customized-popup {
  max-width: 40vw;
  min-width: 40vw;

  .view-custom-data {
    border: 1px solid transparent;
    color: $palette-heading-main;
    word-break: break-word;
  }

  .view-custom-data:hover {
    border: 1px solid $palette-bg-main;
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }

  .custom-action-buttons {
    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }

  /* .MuiSvgIcon-root {
 	height: 25px;
 	width: 18px;
 }  */
}

.type-field-custom {
  margin-top: 40px;
  width: 40vw;
}

.custom-add-another {
  display: 'flex';
  justify-content: flex-end;
  padding: '10px';

  .add-button-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }
}

.top-level {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selection-input {
  min-width: 235px;

  .MuiOutlinedInput-root {
    border-radius: $border-radius;
  }

  .MuiFormControl-root {
    width: stretch;
    background-color: #fff;
  }
}

.industry-list {
  height: 40px;
  position: relative;

  .check-shift {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.professional_experience_layout {
  height: 554px;
  width: 1063px;
}

.industry-job-layout {
  width: 1063px;
  padding-left: 40px;
  margin-top: 10px;
  height: 136px;
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: rgb(149 159 183 / 4%);

  .compensation-typo {
    color: $palette-heading-main;
    min-width: 140px !important;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.work-experience-description {
  padding-top: 15px;
}

.label-text-description {
  margin-bottom: 10px;
  font-size: 14px;
}

.dis-container {
  border: 1px solid red;

  .MuiInputBase-input {
    width: 500px !important;
  }
}

.work-experience-add {
  padding-left: 80px;
}

.kgmgtp {
  width: 100% !important;
  min-width: 345px !important;
  max-width: 345px !important;
}

.close-button-back {
  cursor: pointer;
}

// @media (width >=1200px) {
//   .card-carousel {
//     .css-1oqqzyl-MuiContainer-root {
//       max-width: stretch !important;
//       padding-left: 10px !important;
//       padding-right: 10px !important;
//     }
//   }
// }

// #education-experiences {
//   .check-box-title {
//     font-size: 12px;
//   }

//   .education-input-base {
//     .education-input {
//       .MuiInputBase-fullWidth {
//         width: 300px !important;
//       }
//     }
//   }

//   .MuiIconButton-root {
//     padding: 0 3px !important;
//   }

//   .css-hlj6pa-MuiDialogActions-root {
//     padding-right: 0;
//   }

//   .body-header-title {
//     font-size: 16px;
//     color: #1b4965;
//     font-weight: 800;
//     font-family: avenir !important;
//   }
// }
#education-experiences {
  .check-box-title {
    font-size: 12px;
  }

  .education-input-base {
    .education-input {
      .MuiInputBase-fullWidth {
        width: 18vw;
      }
    }
  }

  .multiInput > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root {
    height: auto;
  }

  .MuiIconButton-root {
    padding: 0 3px !important;
  }

  .css-hlj6pa-MuiDialogActions-root {
    padding-right: 0;
  }

  .body-header-title {
    font-size: 16px;
    color: $palette-heading-main;
    font-weight: 800;
    font-family: avenir !important;
  }

  .DatePickerStyle .MuiInputBase-root {
    width: 18vw;
  }

  .AutoCompleteStyle {
    width: 18vw;
  }
}

#education-exp-header {
  .header-title {
    font-size: 18px;
    color: $palette-heading-main;
    font-weight: 800;
    font-family: avenir !important;
  }
}

#education {
  .add-another-languages {
    width: max-content;
  }

  .license-content {
    width: 60vw;
  }

  .expire-width {
    width: 9vw;
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }

  .remove-another-button {
    align-items: center;
  }

  .add-another-button {
    margin-top: 35px;
    flex: 1.75;

    button {
      text-transform: capitalize;
    }
  }
}

#education-selection-type {
  .MuiAutocomplete-input {
    padding-right: 60px;
  }
}

.addAnotherButton {
  color: $turquoise;
  border-radius: 0;
  text-transform: capitalize;
  font-size: 16px;

  &:hover {
    color: $turquoise;
    background-color: transparent;
  }
}

.add-new-body {
  overflow-y: auto !important;

  .disabled-dialog-content {
    pointer-events: none;
    opacity: 0.5;
  }

  .remove-button {
    cursor: pointer;
    font-size: 20px;
  }
}

#custom-card {
  .header-title {
    color: $black;
    text-align: left;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header-containt {
    color: $cool-gray;
    text-align: left;
    line-height: normal;
    word-break: break-all;
  }

  /* .is-approved-checkbox {
	 	.MuiSvgIcon-root {
	 		font-size: 21px;
	 	}
	 } */

  .top-level {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .MuiSvgIcon-root {
      height: 10px;
    }

    .download-icon {
      color: $palette-heading-main;

      .MuiSvgIcon-root {
        height: 28px;
        padding-bottom: 5px;
      }
    }
  }

  .bottom-level {
    float: right;
  }

  // .edit-icon {
  //   max-width: 16px;
  //   max-height: 16px;

  //   img {
  //       height: 16px;
  //       color: $white;
  //   }
  // }
  .delete-icons {
    color: $palette-error-main;

    img {
      width: 12px !important;
    }
  }

  // .download-icons {
  //   color: $palette-heading-main;
  //   img {
  //     width: 22px !important;
  //   }
  // }
  .edit-icon {
    max-width: 16px;
    max-height: 16px;

    img {
      height: 14px;
      color: $white;
    }
  }

  .security-icon {
    padding: inherit;
    float: right;

    // cursor: pointer;

    img {
      height: 16px;
      width: 13px;
    }
  }

  .green-bg {
    background-color: $palette-primary-main !important;
    border-radius: $border-radius !important;
    color: $white !important;
  }

  .show-more-title {
    font-size: 10px;
  }
}

.drawer-container-header {
  display: flex;
  height: 47px;
  padding: 10px 11px 10px 16px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  background: #003c5b;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* PersonalityCard.css */
.personality-container {
  width: 100%;
}

.personality-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.personality-card-wrapper {
  flex: 0 0 auto;
}

.personality-card {
  width: 218px;
  height: 218px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 22px 0 rgb(0 0 0 / 15%);
}

.personality-header {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.personality-role {
  color: $palette-primary-main;
  font-family: Inter-SemiBold, sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
  padding-bottom: 6px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.personality-type {
  color: #666;
  font-family: Inter-Regular, sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.personality-description {
  width: 180px;
  margin-bottom: 10px;
  height: 76px;
}

.description-text {
  color: #444;
  font-family: Inter-Regular, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.personality-timestamp {
  padding: 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timestamp-text {
  color: #999;
  font-family: Inter-Regular, sans-serif !important;
  font-size: 12px !important;
  line-height: 13px !important;
}

.personality-actions {
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personality-publish-button {
  background: #fff !important;
  border: 1px solid $palette-primary-main !important;
  border-radius: 3px !important;
  padding: 0 8px !important;
  height: 24px;
  min-width: unset !important;
}

.button-text {
  color: $palette-primary-main;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-right: 6px !important;
}

.personality-info-icon {
  width: 16px;
  height: 16px;
  color: $palette-primary-main;
}

.personality-action-icons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.personality-action-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.personality-filter-popover-box {
  display: flex;
  padding: 16px;
  width: 299px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
}

.personality-filter-text-field {
  border-radius: 3px;
  padding: 6px 2px;
  border-bottom: 1px solid rgb(55 65 81 / 10%);
}

.personality-filter-text-field-input {
  padding: 6.5px 14px;
}

.personality-filter-filter-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 15px;
  align-items: flex-start;
  align-self: stretch;
}

.personality-filter-section-title {
  font-weight: 500;
  margin-bottom: 8px;
  color: gray;
}

.personality-filter-checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.personality-filter-action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-self: flex-end;
}
