@import '/src/variables';

.row > * {
  padding: 0;
  margin: 0;
}

.average-rating-value {
  background-color: $palette-primary-main;
  color: $white;
}

.rating-slider {
  height: 10px;
  color: rgba($palette-primary-main, 50%);
  font-size: 12;
}

.view-only-quill {
  padding-top: 0 !important;
  color: #999;
  font-size: 12px;
}

.bd-lb {
  border-radius: 6px 0 0 6px;
}

.bd-rb {
  border-radius: 0 6px 6px 0;
}

.competency-items {
  background: #fff;
  border-radius: 6px;
}
