@import '/src/variables';

.roles_back_button {
  color: $palette-primary;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.edit_btn {
  color: $palette-primary;
}
