#BrandLogo {
  .logo-container {
    cursor: pointer;
    margin-right: 2vw;
    max-width: 200px;
    max-height: 30px;

    img {
      max-height: 20px;
    }
  }
}
