@import '/src/variables';

.invalid-message {
  color: red !important;
  font-size: 12px !important;
  text-align: left;
  padding-left: 0.8rem !important;
}

#view-contact {
  height: 100%;
  overflow-y: auto;
  background: #fff;
  border-radius: 0 0 15px 15px;

  .details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: height 0.5s;
    height: calc(100vh - 350px);
  }

  .details-container-close {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 150px);
    transition: height 0.5s;
  }

  .MuiFormLabel-root {
    font-size: 0.75rem;
  }

  .viewcontact-datepicker {
    width: 19vw;
  }

  .text-field-content {
    height: 0;
  }

  .custom-drop-down-label-font {
    font-size: 12px;
  }

  .details-container-subtab {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 170px);
  }

  .details-container-subtab-close {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 170px);
  }

  .scroll-content {
    overflow-y: auto;
  }

  .contact-details .contact-details-value {
    border: 1px solid transparent;
  }

  .contact-details:hover .contact-details-value {
    border: 1px solid $palette-bg-main;
  }

  .hover-border:hover {
    .apply-border {
      border: 1px solid $palette-bg-main;
    }
  }

  .hover-border .apply-border {
    border: 1px solid transparent;
  }

  .content-space {
    gap: 4px !important;
  }

  .highlight-richtext {
    width: 45vw;
  }

  .section-details {
    display: flex;
    flex-flow: row wrap;

    .language-section {
      width: 100%;
    }
  }

  .typo-box {
    min-width: 184px;
  }

  .content-details {
    flex: 60%;
  }

  .column-gap {
    column-gap: 5px;
  }

  .row-gap {
    row-gap: 5px;
  }

  .typo-gap {
    column-gap: 5px;
  }

  .section-block {
    min-width: 114px;
  }

  .custom-drop-down-size {
    width: 10vw;
  }

  .industry-jobfunction-custom-drop-down-size {
    width: 20vw;
  }

  .personality-head {
    width: 43vw;
  }

  .personality_sub {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title-color {
    color: $black;
  }

  .content-color {
    color: $palette-heading-main;
  }

  .compact-auto {
    width: 10vw;
  }

  .industry-jobfunction-compact-auto {
    width: 20vw;
  }

  .close-icon {
    color: $palette-error-main;
  }

  .viewcontact-icon-style {
    .MuiSvgIcon-root {
      width: 13px;
      height: 20px;
    }
  }

  .viewcontact-checkbox {
    .MuiSvgIcon-root {
      width: 25px;
      height: 25px;
    }
  }

  .download-icon {
    color: $palette-heading-main;

    .MuiSvgIcon-root {
      width: 20px;
      height: 28px;
      padding-bottom: 5px;
    }
  }

  .card-carousels {
    width: 100%;
  }

  .compensation-section {
    display: flex;
    flex-flow: row wrap;
    margin: 2%;
  }

  #bio-card {
    .custom-card {
      width: 357px;
      height: 143px;
    }

    .personality-card {
      width: 100%;
      max-width: 343px;
      height: 100%;
      max-height: 128px;
    }
  }

  .add-another-btn {
    width: fit-content;
    text-transform: capitalize;
  }
}

#view-contact-content-full {
  width: 75vw;

  .MuiTypography-body1 {
    color: $white;
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }

  .table-edit-icon {
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .table-editclose-icon {
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .add-another {
    padding-left: 12px;
  }

  .MuiTableCell-root {
    border-bottom: 0;
  }

  .table-header-background {
    background-color: $palette-primary-main;
    color: $white;
  }

  .header-color {
    background-color: $content-background;
  }

  .delete-icon-pointer {
    cursor: pointer;
  }
}

.compensation-table {
  width: 100%;
  padding: 0 30px;
  margin: 0 0 10px !important;
}

#view-contact-content {
  // width: 45vw;

  .MuiTypography-body1 {
    color: $white;
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }

  .table-edit-icon {
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .table-editclose-icon {
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .add-another {
    padding-left: 12px;
  }

  .MuiTableCell-root {
    border-bottom: 0;
  }

  .table-header-background {
    background-color: $palette-primary-main;
    color: $white;
  }

  .header-color {
    background-color: $content-background;
  }

  .delete-icon-pointer {
    cursor: pointer;
  }
}

#view-contact-wfull {
  width: 100%;

  .MuiTypography-body1 {
    color: $white;
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }

  .table-edit-icon {
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .table-editclose-icon {
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .add-another {
    padding-left: 12px;
  }

  .MuiTableCell-root {
    border-bottom: 0;
  }

  .table-header-background {
    background-color: $palette-primary-main;
    color: $white;
  }

  .header-color {
    background-color: $content-background;
  }

  .delete-icon-pointer {
    cursor: pointer;
  }
}

@media (width > 767px) and (width < 1024px) {
  #view-contact {
    #view-contact-content-full {
      width: 75vw;
    }

    #view-contact-content {
      // width: 43vw;
    }

    .personality-head {
      width: 41vw;
    }

    .highlight-richtext {
      width: 43vw;
    }
  }
}

@media (width > 1023px) and (width < 1440px) {
  .drawer-tab-layout {
    height: 55vh;
  }
}

@media (width > 1439px) and (width < 1646px) {
  .drawer-tab-layout {
    height: 52vh;
  }
}

.address {
  .input-field-data {
    width: 300px;
    padding-top: 22px;
  }

  .set-position {
    width: fit-content;
    padding-left: 20px;
    margin-top: -29px;
  }
}

.bottom-action-icon {
  cursor: pointer;
  color: #3d84a8;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.activity-bar-title {
  color: #1fa890 !important;
  font-weight: 500 !important;
}

.box-ai-rating-column {
  max-width: 31.3%;
  flex: 0 0 31.3%;
  position: relative;
}

.box-ai-rating-column::after {
  content: '';
  background: #eee;
  height: 51px;
  width: 1px;
  position: absolute;
  right: -10px;
  top: 33px;
}

.box-ai-rating-column:last-child::after {
  display: none;
}

.box-ai-rating-column svg {
  width: 100%;
}

.view-candidate-score {
  height: 100%;
}

.time-meter {
  position: relative;

  &::after {
    position: absolute;
    left: -8px;
    top: 9px;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #999;
    border-radius: 100%;
  }
}

.menu-container,
.menu-container * {
  box-sizing: border-box;
}

.menu-container {
  background: rgb(0 60 91 / 5%);
  padding: 30px 12px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.button-new {
  background: #003c5b;
  border-radius: 3px;
  color: #fff !important;
}

.menu-container-button-dropdown {
  background: $palette-primary-main !important;
  text-transform: 'none' !important;
  font-weight: 500 !important;
  border: '0.1px solid grey' !important;
  color: #fff !important;
  width: '100%' !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.button-label {
  color: #fff;
  background: $palette-primary-main !important;
  font-family: Inter-Medium, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.icon-arrow {
  width: 6px;
  height: 11px;
}

.spacer {
  flex: 1;
  height: 14px;
}

.search-bar {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 326px;
  height: 36px;
}

.search-placeholder {
  color: #999;
  font-family: Inter-Regular, sans-serif;
  font-size: 14px;
  flex: 1;
}

.icon-magnify {
  width: 6px;
  height: 11px;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 161px;
}

.dropdown {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: 36px;
}

.dropdown-label {
  color: #333;
  font-family: Inter-Regular, sans-serif;
  font-size: 14px;
}

.icon-dropdown {
  width: 6px;
  height: 11px;
}

.button-filters {
  background: #c4c4c4;
  border-radius: 3px;
  padding: 2px 11px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 61px;
  height: 36px;
}

.filters-label {
  color: #111;
  font-family: Inter-Medium, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.apply-filter-container {
  background: rgb(0 60 91 / 2%);
  border-bottom: 4px solid rgb(0 60 91 / 5%);
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.apply-filter-title {
  color: #003c5b !important;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 121.059%;
}

.apply-filter-chip {
  background: #ebebeb !important;
  border-radius: 15px;
  padding: 2px 4px;
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;

  .apply-filter-chip-key {
    font-weight: 500;
  }

  .apply-filter-chip-value {
    font-weight: 400;
  }
}

.apply-filter-close-icon {
  font-size: 14px;
}

.personality-no-data {
  display: flex;
  height: 47px;
  width: 200px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px dashed #d2d2d2;
}
