@import '/src/variables';

.add-another {
  padding-left: 12px;
}

.paper-border {
  border-radius: $border-radius;
  border: 1px solid $gray;
}

.title-content {
  color: $white;
}

.table-header-content {
  color: $indigo;
}

.MuiTableCell-root {
  border-bottom: 0;
}

.delete-icon-color {
  color: $palette-error-main;
  cursor: pointer;
}

.table-header-background {
  background-color: $palette-primary-main;
}

.header-color {
  background-color: $content-background;
}

.add-contact-table {
  display: inline;
  border-bottom: solid 1px $palette-primary-main;
  color: $palette-primary-main;
  cursor: pointer;
}

#is-approved-checkbox-view {
  .MuiSvgIcon-root {
    color: $white;
  }

  .Mui-checked {
    color: $white;
  }
}

.custom-table .MuiTableHead-root .MuiTableCell-root {
  border-right: 1px #eee solid !important;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 6px !important;
}

.box-criteria-tab-row .MuiTableCell-root {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 8px !important;
}

.custom-table {
  max-height: calc(100vh - 300px);
}

.custom-table-override {
  max-height: calc(100vh - 350px) !important;
}

.table-header-background.title-content.contact-details-table-header {
  padding: 6px 0 !important;
  line-height: 20px;
}

.table-header-background .MuiTableCell-root {
  font-weight: 700;
}
