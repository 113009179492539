@import '/src/variables';

/*
.ag-theme-alpine {
  .ag-checkbox .ag-input-wrapper {
    font-size: 19px;
  }

  .ag-cell-value {
    display: flex !important;
  }

  .ag-checkbox-input-wrapper {
    font-size: 18px;
    line-height: normal;

    input {
      cursor: pointer;
      width: 20px !important;
      height: 20px !important;
    }
  }

  .ag-header-cell {
    padding: 0;
  }

  .ag-react-container {
    display: flex;
    justify-content: center;
  }

  .ag-ltr {
    .ag-selection-checkbox {
      margin-right: 0;
    }

    .ag-cell-wrapper {
      justify-content: center;
      .ag-selection-checkbox {
        margin-right: 0;
      }
    }
  }
}

.ag-theme-alpine .ag-filter {
  width: fit-content;
}

.ag-theme-alpine .ag-header-icon {
  opacity: 1 !important;
  color: $white;
}

.ag-theme-alpine .ag-header-icon:hover {
  opacity: 1 !important;
  color: $white;
  cursor: pointer;
}
*/
.ag-grid-container {
  padding: 0.7rem 0.3rem;
  height: 95%;
  width: 100%;
}

.modal-title {
  background-color: $palette-primary-main;
  color: $white;
}

.custom-theme {
  .ag-react-container {
    width: auto;
  }

  .ag-header-cell {
    justify-content: center !important;
  }
}

.ES-download-icon {
  width: 15px !important;
}

.selected-type-btn {
  border-bottom: 3px solid $palette-primary-main;
  color: $palette-primary-main;
  border-radius: 0;
}

.custom-link {
  color: #23cea7; /* Change to your preferred color */
  text-decoration: underline; /* Optional, for underlining the link */
}

.custom-link:hover {
  color: #23cea7;
}

.modal-sticky-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebedf1;
  padding-bottom: 0.6rem;
  margin-bottom: 1rem;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
}

.modal-container {
  max-width: 90%;
  min-width: 80%;
  border: none;
  max-height: 90%;
  overflow: hidden auto;
}

.ignyte-intelligence-table {
  height: calc(100vh - 220px);
}
