@import '/src/variables';

#customBreadCrumb {
  .nav-links {
    display: flex;
    align-items: center;
    font-size: 12px;

    &:hover {
      color: $palette-heading-main !important;
      opacity: 1;
    }
  }
}
