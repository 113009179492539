.contact-profile-image {
  width: 73px;
  min-width: 73px;
  height: 73px;
  color: #dddde2;
  margin-right: 20px;

  .default-profile-icon {
    font-size: 73px;
  }

  .contact-rock-star {
    font-size: 18px;
    color: #f8b500;
    position: absolute;
    top: -4px;
    right: 0;
  }
}

.contact-profile-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000;
  margin-right: 8px;
}

.contact-profile-link {
  font-size: 18px;
  color: #2867b2;
  margin-right: 12px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.contact-profile-info-icon {
  font-size: 16px;
  margin-right: 5px;
  color: #dadada;
}

.contact-profile-info-text {
  color: #000;
}

.contact-profile-domain {
  font-size: 16px;
  line-height: 19px;
}

.contact-profile-location,
.contact-profile-type {
  font-size: 14px;
  line-height: 16px;
}
