@import '/src/variables';

.paper-custom-css {
  width: 654px;
  height: 522px;
  position: relative;

  .abolute-position {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: $frosted-white;
  }
}

.mark-off-limits-title > svg#deleteIcon {
  font-size: 18px;
  fill: $error-color;
  cursor: pointer;
}

.mark-off-limits-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

#mark-off-limits-popup {
  gap: 0;

  .MuiFormLabel-root {
    font-size: 14px;
    color: $indigo;
    margin-bottom: 0;
  }

  #off-limit-reason {
    margin-bottom: 0.7rem;
  }

  .ql-editor {
    min-height: 130px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .MuiTypography-body1 {
    font-size: 14px;
    min-width: 70px;
  }

  .mark-off-limits-title {
    font-size: 20px !important;
    font-weight: 900 !important;
    color: $indigo !important;
  }

  .postion {
    right: 150px;
    top: 0;
  }

  .DatePickerStyle .MuiInputBase-input,
  .DatePickerStyle .MuiInputLabel-standard {
    font-size: 12px !important;
  }

  .DatePickerStyle .MuiInputBase-formControl.MuiInputBase-adornedEnd {
    margin-top: 10px !important;
  }

  .DatePickerStyle .MuiInputLabel-standard.MuiInputLabel-shrink {
    font-size: 18px !important;
  }
}

.MuiDialogContent-root:has(> #mark-off-limits-popup) {
  padding: 10px 24px;
}

:export {
  palettePrimary: $palette-primary-main;
}

.off-limit-richbox-container {
  .ql-editor-custom {
    .ql-container {
      height: auto !important;

      .ql-editor {
        padding-top: 12px !important;
      }
    }
  }
}
