.ag-icon-group::before {
  color: #1b4965 !important;
}

.ag-icon-columns::before {
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ctitle%3Ecolumns%3C/title%3E%3Cpath d='M5.333 10.667h5.333V5.334H5.333zm8 16h5.333v-5.333h-5.333zm-8 0h5.333v-5.333H5.333zm0-8h5.333v-5.333H5.333zm8 0h5.333v-5.333h-5.333zm8-13.334v5.333h5.333V5.333zm-8 5.334h5.333V5.334h-5.333zm8 8h5.333v-5.333h-5.333zm0 8h5.333v-5.333h-5.333z'/%3E%3C/svg%3E");
}

.ag-icon-filter::before {
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ctitle%3Efilter%3C/title%3E%3Cpath d='M13.333 24h5.333v-2.667h-5.333zM4 8v2.667h24V8zm4 9.333h16v-2.667H8z'/%3E%3C/svg%3E");
}

.ag-icon-custom-stats::before {
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150'%3E%3Cpath d='M75 45.625L66 36H30V113H120V45.625H75ZM84 93.75H48V84.125H84V93.75ZM102 74.5H48V64.875H102V74.5Z' fill='%23AA0A6C'/%3E%3C/svg%3E");
}
