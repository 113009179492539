#ClientSuiteBrandLogo {
  .logo-container {
    cursor: pointer;
    max-width: 200px;
    max-height: 30px;

    img {
      max-height: 30px;
      width: 50px;
    }
  }
}

#stack-nav {
  .MuiInputBase-root {
    border-radius: 50px !important;
  }
}

#ClientSuiteBrandLogo #BrandLogo {
  margin-left: 5px;

  img {
    min-height: 32px;
    height: 32px;
    width: auto;
  }
}

.profile-container {
  .profile-default-icon {
    margin: 0;
    width: 40px;

    svg {
      fill: #fff;
    }
  }
}

.auth-section {
  border: 1px solid #ffff;
  padding: 5px;
  border-radius: 6px;
}
