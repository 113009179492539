@import '/src/variables';

.AutoCompleteStyle {
  border-radius: 4px;
  //background-color: $white;
  // color: $black;
  text-align: left;
  width: 300px;

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    //box-shadow: $palette-heading-main 0 0 0 1.5px;
    // border-color: transparent;
    border-radius: 4px;
    // color: $black;
  }

  .MuiOutlinedInput-notchedOutline {
    // border: 1px solid $palette-heading-main;
  }

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: wrap;
  }

  .MuiButtonBase-root .MuiChip-root {
    font-size: 25px;
  }

  // .MuiChip-label {
  //     font-size: 10px;
  //     padding: 10px;
  // }
  .MuiFormLabel-root {
    font-size: 0.875rem;
  }
}
