@import '/src/variables';

.notification-label {
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  color: $black;
}

.viewed-notification-label {
  font-weight: 700;
}

.notification-sub-label {
  color: $cool-gray;
}

.notification-container {
  text-overflow: ellipsis !important;
  word-break: keep-all !important;
  overflow: hidden !important;
  white-space: nowrap;
  font-size: 11px;
}

.empty-notification {
  padding: 10px 15px;
  color: $cool-gray;
}

.view-status {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: $cool-gray;
  position: absolute;
  top: 50%;
  left: 20px;
}

.badge-animation {
  animation: badge-animation 1s ease infinite;
}

@keyframes badge-animation {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.user-notification {
  .popup-overlay {
    .popup-content {
      .confirmation-content {
        width: 60vw !important;
      }
    }
  }
}

.notification-badge {
  // transform: scale(0.6);
  span {
    border-radius: 50%;
    padding: 0 4px;
  }
}

.menu-container {
  cursor: pointer;
  position: relative;
}

.menu-component {
  max-height: 45vh;
  margin-top: 6;
  max-width: 25vw;
}

.menu-background {
  background: $notification-menu-background;
}
