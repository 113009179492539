@import '/src/variables';

#common-footer {
  .container-footer {
    margin-top: auto;
    width: auto;
    display: flex;
    justify-content: flex-end;
  }
}
