@import '/src/variables';

.header-spacing {
  flex-grow: 1;
}

.header-nav-items {
  flex-grow: 1;
}

.header-search {
  padding: 2px;
  border-radius: 4px;
  background-color: rgb(149 159 183 / 9%);
  width: 270px;
  height: 38px;
  font-size: 14px !important;

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiAutocomplete-hasClearIcon.MuiAutocomplete-inputRoot {
    padding-right: 0;
  }

  .MuiInputBase-input .MuiInput-input {
    color: $black;
  }
}

.logo-margin {
  margin-right: 32px;
}

.master-search-item {
  .master-search-info {
    color: #999 !important;
  }

  .master-search-info-sec {
    font-style: italic !important;
    font-size: 14px !important;
    color: $black;
  }
}

.search-icon {
  padding: 6px 0 6px 10px;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.profile-container {
  padding: 0;
  cursor: pointer;
  color: $black;
}
