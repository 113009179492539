@import '/src/variables';

#ActivityLogs_container {
  #ActivityBars_container {
    .bottom-navigation {
      height: 100%;
    }

    .bottom-action-icon {
      cursor: pointer;
      color: $palette-primary-main;
      display: flex;
      margin-right: auto;
      margin-left: auto;
    }

    .activity-bar-title {
      color: $cool-gray !important;
    }
  }

  #ActivityDashboard_container {
    .sub-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .sub-title {
      color: $cool-gray;
    }
  }

  #ActivityHistory_container {
    .activity-history {
      height: 50vh;
      width: 100%;
    }

    .activity-history-close {
      height: 68vh;
    }

    .non-active-tab {
      text-transform: capitalize;
    }

    .active-tab {
      text-transform: capitalize;
      color: $palette-heading-main;
    }
  }

  #ActivityHistoryDetails {
    .head-filter-icon {
      display: flex;
      justify-content: flex-end;

      .filter-icon {
        width: 20px;
        height: 20px;
        padding: 2px;
        border-radius: $border-radius;
        border: 1px solid $cool-gray;
        background-color: $white;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .icons {
          color: $cool-gray;
          font-size: small;
        }
      }
    }

    .activity-details-collapse {
      // max-height: 65vh;
      // overflow: hidden auto;
      height: calc(100vh - 260px);
      overflow: hidden auto;
      transition: height 0.5s;
    }

    .activity-details {
      // max-height: 41vh;
      // overflow: hidden auto;
      height: calc(100vh - 440px);
      overflow: hidden auto;
      transition: height 0.5s;
    }

    .activity-history-head {
      overflow: 'hidden';
      position: 'relative';
      border-radius: $border-radius;
      background-color: $smoke-black;
      box-sizing: border-box;
      width: auto;
      display: flex;

      .MuiGrid-item {
        padding-left: 0;
        padding-top: 0;
      }

      .activity-icons {
        border-radius: $border-radius;
        height: 20px;
        width: 20px;
        box-sizing: border-box;
        background-color: $dark-cerulean;
        position: relative;
        top: 12px;
      }

      .call-icon {
        background-color: $greenish-blue;
      }

      .note-icon {
        background-color: $reddish-orange;
      }

      .screening-icon {
        background-color: $dark-cerulean;
      }

      .default-icon {
        background-color: $dark-cerulean;
      }

      .show-more-link {
        cursor: pointer;
        color: $dark-cerulean;
        margin-left: 10px;
        text-decoration: underline;
        font-size: 12px;
      }

      .activity-content {
        margin-top: 14px;
        flex: 1 1 2%;

        .activity-head {
          display: flex;
          margin-bottom: 6px;
        }

        .label-text-activity {

          /* margin-right: 8px; */
          font-weight: bold;
          color: $palette-text-main;
          text-align: left;
          line-height: normal;
          width: min-content;
        }

        .value-notes {
          color: $palette-text-main;
          line-height: normal;
          white-space: pre-line;
        }

        .value-text-activity {
          color: $palette-text-main;

          /* text-align: center; */
          line-height: normal;
        }

        .activity-type {
          color: $dark-cerulean !important;
        }

        .value-title {
          cursor: pointer;
          color: $dark-cerulean !important;
          line-height: normal;
          text-decoration: underline;
        }

        .subject-value {
          text-decoration: none !important;
        }
      }

      .activity-time-content {
        color: $cool-gray;
        margin-top: 14px;

        .MuiTypography-root {
          text-align: right;
          line-height: normal;
          color: $cool-gray;
        }

        .edited-by {

          /* text-align: right; */
          line-height: normal;

          /* display: flex; */
          color: $cool-gray;

          .names {

            /* text-align: left; */
            line-height: normal;
            font-weight: bold;
            margin-left: 2px;
          }
        }
      }

      .MuiSvgIcon-root {
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: medium;
      }
    }
  }
}

// media query

@media (width > 767px) and (width < 1024px) {
  #ActivityLogs_container {
    #ActivityHistory_container {
      .activity-history {
        height: 51vh;
      }
    }

    #ActivityHistoryDetails {
      .activity-details {
        .activity-history-head {
          .activity-icons {
            height: 17px;
            width: 17px;
            margin-left: 3px;

            // margin-top: 0;
          }

          .MuiSvgIcon-root {
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (width > 1023px) and (width < 1440px) {
  #ActivityLogs_container {
    #ActivityHistory_container {
      .activity-history {
        height: 54vh;
      }
    }

    #ActivityHistoryDetails {
      .activity-details .activity-history-head .activity-icons .activity-details-collapse {
        height: 20px;
        width: 20px;
      }
    }
  }
}

@media (width > 1439px) and (width < 1646px) {
  #ActivityLogs_container {
    #ActivityHistory_container {
      .activity-history {
        height: 50vh;
      }
    }
  }
}

.delete-icons {
  color: $palette-error-main;

  img {
    width: 11px !important;
  }
}

#company_view_drawer {
  .company-details-head {
    #ActivityLogs_container {
      // background: yellow;
      // display: flex;
      // flex-direction: column;
      // max-height: calc(100vh - 340px);

      #ActivityHistoryDetails {
        .activity-details-collapse {
          height: calc(100vh - 312px);
          overflow: hidden auto;
          transition: height 0.5s;

          // #CustomRichText .ql-container{
          //   height: 35vh;
          // }
        }

        .activity-details {
          height: calc(100vh - 492px);
          overflow: hidden auto;
          transition: height 0.5s;

          // #CustomRichText .ql-container{
          //   height: 44vh;
          // }
        }
      }
    }
  }
}

.sub-typo span {
  color: #777;
}
