@import '/src/variables';

#add-contact-project {
  max-width: 33vw;
  min-width: 30vw;

  .search-bar {
    background-color: $white !important;
    border: 1px solid $cool-gray;
    border-radius: 4px;

    .MuiAutocomplete-root {
      width: 30vw;
      display: flex;
      align-items: center;
    }
  }

  .head-content {
    display: flex;
    gap: 40px;
  }

  .label-text {
    color: $palette-heading-main;
  }

  .input-data {
    width: 16vw;
    position: relative;
  }
}
