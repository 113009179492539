@import '/src/variables';
@import './utils/common';

@font-face {
  font-family: avenir;
  src: url('assets/fonts/Avenir.ttc');
}

@font-face {
  font-family: open-sans;
  src: url('assets/fonts/OpenSans-Regular.ttf');
}

html,
body,
#root {
  font-family: open-sans;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {

  /*  label {
    font-family: open-sans;
    font-size: 1rem !important;
  } */

  input {
    font-family: open-sans;
    font-size: 0.9rem !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;

  &.support-mail-link {
    color: #ffc107;
    text-decoration: underline;
  }
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
}

// button,
// button:focus {
//   outline: none;
// }

.main-wrapper {
  text-align: left;
}

.content-wrapper {
  // max-width: 1920px;
  margin: 0 auto;
  height: calc(100vh - 48px);
  margin-top: 48px;
  overflow: auto;
  background: $palette-bg-main;
}

.content-wrapper2 {
  margin: 0 auto;
  overflow: auto;
  background: $palette-bg-main;
}

.main-content {
  position: relative;
  width: 100%;
  overflow: auto;
}

.closed ~ .main-content {
  max-width: calc(100% - 40px);
}

.open ~ .main-content {
  max-width: calc(100% - 190px);
}

.primary-btn {
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.02em;
  color: #fff;
  background: #5c52c6;
  border-radius: 2px;
  border: none;
  padding: 10px 30px;
}

.secondary-btn,
.secondary-btn-border {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #5c52c6;
  background-color: transparent;
  padding: 10px 25px;
  border: 0;
}

.secondary-btn-border {
  background: #fff;
  border: 1px solid #5c52c6;
  box-sizing: border-box;
  border-radius: 2px;
}

.primary-btn:hover,
.primary-btn:focus,
.secondary-btn:hover,
.secondary-btn:focus,
.secondary-btn-border:hover,
.secondary-btn-border:focus {
  outline: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-item-center {
  align-items: center;
}

.profile-image-container {
  position: relative;
  color: #dddde2;

  .image-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid hsl(240deg 8% 88%);
    justify-content: center;
  }

  .image-container,
  .default-profile-icon {
    display: flex;
    align-items: center;
  }

  &.edit .image-container {
    opacity: 0.5;
  }

  .profile-pic-edit {
    position: absolute;
    font-size: 28px !important;
    color: black;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.input-form {
  overflow: auto;
  padding: 20px;

  .section-content {
    margin-bottom: 25px;

    .section-sub-content {
      margin-top: 25px;
    }
  }

  .section-label {
    font-weight: bold;
    font-size: 20px !important;
    line-height: 23px;
    letter-spacing: 0.004em;
    color: #1a1e3c;
    margin-bottom: 15px;
  }

  .sub-section-label {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: 0.01em;

    // text-transform: uppercase;
    color: rgb(26 30 60 / 40%);
  }

  .input-label {
    font-size: 12px !important;
    margin: 0;
    line-height: 14px;
    color: rgb(92 82 198 / 80%);
  }

  .panel-bottom-margin {
    margin-bottom: 1.25rem;
  }
}

.only-for-labels {
  margin: 0;
  line-height: 14px;
  color: rgb(92 82 198 / 80%);
}

.MuiInputLabel-asterisk {
  color: $palette-error-main;
}

/* // .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
//   padding: 9px 65px 9px 9px !important;
// } */

.MuiInput-underline,
.Mui-focused {

  /* // &:not(.Mui-error) {
  //   //width: 100%;
  //   // border-bottom: 1px solid black !important;
  // } */

  &.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiInputLabel-shrink {
    border-bottom: 0;
  }

  &:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid black;
  }
}

.hl-integration {
  margin-left: 12px;
  margin-top: 20px;

  .MuiAutocomplete-root,
  .MuiAutocomplete-hasClearIcon,
  .MuiAutocomplete-hasPopupIcon {
    width: 100%;
  }

  .d-flex.align-items-center.input-form-field {
    padding-right: 0 !important;
  }

  .MuiInputBase-input,
  .MuiInputLabel-root {
    margin-left: 4px;
    margin-right: 4px;
  }

  .MuiFormLabel-root.Mui-focused {
    margin-left: 4px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.003em;
    color: rgb(92 82 198 / 80%);
    background-color: white;
  }

  .react-tel-input {
    label {
      font-family: open-sans !important;
      padding-left: 35px;
      font-size: 16px !important;
      line-height: 19px;
      letter-spacing: 0.003em;
      color: rgb(92 82 198 / 80%);
    }

    .MuiInputLabel-shrink {
      padding-left: 4px;
    }

    .selected-flag .flag {
      margin-top: 5px;
    }
  }
}

.quick-add-contact-scroll {
  overflow-y: auto;
}

.input-form-field,
.field-value {
  width: 100%;
  max-width: 100%;
  padding-right: 20px !important;

  .MuiInputBase-input,
  .MuiInputLabel-root {
    margin-left: 4px;
    margin-right: 4px;
  }

  .MuiFormLabel-root.Mui-focused {
    margin-left: 4px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.003em;
    color: rgb(92 82 198 / 80%);
    background-color: white;
  }

  .react-tel-input {
    border: none;
    outline: none;
    padding-bottom: 9px;
    margin-bottom: 12px;

    label {
      font-family: open-sans !important;
      padding-left: 35px;
      font-size: 16px !important;
      line-height: 19px;
      letter-spacing: 0.003em;
      color: rgb(92 82 198 / 80%);
    }

    .MuiInputLabel-shrink {
      padding-left: 4px;
    }

    .selected-flag .flag {
      margin-top: 5px;
    }
  }
}

.input-field-old {
  margin-bottom: 20px;
}

.breadcrumbs-label {
  font-size: 14px !important;
}

.breadcrumbs-main-title {
  color: #1b4965;
}

.quick-add-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px !important;
  line-height: 20px;
  color: #fff;
  padding: 15px 20px;
  background: #2a364c;

  p {
    flex-grow: 1;
    font-family: open-sans !important;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 28px;
    color: #fff;
  }
}

.quick-add-container {
  height: calc(100% - 67px);
  background: #fff;

  .quick-add-content {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 0;
    height: calc(100% - 60px);
    background: #fff;
    overflow: auto;
    padding-top: 34px;
  }

  .div {
    height: 89% !important;
  }

  .input {
    width: 330px;
    margin-bottom: 20px !important;
  }
}

.quick-add-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 56px;
  background-color: #fff;

  .button {
    outline: none;
    margin-right: 10px;
    padding: 4px 20px;
    border-radius: 0 !important;
    border: 2px solid black;

    &:last-child {
      margin-right: 0;
    }
  }
}

.close-action {
  min-width: 24px;
}

.w-95 {
  width: 95% !important;
}

amplify-authenticator {
  text-align: center;
}

:root {
  --amplify-primary-color: #5c52c6;
  --amplify-primary-tint: #5f51cb;
  --amplify-primary-shade: rgb(92 82 198 / 70%);
  --amplify-font-family: 'open-sans' !important;
}

.react-quill-container {
  word-break: break-word;
}

.text-link {
  cursor: pointer !important;
  text-decoration: underline !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.error-container {
  position: relative;
  top: 35%;
  text-align: center;

  .error-title {
    font-size: 40px !important;
    font-weight: 500;
    padding-bottom: 20px;
  }
}

.expansion-panel-close-container {
  box-shadow:
    rgb(0 0 0 / 20%) 2px 2px 1px -1px,
    rgb(0 0 0 / 14%) 1px 1px 1px 0,
    rgb(0 0 0 / 12%) 1px 1px 3px 0;

  .expansion-panel-close {
    padding: 15px;
  }
}

.decrease-width {
  padding-right: 0 !important;
  max-width: 51%;
}

.half-width {
  max-width: 50%;
}

.padding-remove {
  padding-right: 0% !important;
}

.list-work-benches {
  .action-button {
    margin-right: 7px;
    font-size: 13px !important;
    padding: 5px 10px;
  }

  .add-contact-to-workbench-content {
    width: 450px !important;
    height: auto !important;
  }
}

.add-workbench-content.add-workbench-content {
  width: 648px !important;
  height: 510px !important;
}

.popup-overlay {
  z-index: 1300 !important;
  background: rgb(10 7 7 / 51%);
}

.confirmation-popup-overlay {
  z-index: 1301 !important;
}

/* .MuiAutocomplete-popper,
#menu- {
  z-index: 1302;
} */

.hirelogicPopup {
  height: calc(100% - 62px);

  @media only screen and (width <=600px) {
    .popupBoxWrapper {
      width: 100%;
    }
  }
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.checkbox-width {
  width: fit-content;
  padding-right: 0 !important;
  margin-right: 0 !important;

  .MuiSwitch-root {
    width: 50px;
    padding: 12px 6px 12px 12px;
  }
}

.input-form-field {
  text-overflow: ellipsis !important;

  /* customize for large field label */
  &.truncate-label {
    .MuiInputBase-input,
    .MuiInputLabel-root {
      padding-right: 10%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: inherit;
    }
  }

  &.date-range {
    overflow: visible !important;
  }
}

.field-details {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  word-break: break-word;

  &.notes-field-details {
    align-items: flex-start;
  }

  .field-title {
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: 0.09em;

    // text-transform: uppercase;
    color: rgb(92 82 198 / 80%);

    /* old value: 1.25 */
    flex: 0.9 0;
    padding-right: 5px;

    &.for-emp-his {
      flex: 2;
    }
  }

  .field-titles {
    font-size: 14px !important;

    /* //padding-left: 100px !important; */
    line-height: 16px;
    letter-spacing: 0.09em;
    color: rgb(92 82 198 / 80%);

    /* old value: 1.25 */
    flex: 0.9 0;
    padding-right: 50px;
    align-items: flex-end;
  }

  .field-value,
  .input-form-field {
    font-family: open-sans !important;
    font-size: 14px !important;
    line-height: 16px;
    color: #31394d;
    flex: 2 0;
    margin-bottom: 0 !important;
  }

  .field-value .react-tel-input {
    border: none;
    margin: 0;
    padding: 0;

    label + .MuiInput-formControl {
      margin-top: 0;
    }

    .selected-flag .flag {
      margin-top: -7px;
    }
  }
}

.bs-popover-right-end.popover {
  transform: translate3d(585px, 40.5px, 0) !important;
  max-height: calc(100% - 150px);
  margin-left: calc(59% - 580px) !important;
  max-width: 40% !important;

  .MuiAccordionDetails-root {
    flex-direction: column;
    padding: 0;
  }

  .expandable-section {
    margin: 0;
    border: 1px solid #000;

    .expandable-section-title {
      font-weight: 500;
      font-size: 18px !important;
    }

    .MuiAccordionSummary-root {
      background-color: #df7928;
      color: white;
      min-height: 44px;
      max-height: 44px;

      .MuiAccordionSummary-expandIcon {
        color: inherit;
      }

      .MuiAccordionSummary-content.Mui-expanded,
      .MuiAccordionSummary-content {
        margin: 8px 0 10px;
      }
    }

    .MuiAccordionDetails-root {
      height: calc(100vh - 200px);

      .log-an-activity-container {
        height: calc(100% - 50px);
        overflow-y: auto;
        padding: 20px 0 20px 20px;
        max-width: 500px;
      }

      .bottom-actions {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        overflow: hidden;
        min-height: 50px;
        background: rgb(244 244 244 / 50%);
        border-top: 1px solid #dddde9;

        .button {
          text-transform: none;
          font-size: 16px !important;
          font-weight: normal;
        }

        .save-activity-btn {
          background-color: #df7928;
          color: white;
        }
      }
    }
  }

  .input-form-field {
    padding-right: 20px !important;
  }
}

.react-tel-input .country-list .search-box {
  max-width: 80%;
}

#client-contacts .country-list {
  position: absolute !important;
  text-align: left;
}

#add-company-form .country-list {
  position: absolute !important;
  text-align: left;
}

.contact-details-row {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 0.5vw;

  &.no-data-available {
    font-size: 16px;
    line-height: 19px;
    color: #373956;
  }
}

/*
.ag-theme-alpine {
  .ag-checkbox .ag-input-wrapper {
    font-size: 19px;
    width: 18px;
    background: transparent;
  }

  .ag-cell {
    background-color: white !important;

    // height: 60px;
    line-height: initial !important;
    align-items: center;
  }

  .ag-cell-value {
    display: grid !important;
  }

  .ag-checkbox-input-wrapper {
    font-size: 18px;
    line-height: normal;

    input {
      cursor: pointer;
      width: 20px !important;
      height: 20px !important;
    }
  }

  .ag-header-cell {
    padding: 0;
  }

  .ag-react-container {
    display: flex;
    justify-content: center;
  }

  .ag-ltr {
    .ag-selection-checkbox {
      margin-right: 0;
    }

    .ag-cell-wrapper {
      justify-content: center;

      .ag-selection-checkbox {
        margin-right: 0;
      }
    }
  }
}
*/
.header-column-filter {
  .ag-header-cell.ag-focus-managed:last-of-type {
    .ag-cell-label-container {
      .ag-header-icon.ag-header-cell-menu-button {
        // Add your specific styles here
        margin-right: 50px;
      }
    }
  }

  // .ag-root-wrapper .ag-layout-normal .ag-header {
  //   padding-right: 50px;
  // }
}

.add-contact-section {
  // .ag-root-wrapper-body.ag-layout-normal {
  //   height: 200px !important;
  // }
}

.action-text {
  &.company-redirect-link {
    text-decoration-line: underline;
    color: #5c52c6;
  }
}

.text-red * {
  color: $palette-error-main;
}

/* To use custom scroll bar add this class */
.custom-scrollbar * {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
    border: 1px solid #dddde9;
    border-radius: 23px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dddde9;
    border-radius: 23px;
    box-shadow: inset 0 0 6px #b4b4bb;
  }
}

/* // .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
//   .MuiAutocomplete-input {
//   padding: 0 !important;
// }

// .MuiOutlinedInput-root {
//   border-radius: 0% !important;

//   .MuiOutlinedInput-input {
//     padding: 9px;
//   }
// }
// */
/* comment this Mui class because of override common component css */
/*   .MuiSwitch-colorPrimary.Mui-checked {
    color: #23cea7 !important;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #23cea7 !important;
    opacity: 0.5 !important;
  } */

.invoice-heading-label {
  font-size: 14px;
  color: #df7928;
  margin-right: 20px;
  width: 50%;
  text-align: end;
}

.invoice-heading-value {
  font-size: 14px;
  flex-grow: 1;
  width: 50%;
  margin-left: 55px;

  &.bottom-bordered {
    border-bottom: 2px solid #2a364c;
  }
}

.text-transform-none {
  text-transform: none !important;
}

.popup-header {
  background-color: #2a364c !important;
}

.intersection-page-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  color: black;
  text-transform: capitalize;

  &.remove-capitalize {
    text-transform: inherit;
  }

  .page-label {
    font-size: 14px !important;
    line-height: 20px;
    color: #000;
    margin: 0;
  }

  .page-field,
  .page-label-value {
    width: 50%;
    max-width: 100%;
    max-height: 75px;
    font-size: 14px;
    line-height: 19px;
    word-break: break-word;
    margin-left: 55px !important;
    overflow: hidden;

    &.h-overflow {
      max-height: fit-content !important;
    }

    &.multiple-selection-list {
      overflow: auto;
      max-height: none !important;

      .input-form-field {
        overflow: auto;
      }
    }
  }

  .multiple-view-selection-list {
    display: block;
    overflow: auto;
  }
}

#client-info {
  .MuiAccordionDetails-root {
    display: inline-block;
  }
}

.page-content-footer {
  display: flex;
  justify-content: flex-end;
  background: #c3c3c35c;
  position: relative;
  top: 100%;
  width: 100%;
  padding: 0.25rem;
  gap: 10px;

  .page-btn-cancel {
    border: 1px solid black;
  }

  .page-btn-save {
    background-color: #4bb543;
    color: #fff;

    &:hover {
      background-color: #4bb543;
      box-shadow: 0 4px 5px 0 rgb(176 176 176 / 100%);
    }
  }
}

.page-content-edit-footer {
  bottom: 0;
  left: 0;
  width: 100%;
  background: #c3c3c35c;
}

.add-popup-value-content.add-popup-value-content {
  height: auto !important;
  padding: 0 !important;
  background: #f4f4f4 !important;
  overflow: auto;
}

.MuiInputBase-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  font-size: 1.2rem;
  margin-left: 0;
}

.fields {
  align-items: flex-start !important;
}

.product-one-score-content {
  display: flex;
  align-items: baseline;
  gap: 8px;

  .product-one-color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

.padding-rich-remove {
  .ql-editor {
    padding: 0 !important;
    padding-left: 15px !important;
  }
}

.checkbox-container {
  pointer-events: none;
}

.checkbox-component {
  pointer-events: auto;
}

.text-view-value {
  width: 34%;
  font-size: 14px;
  line-height: 19px;
  color: #373956;
  word-break: break-word;
  padding-left: 10px;
}

.outlined-border {
  border-bottom: 1px solid rgb(135 135 135);

  &.rich-text-field {
    width: 97% !important;
    margin-top: 5px;
  }
}

.width-95 {
  width: 95% !important;
}

.all-popup-content {
  max-height: 320px;
  overflow: auto;
}

.all-popup-content-height {
  max-height: 450px;
  overflow: auto;
}

.margin-button {
  margin-top: 50px;
}

.MuiSnackbarContent-message {
  max-width: 95%;
}

.confirmation-popup-bd-search {
  .confirmation-popup-content.confirmation-popup-content {
    height: auto !important;
    width: 50% !important;
  }
}

// .ag-cell {
//   white-space: normal !important;
// }

/* // .ag-header-cell {
//  background-color: #2a364c !important;
// }
// .ag-header-cell {
//   background-color: #2a364c !important;
// } */

.text-capitalize {
  text-transform: capitalize !important;
}

#add-document {
  width: auto;
  padding: 0;
  padding-left: 0;

  #button-add {
    width: auto;
    height: 32px;
    border-radius: 4px;
    background-color: #fff;

    /* White background color */
    border: 1px solid #009d81;

    /* Green border */
    font-family: OpenSans-Regular, 'Open Sans', sans-serif !important;
    color: #009d81;

    /* Green text color */
    align-items: center;
    text-transform: inherit;
  }

  .MuiButton-root {
    box-shadow: none;
    text-transform: none;
  }
}

/* add contact popup */

.education-container {
  // border: 1px solid red;

  .MuiInputLabel-outlined {
    transform: translate(12px, 10px) scale(1);
  }

  .container-title {
    font-weight: 900;
  }

  .final-page-layout {
    background-color: $palette-bg-main;
  }
}

.education-container-projectedDate-popup {
  // border: 1px solid red;
  .container-title {
    font-weight: 900;
  }

  .final-page-layout {
    background-color: $palette-bg-main;
  }
}

// .MuiOutlinedInput-root {
//   .MuiOutlinedInput-input {
//     background-color: #fff;
//     border-radius: 4px;
//     border: 1px solid #fff;
//   }
// }

.text-input-date {
  width: 294px;
  height: 40px;

  /* // date picker in add workexperience popup */
  background-color: #fff;
  border: 1px solid #959fb7 !important;

  .MuiInput-underline::after {
    border: none;
  }

  .MuiInputBase-root {
    height: stretch;
  }
}

.arrow-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  color: #000;

  .left-arrow {
    text-align: center;
    display: inline-grid;
    align-content: center;
  }
}

.header-font {
  font-family: avenir !important;
}

.body-font {
  font-family: open-sans;
}

.loader-class {
  color: $palette-primary-main !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #009d81 !important;
  color: #009d81;
}

.MuiPickersDay-daySelected {
  background-color: #009d81 !important;
}

.MuiDialogActions-root {
  .MuiButton-textPrimary {
    color: #009d81;
  }

  .MuiPickersToolbarText-toolbarTxt {
    color: #009d81;
  }
}

.MuiPickersYearSelection-container {
  .MuiPickersYear-yearSelected {
    color: #009d81;
  }
}

/* // custom Scroll bar ------- */
.custom-scroll * {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
    border: 1px solid #dddde9;
    border-radius: 23px;
  }

  &::-webkit-scrollbar-thumb {
    background: $switch-back-gray;
    border-radius: 23px;
    box-shadow: inset 0 0 6px $switch-back-gray;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $switch-dark-gray;
    border-radius: 23px;
    box-shadow: inset 0 0 6px $switch-dark-gray;
  }
}

.animate-icon {
  .MuiAutocomplete-popupIndicator {
    transition: transform 0.4s ease;
  }

  .Mui-focused .MuiAutocomplete-popupIndicatorselected {
    transform: rotate(360deg);
  }
}

.animate-icon-closed {
  transition: transform 0.4s ease !important;
  transform: rotate(0deg) !important;
}

.animate-icon-open {
  transition: transform 0.4s ease !important;
  transform: rotate(180deg) !important;
}

.theme-bg-color {
  background-color: $palette-primary-main;
  color: $white;
}

.theme-text-color {
  color: $white;
}

.theme-bg-cool-grey {
  background-color: $light-grey;
}

.theme-bg-error-color {
  background-color: $palette-error-main;
  color: $white;
}

.disabled-linkedin-link {
  fill: $gray;
  cursor: not-allowed;
}

.enabled-linkedin-link {
  fill: $dark-cerulean;
}

/* html {
  font-size: 16px;
} */

/* Tablets (portrait and landscape) */
@media (width >=768px) and (width <=1023px) {
  html {
    font-size: 14px;
  }
}

/* Larger tablets and desktops */
@media (width >=1024px) {
  html {
    font-size: 16px;
  }
}

/* Extra-large screens for media */
@media (width >=1440px) {
  html {
    font-size: 18px;
  }
}

.project-set-background {
  background: #f0f1f6;
}
