.tab-info-container {
  position: relative;
  top: 10px;
}

.status-message {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: red !important;
  position: relative;
  bottom: 15px;
}

.save-and-next-button {
  text-transform: capitalize !important;
}

.back-button {
  text-transform: capitalize !important;
}

.cancel-button {
  text-transform: capitalize !important;
}

.align-values {
  margin-bottom: 1rem;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.stepper {
  padding: 0;

  .MuiStepper-root {
    padding: 0;
  }
}

.step {
  .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
    color: #f1a06b;
    box-sizing: unset;
    border: 5px solid #f1a16b5a;
    border-radius: 50%;
    position: relative;
    bottom: 3px;
  }

  .step-child {
    font-weight: bold;
    text-decoration: underline;
  }

  &.MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel.MuiStep-completed {
    pointer-events: none;

    .MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel {
      pointer-events: none;

      .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel,
      .step-child {
        pointer-events: visible;
        cursor: pointer;
      }
    }
  }

  .MuiSvgIcon-root.MuiStepIcon-root {
    height: 1.6rem;
    width: 1.6rem;
    cursor: pointer;
  }

  .MuiStepIcon-completed {
    color: #f1a06b;
  }

  .MuiStepIcon-text {
    cursor: pointer;
  }

  .step-child-border {
    position: relative;
  }
}
