@import '/src/variables';

.close-rectangle {
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: $palette-primary-main;
  box-sizing: border-box;
  color: $white !important;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  // top: 45px;
  left: -32px;
}

.normalwidth-square {
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: $cool-gray;
  box-sizing: border-box;
  color: $white !important;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  top: 95px;
  left: -32px;
}
