@import '/src/variables';

#inputTextStyle {
  &:focus {
    background: $input-focus;
  }
}
.MuiFormLabel-root {
  font-size: 0.875rem;
}
