.notes-details {
  margin-bottom: 20px;

  .notes-count {
    font-size: 12px;
    line-height: 14px;
    color: rgb(92 82 198 / 80%);
    margin-bottom: 2px;
  }

  .notes-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #31394d;
  }

  .notes-description {
    font-size: 14px;
    line-height: 162.6%;
    color: #31394d;
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
