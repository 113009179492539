@import '/src/variables';

#add-sms-template-drawer {
  position: relative;
}

.message-template-footer-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
