@import '/src/variables';

.warning-offLimit {
  width: 900px;
}

.offLimit-warning-popup-title {
  font-size: 24px !important;
}

.italic {
  font-style: italic !important;
  font-size: 12px !important;
  margin-bottom: 10px !important;
}

.hard-off-limits-warning-content {
  border: 1px solid $deep-crimson !important;
  background-color: $soft-rose;
  padding: 5px;

  h6 {
    color: $deep-crimson;
    font-weight: bold;
    font-size: 16px;
  }

  div svg {
    fill: $deep-crimson;
  }

  div:has(> svg) {
    max-height: 40px;
  }

  .bold {
    color: $deep-crimson;
    font-weight: bold;
    margin-right: 5px;
  }
}

.soft-off-limits-warning-content {
  border: 1px solid $vivid-orange !important;
  background-color: $light-cream;
  padding: 5px;

  h6 {
    color: $vivid-orange;
    font-weight: bold;
    font-size: 16px;
  }

  div svg {
    fill: $vivid-orange;
  }

  div:has(> svg) {
    max-height: 40px;
  }

  .bold {
    color: $vivid-orange;
    font-weight: bold;
    margin-right: 5px;
  }
}

.offLimits-warning-content {
  margin-bottom: 5px;

  p {
    font-size: 12px;
  }
}

.overflowScroll {
  max-height: 500px;
  overflow: scroll;
}
