@import '/src/variables';

.content-wrapper {
  background: #fff;
}

.module-header {
  background: #efefef;
  padding: 7px 0 15px;

  .module-header {
    font-size: 13px;
    font-weight: bold;
    padding: 0 15px 5px;
  }

  .module-search {
    padding: 0 15px;
  }
}

.project-count,
.project-title {
  color: #333;
}

.project-subtitle {
  color: #666;
}

.header-input {
  font-size: 14px;
}

.w-98 {
  width: 98%;
}

.w-40 {
  width: 40px;
}

.rounded-right {
  border-radius: 0 6px 6px 0;
}

.rounded-left {
  border-radius: 6px 0 0 6px;
}

.filter-chip {
  max-width: 18% !important;
  min-width: 15% !important;
}

.side-bar-projects {
  height: calc(100vh - 230px);
  overflow: hidden overlay;
  margin: 5px 0;

  .client-list {
    &:hover {
      background: #efefef;
      border-radius: 3px;
    }
  }

  .selected-project {
    // background-color: $palette-error-main;
    .project-count,
    .project-title,
    .project-subtitle {
      color: #fff;
    }

    .project-subtitle {
      opacity: 0.7;
    }

    &:hover {
      .project-count,
      .project-title,
      .project-subtitle {
        color: #fff;
      }

      .project-subtitle {
        opacity: 0.7;
      }
    }
  }
}

.stat-card {
  background-color: $palette-title-bar-main;
  min-width: 15%;
}

.help-desk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}

.border-red {
  border-color: $palette-error-main;
}

.selected-stat {
  background-color: rgba($error-color, 20%) !important;
  color: $white;
}

.temp-modal {
  min-width: 40vw;
  min-height: 40vh;
}

.scroll-style {
  width: 98%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
    border: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }
}

.loader-container.custome-loader {
  position: fixed;
}

.w-40p {
  width: 350px;
  min-width: 350px;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

.scroll-box-fh {
  position: relative;
  height: 407px;
  overflow: hidden scroll;
  width: 100%;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 6px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
}

.client-portal-rating-modal {
  .rich-text-popup-container .ql-editor {
    min-height: 160px !important;
    height: 160px !important;
  }

  #CustomRichText .quill .ql-container {
    height: auto;
  }
}

.multiline-elipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 4.5em;
}

.feedback-tooltip {
  max-height: 400px;
}

.min-400 {
  min-width: 624px;
  max-width: 624px;

  @media (width <= 1024px) {
    min-width: 400px;
    max-width: 400px;
  }

  @media (width <= 768px) {
    min-width: 300px;
    max-width: 300px;
  }
}

#client-side-nav-container {
  z-index: 10;

  .side-nav-bar {
    position: relative;
    box-sizing: border-box;
    top: 10vh !important;
  }

  .side-nav-header {
    border-bottom: 1px solid $light-black;
  }

  .export-tab {
    text-transform: none;
  }

  .MuiTab-root.Mui-selected {
    color: $palette-heading-main;
    font-weight: bold;
  }

  .MuiTabs-indicator {
    background-color: $palette-heading-main;
    height: 2px;
  }
}

.hamburger-span {
  position: relative;
  background-color: $content-background;
}

.hamburger-icon {

  /* font-size: 30px !important; */
  cursor: pointer;

  /* margin-left: -16px; */
  color: $palette-title-bar-main;

  /* margin-top: 60vh; */
}

.arrow-icon {
  color: $palette-primary-main;
  transition: transform 0.4s ease-in-out;
  top: 40vh;
  right: 14px;
  background-color: $white;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  position: relative;
}

.side-nav-container {
  transition: width 0.3s linear;
  overflow: hidden auto;
}

.side-nav-container.open {
  width: 190px;
}

.rotate-icon-open {
  transform: rotate(-270deg);
}

.rotate-icon-closed {
  transform: rotate(-90deg);
}

.side-nav-items {
  width: fit-content;
}

.side-nav-items a:hover {
  text-decoration: none;
}

.side-nav-container.open .side-nav-items {
  display: flex;
}
