@for $i from 6 through 20 {
  $value: ($i) - 3;
  $base: 0.25;
  $types: (
    (
      key: 'p',
      property: padding
    ),
    (
      key: 'm',
      property: margin
    )
  );

  @each $type in $types {
    .#{map-get($type, key)}-#{$i} {
      #{map-get($type, property)}: ($value * $base) + rem;
    }

    .#{map-get($type, key)}x-#{$i} {
      #{map-get($type, property)}-left: ($value * $base) + rem;
      #{map-get($type, property)}-right: ($value * $base) + rem;
    }

    .#{map-get($type, key)}y-#{$i} {
      #{map-get($type, property)}-top: ($value * $base) + rem;
      #{map-get($type, property)}-bottom: ($value * $base) + rem;
    }

    .#{map-get($type, key)}t-#{$i} {
      #{map-get($type, property)}-top: ($value * $base) + rem;
    }

    .#{map-get($type, key)}r-#{$i} {
      #{map-get($type, property)}-right: ($value * $base) + rem;
    }

    .#{map-get($type, key)}b-#{$i} {
      #{map-get($type, property)}-bottom: ($value * $base) + rem;
    }

    .#{map-get($type, key)}l-#{$i} {
      #{map-get($type, property)}-left: ($value * $base) + rem;
    }
  }
}

@for $i from 4 through 40 {
  $value: ($i) * 0.0625;
  $types: ('fs');

  @each $type in $types {
    .#{$type}-#{$i} {
      font-size: #{$value}rem;
    }
  }
}

.highlight {
  background-color: #ff0;
  border: 1px solid #797979;
  opacity: 0.35;
  color: black;
}

.grid-menu-tab {
  background-color: var(--palette-primary-main);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  // max-width: 380px;
  margin-left: auto;
  width: 100%;

  .fs-14 {
    color: #fff;
  }
}

.grid-menu-tab .MuiTabs-flexContainer {
  justify-content: center;
}

// .ag-theme-alpine .ag-root-wrapper {
//   border-radius: 0 !important;
//   border: 0 !important;
// }

// .ag-root-wrapper .ag-layout-normal .ag-header,
// .ag-root-wrapper .ag-layout-normal {
//   border-radius: 0 !important;
// }
