.add-translate-value-content.add-translate-value-content {
  height: auto !important;
  padding: 0 !important;
  background: #f4f4f4 !important;
  overflow: auto;
}

.action-edit {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #2a364c !important;
  padding: 15px 25px;
  cursor: pointer;
}
