.attachment-details {
  border-bottom: 1px solid #dddde2;
  margin-bottom: 20px;
  padding-bottom: 20px;
  max-width: 75%;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .attachment-name {
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #5c52c6;
    margin-right: 10px;
  }

  .attachment-added-on {
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
    color: rgb(49 57 77 / 60%);
    margin-top: 5px;
  }
}
