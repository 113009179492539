@import '/src/variables';

#conformation-popup {
  .MuiPaper-root {
    min-width: 32vw;
    min-height: 10vh;
    height: auto;
    width: auto;
  }

  .MuiDialogContent-root {
    flex: 0 1 auto;
    padding: 15px 24px;
  }

  .confirmation-title {
    padding: 0 !important;
    font-weight: bold;
    font-size: 19px;
    text-transform: capitalize;
  }

  .confirmation-close-icon {
    cursor: pointer;
  }

  .cancel-button {
    color: $palette-error-main;
  }

  .delete-button {
    background-color: $palette-primary-main;
    color: $white;
  }

  .confirmation-button {
    text-transform: capitalize;
  }
}
