@import '/src/variables';

.client-setup {
  .header-container {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    background-color: $white;
    border-radius: 8px;
    filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.1));

    // .header-input {
    //   min-width: 300px;
    // }
  }
}

#demo-simple-select-label {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.bar-buttons {
  color: $palette-text-main !important;
}
