@import '/src/variables';

.filter-container {
  position: absolute;
  background-color: white;
  border: 1px solid rgb(0 0 0 / 12%);
  border-radius: 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding: 16px;
  z-index: 10;
  width: 500px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-divider {
  width: 100%;
  margin: 4px 0;
}

.filter-search-field {
  margin-bottom: 8px;
  margin-top: 4px;
}

.filter-search-field .MuiOutlinedInput-root {
  height: 36px;
  font-size: 0.9rem;
}

.filter-section-title {
  margin-bottom: 4px;
}

.filter-checkbox-label {
  margin-bottom: 0 !important;
  font-size: small;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.experience-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border: 1px solid $gray;
  border-radius: 8px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.category-section {
  width: 100%;
  padding: 16px;
  border-radius: 4px;
}

.category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--palette-primary-opacity-20);
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.category-title {
  color: primary;
}

.category-divider {
  width: 100%;
  border-top: 1px solid #e0e0e0;
}

.sub-category {
  margin-top: 16px;
}

.sub-category__title {
  display: block;
  margin-left: 12px;
  background-color: var(--palette-primary-opacity-30);
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  color: primary;
}

.item-card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
}

.item-card--competency {
  margin-left: 24px;
}

.item-card--experience,
.item-card--uncategorized {
  margin-left: 12px;
}

.item-card__header {
  background-color: var(--palette-primary-opacity-30);
  padding: 12px;
  display: 'flex';
  align-items: 'center';
}

.item-card__title {
  margin-left: '8px';
  font-weight: bold;
}

.item-card__content {
  padding: 16px;
}

.item-card__section {
  margin-bottom: 12px;
}

.item-card__section:last-child {
  margin-bottom: 0;
}

.item-card__label {
  color: rgb(0 0 0 / 60%);
  margin-bottom: 8px;
}

.item-card__text {
  display: block;
  padding: 8px;
  border-radius: 4px;
}

.label-form {
  padding: 24px;
}

.label-form__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.label-form__loading-text {
  margin-bottom: 16px;
}

.label-form__field {
  margin-bottom: 16px;
  width: 100%;
}

.label-form__field--required {
  position: relative;
}

.label-form__field--required::after {
  content: '*';
  color: #d32f2f;
  margin-left: 4px;
}

/* Layout for form groups (status, type, requirement) */
.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.form-group__label-container {
  flex: 0 0 30%;
  display: flex;
  align-items: center;
}

.form-group__controls-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.form-group__label {
  font-size: 0.875rem;
  margin-right: 8px;
}

/* Radio and Switch controls */
.form-control__label {
  font-size: 0.875rem;
  margin-right: 16px;
}

/* Custom modal styles */
.label-modal {
  min-width: 20vw;
  width: 35vw;
}

/* Responsive styles */
@media (width <= 960px) {
  .label-modal {
    width: 80vw;
  }

  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group__label-container,
  .form-group__controls-container {
    flex: 1;
    width: 100%;
    margin-bottom: 8px;
  }
}

.expandable-text {
  padding: 4px;
}

.label-icons {
  padding: 2px;
  margin-right: 0;
}

/* Helper classes */
.w-100 {
  width: 100%;
}

.position-profile {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header-buttons {
    margin-bottom: 16px;
  }

  .preview-controls {
    display: flex;
    align-items: center;

    .control-button {
      margin: 0 4px;
      border: 1px solid $palette-primary-main;
      border-radius: 4px;

      .MuiTypography-root {
        margin-left: 8px;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: 4px;
  }

  .progress-bar-container {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: $palette-primary-main;
    padding: 15px;
    border-radius: 4px;

    .progress-title {
      color: $white;
    }

    .progress-bar-wrapper {
      display: flex;
      align-items: center;
      gap: 50px;
      margin-left: auto;

      .progress-bar {
        width: 250px;
        border-radius: 25px;

        .MuiLinearProgress-root {
          height: 15px;
          border-radius: 25px;
          background-color: var(--palette-primary-opacity-50) !important;

          .MuiLinearProgress-bar {
            border-radius: 5px;
            background-image: linear-gradient(135deg, $mint-green 25%, $palette-primary 25%, $palette-primary 50%, $mint-green 50%, $mint-green 75%, $palette-primary 75%);
            background-size: 20px 20px;
            animation: progress-stripes 1s linear infinite;
          }
        }
      }

      .progress-value {
        min-width: 40px;
        color: $white;
      }
    }
  }

  @keyframes progress-stripes {
    from {
      background-position: 0 0;
    }

    to {
      background-position: 20px 0;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 16px;

    .MuiTypography-root {
      text-align: center;
      margin-bottom: 16px;
    }
  }

  .project-labeling-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--palette-primary-opacity-50) !important;
    border-radius: 4px;

    .MuiTypography-root {
      padding: 8px 16px;
    }

    .MuiIconButton-root {
      margin: 0 4px;
    }
  }

  .project-description {
    max-height: 250px;
    overflow-y: auto;
    background-color: var(--palette-primary-opacity-5);
    border: none !important;
  }

  .columns-container {
    display: flex;
    flex-direction: row;

    // gap: 12px;
    align-items: stretch;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .column-paper {
        padding: 12px;
        height: 400px;
        display: flex;
        flex-direction: column;

        .column-header {
          background-color: var(--palette-primary-opacity-20);
          color: $palette-primary-main;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 6px;
          padding: 2px 6px;
          border-radius: 4px;

          .header-left {
            display: flex;
            align-items: center;

            .MuiTypography-root {
              margin-left: 6px;
            }
          }

          .header-actions {
            background-color: var(--palette-primary-opacity-10);
            color: $palette-primary-main;

            .MuiIconButton-root {
              margin: 0 2px;
              color: $palette-primary-main;
            }
          }
        }

        .column-content {
          background-color: var(--palette-primary-opacity-5);
          margin-top: 6px;
          flex: 1;
        }
      }
    }
  }
}

.divider {
  background-color: $gray; /* Divider color */
}

.vertical-divider {
  width: 1px;
  height: 100%;
  align-self: stretch;
  margin: 0; /* Remove any default margin */
}

.horizontal-divider {
  height: 1px;
  width: 100%;
  margin: 5px 0;
}

.column-content-header {
  background-color: var(--palette-primary-opacity-30);
}

.expand-icon {
  transition: transform 0.3s;

  &.expanded {
    transform: rotate(180deg);
  }

  &:not(.expanded) {
    transform: rotate(0deg);
  }
}

.label-text-block {
  &__container {
    color: $palette-primary-main;
    padding: 2px 8px;
    border-radius: 8px;
    display: inline-block;
  }

  &__text {
    font-weight: 600;
    font-size: 0.875rem;
  }
}

.sortable-subcompetency {
  &__container {
    cursor: grab;
    background-color: var(--palette-primary-opacity-40);
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    touch-action: none;

    &--dragging {
      opacity: 0.5;
      box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    cursor: grab;
    flex-grow: 1;
  }

  &__drag-icon {
    margin-right: 8px;
  }

  &__title {
    margin-right: 8px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__add-button {
    margin-left: 8px;
  }

  &__expand-button {
    margin-left: 8px;
    transition: transform 0.3s;

    &--expanded {
      transform: rotate(180deg);
    }

    &--collapsed {
      transform: rotate(0deg);
    }
  }
}

.modal-header-background {
  background-color: $palette-primary-main;
  color: $white;
  margin-bottom: 0 !important;
}

.empty-state .text-content {
  word-wrap: break-word;
  white-space: normal;
  max-width: 660px;
}

.sortable-item__container {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  user-select: none;
  position: relative;
  overflow: hidden;

  &.expanded {
    background-color: $palette-primary-main-30;
  }

  &:not(.expanded) {
    background-color: $white;
  }
}

.rubric-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: $white;
  border-top-left-radius: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 0.6rem;
  padding-right: 0.6rem;
}

.legend-modal-preview {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid rgb(0 0 0 / 12%);
  border-radius: 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding: 16px;
  z-index: 10;
  width: 500px;
}

.section-title {
  color: $palette-secondary-main;
  font-weight: bold;
  margin-bottom: 16px;
}

.legend-box,
.color-box {
  margin-bottom: 15px;
}

.legend-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.legend-text {
  display: flex;
  align-items: center; /* Align icon and title in a row */
  gap: 8px; /* Spacing between icon and title */
}

.legend-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.legend-title {
  font-size: 16px;
  font-weight: 600;
}

.legend-description {
  margin-left: 32px;
  margin-top: 4px;
  font-size: 14px;
  color: #6b7280;
}

.color-box-icon {
  width: 15px;
  height: 15px;
  margin-right: 8px; /* Spacing between icon and title */
  border-radius: 3px; /* Rounded corners */
  display: inline-block; /* Ensures it's inline with the title */
  background-color: #ccc; /* Fallback color to test visibility */
}

.popover-container {
  position: fixed;
  z-index: 1000;
}

.popover-arrow {
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  right: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.popover-content {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.popover-header .bold {
  font-weight: bold;
  border: none !important;
  background-color: transparent !important;
}

.popover-header .MuiSvgIcon-root {
  cursor: pointer;
  margin-left: 15px;
}

.popover-description {
  padding: 8px 16px;
  max-width: 500px;
}
