@import '/src/variables';

.root-wrapper {
  background: linear-gradient(0deg, #fff, 5%, $palette-primary-main);
}

.loader-override {
  background: transparent !important;
  flex-direction: column;
  height: 75%;
}

.over-flow-hide,
.over-flow-hide * {
  overflow: hidden;
}

.custom-theme {
  .ag-react-container {
    width: auto;
  }
}

.stage-workflow {
  .ag-center-cols-viewport {
    overflow-x: hidden;
  }
}

.description-tooltip {
  display: block !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden;

  p {
    text-overflow: ellipsis;
  }
}
