// @import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
// @import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '/src/variables';
@import './AgGrid';

.list-companies,
.list-contacts,
.list-work-benches {
  height: 100%;
  width: 100%;
}

.list-view {
  height: 100%;
  width: 100%;
  position: relative;
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow: auto auto;
}

#checkbox-component {
  display: flex;
  margin: 0;

  .checkbox-container {
    height: 24px;
    width: 24px;
    color: $palette-secondary-main;
  }
}

.columns-icon {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  right: 5px;
  min-height: 30px;
  z-index: 1;
  cursor: pointer;
}

.badge {
  height: 10px;
  width: 10px;
  background: red;
  border-radius: 50%;
}

.badge-wrapper {
  height: 30px;
  width: 30px;
  position: fixed;
  left: -14;
  top: 17;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-wrapper {
  position: relative;
}

.menu-icon {
  color: $white;
  width: 30px;
  height: 30px;
}

.toolbar {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  padding: 20px 2px;
  justify-content: space-between;

  .search-icon {
    height: 40px;
    width: 40px;
    padding: 0;
    border: 1px solid #dddde2;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .search:disabled {
    background-color: #f4f4f4;
    box-sizing: border-box;
    color: #c6c6c6;
  }

  .search {
    box-sizing: border-box;
    color: #a8a8a8;
    width: 255px;
    padding: 6px;
  }

  .search:focus {
    border: 2px solid #0f62fe;
    box-sizing: border-box;
  }

  .start-query {
    margin-left: 15px;
    background: $white;
    border: 1px solid #5d59a3;
    box-sizing: border-box;
    border-radius: 2px;
    outline: none;
  }

  .rangeof {
    margin: 0;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;

    .MuiButtonBase-root {
      padding: 0;
    }
  }
}

.query-toolbar {
  border-bottom: 1px solid #e9e9ea;
  box-shadow: 2px 1px 7px rgb(0 0 0 / 10%);
  padding: 23px 0 23px 21px;

  .MuiAccordion-root.MuiPaper-elevation1 {
    box-shadow:
      -1px 2px 1px -1px rgb(0 0 0 / 20%),
      -1px 1px 1px 0 rgb(0 0 0 / 14%),
      0 1px 0 0 rgb(0 0 0 / 12%);
  }
}

.query {
  width: 97%;

  .second-row {
    max-height: 68px;

    .query-button {
      width: 116px;
      margin-top: 15px;
      background-color: #5d59a3;
      font-weight: 500;
      font-size: 16px;
      color: $white;
      text-transform: none;
      max-height: 38px;
      outline: none;
    }

    .save-query,
    .export-list {
      margin-top: 15px;
      margin-left: 15px;
      border-radius: 2px;
      width: 136px;
      outline: none;
    }
  }

  .date-range {
    max-height: 64px;
    max-width: 23.6%;
    margin-right: 30px;

    .range-label {
      letter-spacing: 0.09em;
    }

    .to-label {
      padding: 0 12px 9px;
    }

    .MuiFormControl-root {
      margin-top: 0;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }

  .revenue-range {
    max-width: 23%;
    margin-right: 30px;

    .range-label {
      letter-spacing: 0.09em;
    }

    .to-label {
      padding: 0 12px;
    }
  }

  .query-input {
    width: 25%;
    margin-bottom: 20px !important;
    margin-right: 20px !important;

    .MuiChip-root {
      height: 25px;
      border-radius: 5px;
    }
  }
}

.action-label {
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;

  &:hover,
  &:active {
    color: #5f51cb;
  }
}

.pop-over-actions {
  background-color: $white;
  padding: 8px 10px;
  width: 150px !important;
  border-radius: 5px;
  border: 0.5px rgb(139 129 129);
  font-size: 14px !important;

  .pop-over-action-item {
    padding: 2px 0;
    text-align: start;
    font-size: 12px;
  }

  .pop-over-action-value {
    cursor: pointer;
    font-size: inherit;
  }
}

.richtext-hover {
  background-color: #eee;
}

.add-contact-to-workbench-content {
  width: 550px !important;
  height: auto !important;

  &.popup-content {
    width: 30% !important;
    height: 30% !important;
    padding: 0 !important;
    background: #f4f4f4 !important;
    overflow: auto;
  }
}

.quick-add-contact-linkedin-content {
  &.popup-content {
    width: max-content !important;
  }
  height: max-content !important;

  .popup-content {
    height: 100% !important;
  }
}

.quick-add-contact-linkedin {
  overflow: auto;
}

.quick-add-header-linkedin {
  height: 67px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: #f5f5f6;

  p {
    flex-grow: 1;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #31394d;
  }
}

.quick-add-container-linkedin {
  .quick-add-content-linkedin {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 0;
    background: $white;
    overflow: auto;
  }
}

.quick-add-footer-linkedin {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 20px;

  .button {
    outline: none;
    margin-right: 10px;
    padding: 4px 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.phone-input {
  .country-list {
    height: auto !important;
  }
}

.text-input {
  padding: 0 16px;
  border-radius: 4px;
  background-color: rgb(149 159 183 / 9%);
  box-sizing: border-box;
  font-family: open-sans;
  color: #a8a8a8;
  text-align: left;
  display: flex;
  width: 312px;
}

.company-autocomplete-popup {
  height: 373px;
  width: 300px;
}

.pop-over-action {
  background-color: $white;
  padding: 8px 10px;
  width: 250px !important;
  min-height: 600px;
  border-radius: 5px;
  border: 0.5px rgb(139 129 129);

  .pop-over-action-item {
    padding: 2px 0;
    text-align: start;
    font-size: 12px;
  }
}

.auto-class {
  padding: 32px 19px 0;
}

.close-icon-class {
  margin: 5px 0 5px 19px;
  right: 20px;
  color: rgb(139 129 129);
  position: fixed;
}

.loader-class {
  color: $palette-primary-main !important;
}

.link {
  text-decoration-color: $black;
}

.list-checkbox-container {
  height: 40px;
  position: relative;

  .check-shift {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.typography-text-field {
  padding: 10px;
}

.black-color {
  color: $black;
}

.heading-main-color {
  color: $palette-heading-main;
}

.color-primary-main {
  color: $palette-primary-main;
}

.moveVerticalIconStyle {
  color: $cool-gray;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.MuiPopover-root .MuiBox-root:focus-visible {
  outline: none;
}
