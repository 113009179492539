@import '/src/variables';

.text-field {
  width: 300px;

  .css-1yz3cqa-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: $border-radius;
  }
}

#contact-search {
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $gray;
  text-align: left;
  display: flex;
  height: 42px;

  .loader-class {
    color: $palette-primary-main;
  }

  .search-icon {
    height: 40px;
    width: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .search {
    box-sizing: border-box;
    width: 255px;
  }

  .search:disabled {
    background-color: $gray-lighter;
    box-sizing: border-box;
  }

  .grid-search {
    top: 10;
    width: 300;
    height: 40;
  }
}

.add-search {
  background-color: $cornflower-blue;
}

.add-contact-to-project {
  background-color: $white;
}
