@import '/src/variables';

.background-white {
  background-color: $palette-title-bar-main;
  box-shadow: 0 0 3px #ccc;
}

.card-img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-15 {
  border-radius: 15px !important;
}

.responsive-width {
  @media (width >= 2621px) {
    max-width: 340px;
  }

  @media (width <= 2620px) and (width >= 2519px) {
    max-width: 340px;
  }

  @media (width <= 2520px) and (width >= 2419px) {
    max-width: 320px;
  }

  @media (width <= 2420px) and (width >= 2319px) {
    max-width: 300px;
  }

  @media (width <= 2320px) and (width >= 2219px) {
    max-width: 280px;
  }

  @media (width <= 2220px) and (width >= 2119px) {
    max-width: 260px;
  }

  @media (width <= 2120px) and (width >= 2019px) {
    max-width: 240px;
  }

  @media (width <= 2020px) and (width >= 1920px) {
    max-width: 220px;
  }

  @media (width <= 1921px) and (width >= 1820px) {
    max-width: 200px;
  }

  @media (width <= 1819px) and (width >= 1720px) {
    max-width: 180px;
  }

  @media (width <= 1719px) and (width >= 1620px) {
    max-width: 160px;
  }

  @media (width <= 1619px) and (width >= 1520px) {
    max-width: 110px;
  }

  @media (width <= 1519px) and (width >= 1420px) {
    max-width: 205px;
  }

  @media (width <= 1419px) and (width >= 1320px) {
    max-width: 175px;
  }

  @media (width <= 1319px) and (width >= 1220px) {
    max-width: 155px;
  }

  @media (width <= 1219px) and (width >= 1120px) {
    max-width: 200px;
  }

  @media (width <= 1119px) and (width >= 1020px) {
    max-width: 200px;
  }

  @media (width <= 1019px) and (width >= 900px) {
    max-width: 200px;
  }

  @media (width <= 899px) and (width >= 750px) {
    max-width: 300px;
  }

  @media (width <= 749px) and (width >= 700px) {
    max-width: 250px;
  }

  @media (width <= 699px) and (width >= 640px) {
    max-width: 250px;
  }
}

.responsive-width-small {
  @media (width >= 2621px) {
    max-width: 320px;
  }

  @media (width <= 2620px) and (width >= 2519px) {
    max-width: 320px;
  }

  @media (width <= 2520px) and (width >= 2419px) {
    max-width: 300px;
  }

  @media (width <= 2420px) and (width >= 2319px) {
    max-width: 280px;
  }

  @media (width <= 2320px) and (width >= 2219px) {
    max-width: 260px;
  }

  @media (width <= 2220px) and (width >= 2119px) {
    max-width: 240px;
  }

  @media (width <= 2120px) and (width >= 2019px) {
    max-width: 220px;
  }

  @media (width <= 2020px) and (width >= 1920px) {
    max-width: 200px;
  }

  @media (width <= 1921px) and (width >= 1820px) {
    max-width: 180px;
  }

  @media (width <= 1819px) and (width >= 1720px) {
    max-width: 160px;
  }

  @media (width <= 1719px) and (width >= 1620px) {
    max-width: 140px;
  }

  @media (width <= 1619px) and (width >= 1520px) {
    max-width: 225px;
  }

  @media (width <= 1519px) and (width >= 1420px) {
    max-width: 205px;
  }

  @media (width <= 1419px) and (width >= 1320px) {
    max-width: 165px;
  }

  @media (width <= 1319px) and (width >= 1220px) {
    max-width: 145px;
  }

  @media (width <= 1219px) and (width >= 1120px) {
    max-width: 125px;
  }

  @media (width <= 1119px) and (width >= 1020px) {
    max-width: 80px;
  }

  @media (width <= 1019px) and (width >= 900px) {
    max-width: 60px;
  }

  @media (width <= 899px) and (width >= 750px) {
    max-width: 110px;
  }

  @media (width <= 749px) and (width >= 700px) {
    max-width: 90px;
  }

  @media (width <= 699px) and (width >= 640px) {
    max-width: 65px;
  }
}
