@import '/src/variables';

#company_view_drawer {
  z-index: 1;

  .company-drawer-body {
    height: 100vh;
    justify-content: right;
    overflow: hidden;
  }

  .company-details-head {
    // width: 100vw !important;
    padding-top: 40px;
    overflow: hidden;
    background-color: transparent;
    box-shadow: none !important;
    width: 100% !important;
    height: 100%;

    .sub-company-details {
      background-color: $bluish-gray !important;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.drawer-head {
  flex-shrink: 0;
  background-color: transparent;
}

.rich-text-modal-action-box {
  #CustomRichText {
    .react-quill-container {
      .MuiStack-root {
        top: 4px;
        width: 50%;
        border: 0;

        .MuiStack-root {
          width: 100%;
        }
      }

      .quill {
        .ql-toolbar {
          &:hover {
            background: transparent !important;
          }
        }

        .ql-container {
          border-top: 1px solid #e0e4ec !important;

          .ql-editor {
            padding-top: 10px !important;

            &:focus {
              background: transparent !important;
            }
          }
        }
      }
    }
  }
}

.open-company-header-drawer {
  #activity-tab {
    height: calc(100vh - 492px);
    overflow-y: scroll;
    padding-top: 15px;
    padding-right: 7px;
    transition: height 0.5s;
  }
}

.close-company-header-drawer {
  #activity-tab {
    height: calc(100vh - 313px);
    overflow-y: scroll;
    padding-top: 15px;
    padding-right: 7px;
    transition: height 0.5s;
  }
}
