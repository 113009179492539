@import '/src/variables';

.home_body {
  height: 100%;
  width: 100%;
  margin: auto 0;
  background-color: $content-background;
  overflow-y: auto;
  position: relative;
  display: grid;
  grid-template-rows: 320px calc(100% - 320px);
}

.home_first_section {
  flex-grow: 1;
  margin: auto;
  height: 100%;
  padding: 15px 0 0;
  width: 100%;

  .top_section {
    height: 100%;
    align-items: center;

    .left_top_section {
      height: 100%;
      width: 100%;

      // max-height: 350px;
      align-items: stretch;
      padding: 0 60px;

      .left_first_section {
        height: 90%;
        padding: 0;
        display: grid;
        min-height: 84px;
        grid-template-columns: repeat(7, 1fr);

        .left_first_section_item {
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          gap: 10px;
          cursor: pointer;

          .left_first_section_item_text {
            width: 100%;
            text-align: center;
          }
        }

        .not_last {
          border-right: 1px solid $dash-border-color;
        }
      }

      .paper_custom {
        height: 100%;
        padding: 16px 0;
        margin: auto;
      }

      .stack_custom {
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .typography_custom {
        font-weight: 600;
        text-align: center;
      }

      .inner_stack_custom {
        height: 100%;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
      }

      .box_custom {
        height: 60px;
        background: $tab-background-color;
        width: 1px;
      }
    }

    .right_top_section {
      height: 100%;
      width: 96%;

      // max-height: 350px;
    }

    .paper_custom {
      width: 95%;
      margin: auto;
      height: 270px;
      padding: 0.5rem;
    }

    .box_custom {
      width: 100%;
      height: 100%;
    }

    .tabs_indicator_custom {
      border-bottom: 1px solid;
      border-color: divider;

      & .MuiTabs-indicator {
        background-color: $indigo;
      }
    }

    .tab_label_custom {
      color: $indigo !important;
    }

    .activities_list_custom {
      padding: 12px;
      background-color: $content-background;
      margin: 10px 8px;
      border-radius: 8px;
      overflow-y: scroll;
      height: 190px;
    }

    .activity_reference {
      color: $candidate1-tag-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.home_second_section {
  height: 96%;
  min-height: 250px;
  width: 99%;
}

.recent_scroll {

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $white;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
}

.section_stack {
  direction: column;

  // spacing: 0.5;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  .header_title {
    text-align: center;
    cursor: pointer;
  }

  .middle_content {
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }

  .bottom_content {
    text-align: center;
    cursor: pointer;
    padding: 0 0.5rem;
  }

  .no_link {
    cursor: default;
    text-decoration: none;
  }
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: relative;
  margin-bottom: 2px;
}

.add_icon {
  position: absolute;
  bottom: -20px;
  right: -20px;
}

.border_bottom {
  border-bottom: 1px solid $cool-gray;
}

.none {
  display: none;
}

.interview_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.interview_modal_content {
  background-color: $white;
  padding: 16px;
  border-radius: 8px;
  width: 80%;
  height: 70%;
  gap: 28px;
  position: relative;
}

.close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 8px;
  z-index: 10;
  background-color: $white;
  border-radius: 0 8px 0 0;
  color: $indigo;
}

.list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tab_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.orange {
  color: $orange-color;
}
