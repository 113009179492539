.contact-view {
  padding: 20px;
}

.section-container {
  width: 100%;
  margin-bottom: 25px;

  .section-header {
    display: flex;
    align-items: center;
    font-weight: bolder;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.004em;
    color: #1a1e3c;
    margin-bottom: 15px;
  }
}

.secion-sub-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: rgb(26 30 60 / 40%);
  margin-bottom: 15px;
}

.contact-details {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding-right: 20px;
  margin-bottom: 10px;

  .contact-view-label {
    font-size: 12px !important;
    line-height: 14px;
    cursor: pointer;
    color: rgb(92 82 198 / 80%);
    margin-bottom: 2px;
  }

  .contact-view-value {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    color: #373956;
    word-break: break-word;
  }
}

.athena-invite-popup-content.athena-invite {
  width: 40% !important;
  height: 50% !important;
  max-height: 800px;
  max-width: 700px;
}

.add-reference-content.add-reference-content {
  width: 40% !important;
  height: 60% !important;
  max-width: 600px !important;
  max-height: 400px !important;
}

.edit-reference-content.edit-reference-content {
  .react-quill-container {
    width: 100%;
  }
}

.reference-row {
  width: 100%;
  display: flex;

  .reference-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 20px 30px 10px;

    .label {
      color: #5c52c6;
      font-size: 14px;
    }
  }
}

.off-limit-badge {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 8px;
  background-color: red;
  color: white;
  text-align: center;

  .details-link {
    display: inline;
    font-weight: 600;
    color: inherit;
    padding-left: 5px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.transform {
  text-transform: none !important;
}
