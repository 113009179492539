.ag-theme-alpine {
  ::-webkit-scrollbar {
    appearance: none;
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f4f4f4;
    border: 1px solid #dddde9;
    border-radius: 23px;
  }

  ::-webkit-scrollbar-thumb {
    background: #dddde9;
    border-radius: 23px;
    box-shadow: inset 0 0 6px rgb(180 180 187);
  }
  position: relative;
  height: 100%;

  .ag-cell-focus,
  .ag-cell-no-focus {
    border-color: transparent !important;
  }

  .ag-cell:focus {
    border-color: transparent !important;
    outline: none;
  }

  .ag-body-vertical-scroll {
    width: 12px !important;
    min-width: 12px !important;
    max-width: 12px !important;

    .ag-body-vertical-scroll-viewport {
      width: 12px !important;
      min-width: 12px !important;
      max-width: 12px !important;
    }
  }

  .ag-body-horizontal-scroll {
    height: 12px !important;
    min-height: 12px !important;
    max-height: 12px !important;

    .ag-body-horizontal-scroll-viewport {
      height: 12px !important;
      min-height: 12px !important;
      max-height: 12px !important;
    }
  }

  .ag-react-container {
    display: flex;
    justify-content: left;
    width: 100%;
  }

  .ag-tool-panel-wrapper .ag-react-container ul.action-button-list {
    width: 100%;
    padding: 8px;

    li {
      margin-bottom: 8px;

      .button-action-wrapper {
        margin: 0;
        border-radius: 6px;
        text-align: center;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 13px;
        }

        .button-action {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: unset !important;
          margin-left: 8px;
        }

        svg {
          width: 18px;
        }
      }
    }
  }

  // .ag-cell{
  //   line-height: 65px !important;
  // }
  .fullHeight {
    height: 100%;
  }

  .line-height-auto {
    line-height: normal;
  }

  .contact-row {
    .ag-cell {
      line-height: 65px !important;
    }

    .contact-action-cell {
      margin-top: 20px;
    }
  }

  .company-row {
    .ag-cell {
      display: inline-block;
      height: 100%;
      position: absolute;
      white-space: nowrap;

      .long-text {
        display: flex;
        height: 100%;
        position: absolute;
        white-space: nowrap;
        align-items: center;

        .multi-line-ellipsis {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          max-height: 3.5em;
          line-height: 1.5;
          white-space: break-spaces;
        }
      }
    }

    .contact-action-cell {
      margin-top: 7px;
    }
  }

  .pipelineContact {
    height: 100%;
    justify-content: unset !important;
    align-items: center !important;

    p {
      margin-left: 0 !important;
    }
  }

  .ag-header-cell-resize {
    z-index: unset !important;
  }

  /*
  .ag-root {
    box-sizing: border-box;
  }

  .ag-header {
    width: 1501px;
    height: 59px;
    padding: 2px;
    border-radius: $border-radius;
    //background-color: $palette-primary-main;
    box-sizing: border-box;
    box-shadow: 0 1px 2px 0 $light-black;

    .ag-header-cell-label {
      justify-content: center;
      //background-color: $palette-primary-main;
    }

    .ag-header-cell-text {
      font-size: 16px;
      color: $white;
      font-family: avenir !important;

      #name {
        width: 52px;
        height: 24px;
        background-color: rgb(255 255 255 / 0%);
        box-sizing: border-box;
        font-family: Avenir-Heavy, 'Avenir Heavy', 'Avenir Book', Avenir, sans-serif;
        font-weight: 800;
        color: $white;
        text-align: left;
        line-height: normal;
      }
    }

    .ag-header-icon,
    .ag-header-cell-menu-button,
    .ag-header-cell-label .ag-icon {
      color: $white;
    }

    // Ensure sort icons are white
    .ag-sort-indicator-icon {
      color: $white;
    }
  }

  .ag-theme-alpine .ag-header .ag-header-cell-text {
    height: 24px;
    font-size: 16px;
    box-sizing: border-box;
    font-family: Avenir-Heavy, 'Avenir Heavy', 'Avenir Book', Avenir, sans-serif;
    font-weight: 800;
    color: $white;
    text-align: left;
    line-height: normal;
  }

  .ag-header-icon {
    color: $white;
  }

  .ag-cell-value {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .ag-ltr {
    .ag-has-focus {
      outline: none;
      border: none;
    }
  }

  .ag-body-viewport {
    box-shadow: 0 0 8px rgb(219 226 243 / 60%);
  }

  .ag-popup {
    position: absolute;
  }

  .ag-filter {
    width: 234px;
  }

  .ag-react-container {
    width: 100%;
  }

  .ag-react-container input {
    border: none;
    outline: none;
  }

  .ag-cell-inline-editing .ag-text-field-input {
    border: 1px solid $black !important;
  }

  .ag-cell-inline-editing .ag-react-container {
    border: 1px solid $black !important;
    border-radius: 12px;
    background-color: $white;
    height: 100%;
    line-height: 0;
    overflow: hidden;

    * {
      height: 100%;
    }
  }

  .ag-cell-inline-editing .ag-react-container input {
    padding-left: 6px;

    &:not(.MuiInput-input) {
      height: 100%;
      width: 100%;
    }
  }

  .ag-cell-inline-editing {
    .ag-react-container:has(input[type='checkbox']) {
      background-color: transparent;
      border: none !important;
      display: contents;

      input {
        width: 24px;
        height: 24px;
        padding: 2px;
        background-color: rgb(255 255 255 / 0%);
        box-sizing: border-box;
      }
    }
  }
*/
}
