.right-nav-section {
  margin-bottom: 20px;
  border-left: 2px solid #dddde2;

  .right-nav-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.004em;
    color: #1a1e3c;
    padding-left: 9px;
  }

  .righ-nav-item {
    font-size: 14px;
    line-height: 216.7%;
    color: #939393;
    letter-spacing: 0.003em;
    padding-left: 9px;
    cursor: pointer;

    &.active {
      color: #5c52c6;
      border-left: 2px solid #5c52c6;
      margin-left: -2px;
    }
  }
}
