@import '/src/variables';

#security-page {
  .security-page__card {
    background-color: $white;
    padding: 1rem 3rem;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 14px 11px 6px -7px rgb(0 0 0 / 10%);
  }
}
