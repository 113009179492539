@import '/src/variables';

.invalid-message {
  color: red !important;
  font-size: 12px;
  text-align: left;
  padding-left: 0.8rem;
}

#view-company {
  overflow-y: auto;

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 40px;
  }

  .details-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    // height: 56vh;
    height: calc(100vh - 350px);
    transition: height 0.5s;
  }

  .toggle-button {
    border: 1px solid $palette-primary-main;
    cursor: pointer;
    background-color: $white;
    color: $palette-primary-main;
  }

  .toggle-button.active {
    background-color: $palette-primary-main;
    color: $white;
  }

  .details-container-close {
    width: 100%;
    display: flex;
    flex-direction: column;

    // height: 74vh;
    height: calc(100vh - 220px);
    transition: height 0.5s;
  }

  .error-message-text {
    color: $palette-error-main;
    height: 12px;
  }

  .MuiFormLabel-root {
    font-size: 0.75rem;
  }

  .engagement-wrapper {
    .ag-header {
      min-height: 40px !important;
    }
  }

  .viewcontact-datepicker {
    width: 19vw;
  }

  .text-field-content {
    height: 0;
  }

  .custom-drop-down-label-font {
    font-size: 12px;
  }

  .details-container-subtab {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 75vh;
  }

  .text-style {
    text-decoration: underline;
  }

  .details-container-subtab-close {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .scroll-content {
    overflow-y: auto;
  }

  .contact-details .contact-details-value {
    border: 1px solid transparent;
  }

  .contact-details:hover .contact-details-value {
    border: 1px solid $palette-bg-main;
  }

  .hover-border:hover {
    .apply-border {
      border: 1px solid $palette-bg-main;
    }
  }

  .hover-border .apply-border {
    border: 1px solid transparent;
  }

  .content-space {
    gap: 4px !important;
  }

  .revenue-gap {
    gap: 20px;
  }

  .revenue-field {
    width: 171px;
  }

  .compensation-autocomplete {
    .MuiAutocomplete-root {
      width: 120px;
    }
  }

  .contact-selection {
    .AutoCompleteStyle {
      width: 187px;
    }
  }

  .divider {
    width: 24px;
  }

  .highlight-richtext {
    width: 45vw;
  }

  .section-details {
    display: flex;
    flex-flow: row wrap;
  }

  .rounded-right {
    border-radius: 0 5px 5px 0;
  }

  .rounded-left {
    border-radius: 5px 0 0 5px;
  }

  #CustomRichText .ql-container .ql-editor {
    max-height: 200px;
    overflow-y: auto;
  }

  .comment-text {
    min-width: 35vw;
  }

  .typo-box {
    min-width: 184px;
  }

  .content-details {
    flex: 60%;
  }

  .column-gap {
    column-gap: 5px;
  }

  .row-gap {
    row-gap: 5px;
  }

  .typo-gap {
    column-gap: 5px;
  }

  .section-block {
    min-width: 114px;
  }

  .custom-drop-down-size {
    width: 15vw;
  }

  .industry-jobfunction-custom-drop-down-size {
    width: 20vw;
  }

  .personality-head {
    width: 43vw;
  }

  .personality_sub {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title-color {
    color: $black;
  }

  .content-color {
    color: $palette-heading-main;
  }

  .compact-auto {
    width: 10vw;
  }

  .industry-jobfunction-compact-auto {
    width: 20vw;
  }

  .close-icon {
    color: $palette-error-main;
  }

  .viewcontact-icon-style {
    .MuiSvgIcon-root {
      width: 13px !important;
      height: 20px !important;
    }
  }

  .viewcontact-checkbox {
    .MuiSvgIcon-root {
      width: 25px;
      height: 25px;
    }
  }

  .download-icon {
    color: $palette-heading-main;

    .MuiSvgIcon-root {
      width: 20px;
      height: 28px;
      padding-bottom: 5px;
    }
  }

  .card-carousels {
    width: 100%;
  }

  .compensation-section {
    display: flex;
    flex-flow: row wrap;
    margin: 2%;
  }

  #bio-card {
    .custom-card {
      width: 357px;
      height: 143px;
    }

    .personality-card {
      width: 100%;
      max-width: 343px;
      height: 100%;
      max-height: 128px;
    }
  }

  .add-another-btn {
    width: fit-content;
    text-transform: capitalize;
  }

  .communication-section {
    border-radius: 8px;
    display: flex;
    background-color: $grayish-blue;
    flex-flow: column wrap;
    gap: 32px;
  }

  .sub-head-content {
    gap: 15px;
    flex-flow: row wrap;
  }
}

#view-company-content {
  width: 45vw;

  .MuiTypography-body1 {
    color: $white;
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }

  .table-edit-icon {
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .table-editclose-icon {
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 17px;
    max-height: 16px;
  }

  .add-another {
    padding-left: 12px;
  }

  .MuiTableCell-root {
    border-bottom: 0;
  }

  .table-header-background {
    background-color: $palette-primary-main;
    color: $white;
  }

  .header-color {
    background-color: $content-background;
  }

  .delete-icon-pointer {
    cursor: pointer;
  }
}

@media (width >= 768px) and (width <= 842px) {
  #view-company {
    .revenue-field {
      width: 120px;
    }
  }
}

@media (width > 767px) and (width < 1024px) {
  #view-company {
    .details-container {
      //   height: 56vh;
    }

    .details-container-subtab {
      //     height: 50vh;
    }

    #view-company-content {
      width: 43vw;
    }

    .personality-head {
      width: 41vw;
    }

    .highlight-richtext {
      width: 43vw;
    }
  }
}

@media (width > 1023px) and (width < 1440px) {
  #view-company {
    .details-container {
      //   height: 59vh;
    }

    .details-container-subtab {
      //   height: 52vh;
    }
  }

  .drawer-tab-layout {
    height: 55vh;
  }
}

@media (width > 1439px) and (width < 1646px) {
  #view-company {
    .details-container {
      //  height: 56vh;
    }

    .details-container-subtab {
      //  height: 48vh;
    }
  }

  .drawer-tab-layout {
    height: 52vh;
  }
}

.address {
  .input-field-data {
    width: 300px;
    padding-top: 22px;
  }

  .set-position {
    width: fit-content;
    padding-left: 20px;
    margin-top: -29px;
  }
}

.all-company-tabs {
  .tab-layout-content {
    min-height: unset !important;
  }
}
