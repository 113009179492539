@import '/src/variables';

.pop-over-email,
.pop-over-phone {
  .email-label,
  .phone-label {
    margin-right: 5px;
  }
}

.special-column-filter {
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 40px;
  z-index: 1;
  cursor: pointer;
}

.pop-over-name {
  border: 1px solid #dddde9;
  box-sizing: border-box;
  box-shadow: 0 0 14px $light-black;
  width: 770px;
  max-height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;

  .name-popover-top-section {
    background: #f5f5f6;
    padding: 20px;
  }

  .contact-profile-info-helper-text {
    color: #8b8b8b;
    font-size: 14px;
  }

  .name-popover-content {
    padding: 30px 20px;
    overflow: auto;
  }

  .compensation-details,
  .industry-details,
  .education-details {
    margin-bottom: 5px !important;
  }
}

.popover {
  max-width: none !important;
}

.saved-queries {
  width: 23%;
  margin: 15px 0 15px 25px;
}

.list-contacts {
  .range-label {
    margin-top: 3px;
    margin-bottom: 0;
  }
}

.inside-container {
  .input-field {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 10px;
  }
}

.disagree-disable {
  pointer-events: none;
  opacity: 0.5;
}

.nameover-top {
  width: 50%;

  .nameover-top-email {
    width: 90%;
  }
}

// .ag-root-wrapper .ag-layout-normal {
//   border-radius: $border-radius;

//   .ag-header {
//     width: auto;
//     padding: 2px;
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//     // background-color: $palette-primary-main;
//     box-sizing: border-box;
//     box-shadow: 0 1px 2px 0 $light-black;

//     .ag-header-cell-label {
//       justify-content: center;
//     }
//   }
// }

input[type='checkbox']:checked {
  accent-color: $palette-primary-main;
}

// .ag-theme-alpine .ag-cell-value {
//   .tag-container {
//     display: flex;
//     flex-direction: column;
//     gap: 3px;
//     align-items: center;
//   }

//   .tag-pointer {
//     cursor: pointer;
//   }
// }

.image-border {
  width: 32px;
  height: 32px;
  background-color: $light-aqua;
  border: 1px solid $palette-primary-main;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .exportIconStyle {
    width: 19px;
    height: 32px;
    box-sizing: border-box;
    color: $palette-primary-main;
  }
}

.add-contact {
  min-width: 80px;
  height: 19px;
  background-color: $transparent-white-color;
  box-sizing: border-box;
  color: $white;
  text-align: center;
  line-height: normal;
  text-transform: none;
  margin-left: 4px;
}

#pop-up-on-click {
  .moveVerticalIconStyle {
    color: $cool-gray;
    width: 100%;
    height: 30px;
    cursor: pointer;
  }
}

.header-checkbox {
  padding: 0;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  color: $white;
}

.custom-checkbox-root .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  color: $white;
}

.text-field {
  width: 300px;

  .css-1yz3cqa-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: $border-radius;
  }
}

#view-contact-drawer {
  position: relative;
}

.hamburger-icon {
  cursor: pointer;
  margin-left: -16px;
  color: white;
  margin-top: 60vh;
}

// .nav-item p {
//   opacity: 0.8;
// }

// .nav-item:hover p {
//   opacity: 1;
// }

// .side-nav-top-actions {
//   min-width: 237px;
// }

.add-new {
  visibility: hidden;
}

// .export-side-nav-container.open .add-new {
//   visibility: visible;
// }

// .nav-item {
//   padding: 12px 0.4vw;
//   cursor: pointer;
//   white-space: nowrap;
//   color: white;
//   font-weight: 500;
//   margin-bottom: 40px;
//   height: 34px;
// }

// .nav-item.active {
//   color: $palette-primary-main;
//   background: $palette-bg-main;
//   width: fit-content;
//   border-radius: 11px;
//   box-shadow: 1px 2px 2px 0 $box-shadow;
// }

// .nav-item:hover {
//   color: $palette-primary-main;
//   background: $palette-bg-main;
//   height: 34px;
//   border-radius: 11px;
//   box-shadow: 1px 2px 2px 0 $box-shadow;
// }

#side-pop-up {
  border-radius: 10px;
  background-color: $gray-lighter;

  .sidePopup-stack {
    display: flex;
    justify-content: flex-end;
  }

  .sidePopupContainer {
    height: inherit;
    width: inherit;
  }

  .side-popup-btn-2 {
    color: white;
    text-transform: none;
    background-color: $palette-primary-main;
  }

  .side-popup-btn {
    text-transform: none;
    color: $palette-error-main;
  }

  .save-name {
    margin: 4px;
  }
}

.action-list {
  height: 100%;
  position: absolute;

  /* display: inline-grid; */
  width: 151px;
  background-color: $white;
  z-index: 10;
}

.ag-theme-alpine .ag-header-cell-resize ::after {
  width: 1px !important;
}

#popover-search {
  max-width: none;
  transform: translate(115px, 162px);
}

.outline-icon {
  border: 0.5px solid $palette-heading-main;
  border-radius: $border-radius-full;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-child {
    color: $palette-heading-main;
  }
}

.loader-class {
  color: $palette-primary-main;
}

#contact-tab-container {
  &.list-contacts {
    height: calc(100vh - 150px);

    /* .range-label {
      margin-top: 3px;
      margin-bottom: 0;
    } */
  }

  .tool-bar {
    flex-grow: 1;
  }

  .MuiPaper-root {
    background-color: $white;
    box-shadow: none;
  }

  .MuiToolbar-root {
    padding: 0;
    min-height: fit-content;
  }

  .add-button {
    text-transform: none;
    background-color: $palette-primary-main;
    color: $white;
    text-align: left;
    line-height: normal;
    height: 40px;
  }
}

#contact-search {
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $gray;
  text-align: left;
  display: flex;
  height: 42px;

  .loader-class {
    color: $palette-primary-main;
  }

  .search-icon {
    height: 40px;
    width: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .search {
    box-sizing: border-box;
    width: 255px;
  }

  .search:disabled {
    background-color: $gray-lighter;
    box-sizing: border-box;
  }

  .grid-search {
    top: 10;
    width: 300;
    height: 40;
  }
}

.add-search {
  background-color: $cornflower-blue;
}

.add-contact-to-project {
  background-color: $white;
}

/* export side navbar */
#side-nav-container {
  z-index: 10;

  .side-nav-bar {
    position: relative;
    box-sizing: border-box;
    top: 5vh;
  }

  .side-nav-header {
    border-bottom: 1px solid $light-black;
  }

  .export-tab {
    text-transform: none;
  }

  .MuiTab-root.Mui-selected {
    color: $palette-heading-main;
    font-weight: bold;
  }

  .MuiTabs-indicator {
    background-color: $palette-heading-main;
    height: 2px;
  }
}

/* grid columns */

.content-wrap {
  text-align: left;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-content {
  box-sizing: border-box;
  font-weight: 800;

  // color: $black;
}

#name-column {
  align-items: center;
  display: flex;
  cursor: pointer;

  .column-width {
    width: 100px;
  }

  .contact-icon {
    width: 38px;
    height: 38px;
    border-radius: $border-radius-full;
  }
}

#about-renderer {
  // width: 214px;
  cursor: pointer;
  box-sizing: border-box;

  // color: $black;
}

#contact-details-renderer {
  .contact_details_icon {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    color: $cool-gray;
  }

  .details {
    width: 120px;
    box-sizing: border-box;
    color: $black;
  }

  .details-width {
    //  width: 152px;
    box-sizing: border-box;
    color: $black;

    a:hover {
      color: $black;
    }
  }
}

#assign-tag {
  .tag-dropdown {
    width: 35vw;
  }

  .tag-auto-complete {
    width: 100%;
  }
}

.tagHot {
  color: $palette-primary-main;
}

.tagBest {
  color: $palette-error-main;
}

.tagAverage {
  color: $candidate2-tag-color;
}

.tagPlaced {
  color: $palette-primary-main;
}

.tagDiversity {
  color: $candidate1-tag-color;
}

.tagActive {
  color: $palette-heading-main;
}

.tag-border {
  text-decoration: underline;
  text-align: center;
  width: fit-content;
}

.color-type-Candidates {
  color: $palette-heading-main;
}

.color-type-Employee {
  color: $light-grayish-navy;
}

.color-type-Client {
  color: $strong-cyan;
}

.color-type-BD {
  color: $light-radish;
}

.phone-input-read-only,
.phone-input-read-only > .PhoneInputInput > div > input {
  font-size: 0.6rem !important;
  padding: 0;
  margin: 0;
}

.phone-input-read-only > .PhoneInputInput {
  display: none;
}
