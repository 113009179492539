@import '/src/variables';

.checkboxStyle {
  .MuiSvgIcon-root {
    font-size: 30px;
  }

  .Mui-checked {
    .MuiSvgIcon-root {
      fill: #000;

      /* fill: $palette-secondary-main; */
    }
  }
}
