@import '/src/variables';

#custom-popup .MuiPaper-root div.MuiDialogContent-root.dialog-content {
  overflow: auto;
}

.capital-structure-label {
  // .MuiFormLabel-root {
  //   top: -8px;
  // }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #aa0a6c !important;
  }

  .Mui-focused.MuiFormLabel-root {
    top: -1px;
  }
}

#add-company {
  .add-company-paper {
    background-color: $gray-lighter;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 21px;
  }

  .field-gap {
    gap: 32px;
  }

  .error-field-gap {
    gap: 22px;
  }

  .fetch-icon {
    gap: 10px;
  }

  .input-field-data {
    min-width: 365px;
  }

  .AutoCompleteStyle {
    width: 365px;
  }

  .revenue-field {
    width: 225px;
  }

  .revenue-gap {
    gap: 20px;
  }

  .divider {
    width: 24px;
  }

  .rich-text-gap {
    width: 766px;
    gap: 10px;
  }

  .error-message-text {
    color: $palette-error-main;
    height: 12px;
  }

  .visible {
    visibility: visible;
  }

  .invisible {
    visibility: hidden;
  }

  .comment-text {
    color: $palette-heading-main;
  }

  .compensation-autocomplete {
    .MuiAutocomplete-root {
      width: 120px;
    }
  }
}
