@import '/src/variables';

.approval-icon {
  color: $mui-approval-icon;
  width: 29px;
  height: 29px;
}

.task-icon {
  color: $mui-approval-icon;
  width: 24px;
  height: 27px;
}

.notification-icon {
  color: $mui-notification-icon;
}
