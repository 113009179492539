@import '/src/variables';

.rich-text-popup-content {
  background-color: #fff !important;
  width: 60% !important;
  height: 70% !important;

  .rich-text-popup-header {
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddde2;
    padding: 0 20px;
    background: #f5f5f6;

    p {
      flex-grow: 1;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #31394d;
    }
  }

  .rich-text-popup-container {
    height: calc(100% - 140px);
    overflow: auto;
    padding: 20px;

    .quill {
      height: calc(100% - 45px);
    }
  }

  .rich-text-popup-actions {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #f5f5f6;
    padding: 20px;
    border-top: 1px solid #dddde2;

    button {
      margin-right: 20px;
      padding: 7px 30px;
    }
  }
}

@mixin full-content($content) {
  position: relative;
  width: max-content !important;

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: $content;
    top: -28px;
    position: absolute;
    left: -32%;
    width: max-content;
    background: #e0e0e0;
    padding: 2px 11px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #5c52c6;
  }

  &:hover::before,
  &:active::before,
  &:focus::before {
    content: '';
    top: -11px;
    position: absolute;
    left: 16%;
    width: 10px;
    height: 10px;
    background: #e0e0e0;
    padding: 2px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    transform: rotate(45deg);
  }
}

.ql-undo {
  @include full-content('Undo');
}

button[type='button'][value='bullet'].ql-list {
  @include full-content('Bullet List');
}

button[type='button'][value='ordered'].ql-list {
  @include full-content('Ordered List');
}

.ql-link {
  @include full-content('Link');
}

.ql-underline {
  @include full-content('Underline');
}

.ql-italic {
  @include full-content('Italic');
}

.ql-bold {
  @include full-content('Bold');
}

.ql-redo {
  @include full-content('Redo');
}

.ql-container.ql-snow.ql-disabled {
  left: -15px;

  &.ql-editor {
    padding: 0;
  }
}

.ql-container {
  font-size: 14px !important;
}

.ql-editor-custom {
  .ql-editor {
    min-height: 150px;
    padding-top: 15px !important;
  }
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-style: normal;
  color: $switch-back-gray;
}

#CustomRichText {
  .grid {
    .ql-toolbar.ql-snow {
      border: none !important;
      color: black;

      &:hover {
        border-bottom: none !important;
      }
    }

    .ql-editor {
      min-height: fit-content;
    }

    .ql-container.ql-snow {
      border: none !important;
      color: $black;

      &:hover {
        border-bottom: none !important;
      }
    }
  }

  .view-card {
    .ql-editor {
      padding: 0 !important;
    }
  }

  .ql-toolbar,
  .ql-container {
    border: 1px solid $palette-heading-main !important;
  }

  .ql-container {
    border-top: 0 !important;
    border-radius: 0 0 4px 4px;
    overflow: scroll;
    height: 50vh;

    .ql-editor {
      border-radius: 0 0 4px 4px;
      background: $white;

      &:focus {
        background-color: $input-focus;
        border-radius: 0 0 4px 4px;
      }
    }
  }

  .ql-toolbar {
    border-radius: 4px 4px 0 0;
    background: $white;
    border-bottom: none !important;

    &:hover {
      background-color: $input-focus;
    }
  }
}

// .position-profile-headers h1,
// .position-profile-headers h2,
// .position-profile-headers h3,
// .position-profile-headers h4,
// .position-profile-headers h5,
// .position-profile-headers h6 {
//   padding: 10px 0 5px !important;
// }

.position-profile-headers p {
  text-align: justify;
  text-justify: inter-word;
}

.ql-container.ql-snow {
  .ql-editor {
    h1,
    h2,
    h3 {
      margin-bottom: 10px;
      font-weight: bold;
      color: #333;
    }

    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.75em;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 1.5em;
      margin-top: 20px;
    }

    p {
      font-size: 1em;
      color: #333;
      line-height: 1.5;
      margin-bottom: 10px;
      text-align: justify;
    }

    ul,
    ol {
      padding-left: 20px;
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 10px;
    }

    blockquote {
      border-left: 4px solid #ccc;
      padding-left: 20px;
      margin: 20px 0;
      font-style: italic;
      color: #555;
    }

    p a {
      color: #007bff;
      text-decoration: none;
    }

    p a:hover {
      text-decoration: underline;
    }

    pre {
      background-color: #f8f9fa;
      padding: 15px;
      border-radius: 4px;
      font-size: 1em;
      overflow-x: auto;
      margin-bottom: 20px;
    }

    code {
      background-color: #f4f4f4;
      padding: 2px 5px;
      border-radius: 4px;
      font-size: 0.9em;
    }

    @media (width <= 768px) {
      h1 {
        font-size: 1.5em;
      }

      h2 {
        font-size: 1.25em;
      }

      h3 {
        font-size: 1.15em;
      }

      p {
        font-size: 1em;
      }
    }
  }
}

.publish-bio-text {
  .ql-container {
    height: 72vh !important;
  }
}

// #CustomRichText .ql-test {
//   height: 15vh;
// }

.projected-bill-date-text {
  .ql-container {
    height: 15vh !important;
  }
}
