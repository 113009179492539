.right-nav-searches-section {
  min-height: 150px;
}

.right-nav-recent-activity-section {
  min-height: 150px;
  padding-left: 10px;

  .right-nav-header {
    padding: 0;
  }
}

.searches-stage {
  background-color: lavender;
  text-align: center;
  margin-bottom: 10px;
  border-width: 1px;
}

.searches-date {
  font-size: 15px;
  color: grey;
}

.searches-job-title {
  color: black;
  font-size: 15px;
}
