@import '/src/variables';

.enrich-contact-dialog {
  padding: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header h2 {
  font-size: 24px;
  font-weight: 600;
}

.card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
}

.card-field {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.card-field svg {
  margin-right: 8px;
  color: #757575;
}

.card-field span {
  flex-grow: 1;
}

.button-group {
  display: flex;
  gap: 8px;
}

.button-group button {
  padding: 4px;
}

.button-group svg {
  font-size: 20px;
}

.crossed-out {
  text-decoration: line-through;
}

@media (width >= 1200px) {
  .pdl-card .MuiGrid-grid-xs-10 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

//////////////////////////////

.contact_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid #000;
}

.action_btn {
  display: flex;
}

.contact_card .content_box {
  background: #383838;
  padding: 12px 8px 7px;
  width: 90%;
  height: 100%;
}

.contact_card .content_box p {
  color: rgb(255 255 255);
  word-break: break-word;
  font-size: 13px;
}

.phone-label {
  font-size: 13px !important;
}

.enriched_contact_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 60px;
  border-bottom: 1px solid #000;
}

.enriched_contact_card .enriched_content_box {
  background: #fff;
  padding: 12px 8px 7px;
  width: 90%;
  height: 100%;
  color: #1b1b1b;
  word-break: break-word;
  font-size: 13px;
}

.title_enriched_card {
  color: $palette-secondary-main !important;
}

.job-history-item {
  display: flex;
}

.contact_card .content_box,
.enriched_contact_card .enriched_content_box {
  width: calc(100% - 50px);
}

.job-history-item span.MuiCheckbox-root {
  padding: 0;
}

// .last-col .enriched_contact_card .enriched_content_box {
//   background: #dce7ee;
// }

.job-history-label,
.email-label,
.education-label {
  // color: #3855b4;
  color: $palette-secondary-main !important;
}

.col.first-col {
  max-width: 26%;
}

.middle-col .enriched_content_box {
  width: 100%;
}

.enriched_content_box p.MuiTypography-root {
  color: #3c5064 !important;
  font-size: 13px;
}

.contact_card .content_box .job-history-item p {
  color: #3c5064;
}

.first-col .action_btn {
  padding-right: 0 !important;
}

.last-col .action_btn {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.action_btn svg {
  margin: 0 -3px !important;
}

.action_btn .MuiGrid-root button {
  padding: 0;
}
