@import '/src/variables';
.box {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.header-legend-title {
  padding-top: 20px;
  color: var(--palette-primary-main);
  font-weight: 900;
}

.dot {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-block;
}

.triangle {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 0;
  border-bottom: 20px solid;
}

.legends-title {
  font-weight: 900;
  margin-left: 26px;
  text-transform: capitalize;
  // font-size: 16px;
}

/* Media Query */
@media screen and (width <= 768px) {
  .header-legend {
    margin-top: 10%;
  }

  .header-legend-title {
    padding-top: 10px;
  }

  .dot {
    height: 10px;
    width: 10px;
  }

  .legends-title {
    margin-left: 10px;
  }
}

.info-box {
  width: 100%;
  height: 100%;
  border: 1px solid $black;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // align-items: center;
  background-color: $white;
  text-align: justify;
  padding: 8px;
}

.info-box-label {
  color: var(--palette-primary-main);
  // font-size: 16px;
}

// .label-desc {
//   font-size: 14px;
// }

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: $white;
  color: $black;
  border-radius: 3px;
  transition: all 0.1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
  text-align: justify;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
