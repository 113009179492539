.rich-text-popup-content {
  background-color: #fff !important;
  width: 60% !important;
  height: 70% !important;

  .rich-text-popup-header {
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddde2;
    padding: 0 20px;
    background: #f5f5f6;

    p {
      flex-grow: 1;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #31394d;
    }
  }

  .rich-text-popup-container {
    height: calc(100% - 140px);
    overflow: auto;
    padding: 20px;

    .quill {
      height: calc(100% - 45px);
    }
  }

  .rich-text-popup-actions {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #f5f5f6;
    padding: 20px;
    border-top: 1px solid #dddde2;

    button {
      margin-right: 20px;
      padding: 7px 30px;
    }
  }
}

@mixin full-content($content) {
  position: relative;
  width: max-content !important;

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: $content;
    top: -28px;
    position: absolute;
    left: -32%;
    width: max-content;
    background: #e0e0e0;
    padding: 2px 11px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #5c52c6;
  }

  &:hover::before,
  &:active::before,
  &:focus::before {
    content: '';
    top: -11px;
    position: absolute;
    left: 16%;
    width: 10px;
    height: 10px;
    background: #e0e0e0;
    padding: 2px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    transform: rotate(45deg);
  }
}

.ql-undo {
  @include full-content('Undo');
}

button[type='button'][value='bullet'].ql-list {
  @include full-content('Bullet List');
}

button[type='button'][value='ordered'].ql-list {
  @include full-content('Ordered List');
}

.ql-link {
  @include full-content('Link');
}

.ql-underline {
  @include full-content('Underline');
}

.ql-italic {
  @include full-content('Italic');
}

.ql-bold {
  @include full-content('Bold');
}

.ql-redo {
  @include full-content('Redo');
}

.popup-overlay.rich-text-popup-overlay {
  .grid .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none !important;
    color: black;

    &:hover {
      border-bottom: none !important;
    }
  }
}

.ql-container.ql-snow.ql-disabled {
  left: -15px;

  &.ql-editor {
    padding: 0;
  }
}

.ql-container {
  font-size: 14px !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-style: normal;
  color: #a6abb4;
}
