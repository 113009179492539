@import '/src/variables';

.profile-name {
  font-size: 14px;
  line-height: 16px;
  width: 6vw;
  color: $mui-profile-color;
  text-align: center;
}

.header-menu-logout {
  top: 0 !important;
}

.profile-icon {
  width: 30px;
  display: inline-block;
  margin-right: 8px;

  svg {
    width: 27px;
  }
}

.profile-default-icon {
  font-size: 30px;
  color: $mui-profile-icon;
}

.profile-dropdown {
  font-size: 20px;
  color: $mui-profile-icon;
}

.user-profile-img {
  border-radius: 100%;
}
