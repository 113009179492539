.popup-content.quick-add-contact-content {
  width: 60% !important;
  height: auto !important;
  max-width: 950px;
  max-height: 430px;

  .quick-add-contact {
    height: auto !important;
    overflow: auto;
    width: 100% !important;
    background-color: #fff;

    .quick-add-conact-item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;

      .quickaddlabel {
        font-size: 14px;
        margin-right: 50px;
        margin-bottom: 20px;
        text-transform: capitalize;
        color: #000 !important;
        border-radius: 0% !important;
      }

      .add-quick-input {
        width: 50%;
        max-width: 100%;
        max-height: 64px;
        margin-bottom: 20px !important;
        padding-right: 20px !important;
        margin-left: 20px !important;
        border-radius: 0%;
      }

      .add-quick-inputs {
        width: 50%;
        max-width: 100%;
        max-height: 64px;
        margin-bottom: 20px !important;
        padding-right: 20px !important;
        margin-left: 20px !important;
        border-radius: 0%;
        overflow-y: auto;
      }

      .job-function-input {
        overflow-y: auto !important;
      }

      .add-quick-inputs div {
        border-radius: 1 !important;
      }

      .add-quick-inputs div div input {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      .add-quick-input div {
        border-radius: 1 !important;
      }
    }
  }
}

.contact-input-lable .MuiInputLabel-root {
  color: #5c52c6;
}
