.bd-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.bd-row {
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 100%;
}

.bd-input-box,
.bd-dropdown-box {
  flex: 1;
}

.switch-box {
  display: flex;
  align-items: center;
}

.switch-box .MuiTypography-root {
  margin: 0;
  padding: 0;
}

.switch-box .MuiFormControlLabel-root {
  margin: 0;
}
