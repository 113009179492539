@import '/src/variables';

.react-tel-input .flag {
  height: 11px !important;
}

.section-content {
  .view-search {
    .react-tel-input {
      border: none !important;

      .selected-flag .flag {
        margin-top: -8px;
      }
    }
  }

  &.read-only * {
    color: gray !important;
    pointer-events: none;
  }
}

input[type='checkbox']:checked {
  accent-color: $palette-primary-main !important;
}

input.custom-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.table-content {
  color: #000;
}

.table-header {
  .contact-details-view-label {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    margin: 0;
  }

  .contact-view-label {
    font-size: 15px;
    line-height: 20px;
    color: white;
    margin: 0;
  }

  .contact-team-info {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }
}

.retainer-info {
  .retainer-value {
    font-size: 14px;
  }
}

.done-icon {
  margin-left: 5px;
  color: #de9f6d !important;
  cursor: pointer;
}

.edit-icon {
  margin-left: 5px;
  margin-bottom: 5px;
  color: #de9f6d !important;
  cursor: pointer;
}

.icon-button-exp-card {
  padding-left: 8px !important;
  padding-right: 8px !important;

  svg {
    width: 18px !important;
  }
}

.text {
  color: white;
}

.MuiSvgIcon-fontSizeLarge {
  height: 20px;
  width: 20px;
}

.client-info-table {
  background: #fff;
  box-shadow: 0 0 8px rgb(219 226 243 / 60%);
  overflow: hidden;

  .table-header {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    padding: 10px 20px;
    background: #2a364c;

    /*  &.read-only {
   // background: #ccc !important;
  } */
  }

  .table-content {
    padding: 30px 20px 10px;

    &.validation-error {
      .sum-value,
      & .Mui-disabled {
        color: red;
      }
    }
  }

  .table-main-header {
    padding: 10px 20px;
    background: #e9e9ea;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #374464;
  }

  .team-info-field {
    flex: 1 0;
    padding-right: 10px;
    padding-left: 10px;
    white-space: nowrap;
    text-align: center;
  }

  .name-field {
    flex: 1.8 0;
  }
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px;
}

.MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 8px 0;
}

.teamInfo .inputfieldinfo div {
  border: 1px solid black;
  border-radius: 4px;
}

.teamInfo .red div {
  border: 1px solid red;
  border-radius: 4px;
  width: 60%;
  padding-left: 8px;
  display: inline-block;
  margin: 0 auto;

  /* margin-left: 10px; */
  margin-right: 28px;
}

.button-text-capitalized {
  text-transform: capitalize !important;
}

.notes-content-view {
  width: 90%;
  font-size: 14px !important;

  .notes-details-item-view {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 50%;

    label {
      color: #000;
      width: 40%;
      font-size: 1rem;
    }

    .input-view-label {
      text-align: left;
      font-size: 14px !important;
    }

    .outlined-box {
      border: 1px solid #ccc;
      border-radius: 0% !important;
      overflow: hidden;
      width: 100% !important;
      color: black;

      &.rich-text-field {
        width: 75% !important;
      }
    }

    .notes-detail-value-view {
      font-size: 1rem;
      margin-bottom: 18px;
      width: 60%;
      margin-left: 55px;
    }

    .input-desc-label {
      text-align: left;
      font-size: 14px !important;
    }
  }
}

.add-search-input {
  width: 100%;
  max-height: 64px;
  margin-bottom: 10px !important;
  border-radius: 0%;
  max-width: auto;
}

.add-notes-input {
  width: 50%;
  max-width: 100%;
  max-height: 64px;
  margin-bottom: 20px !important;
  padding-right: 20px !important;
  border-radius: 0%;

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    padding: 0;
  }

  .MuiOutlinedInput-root {
    border-radius: 0%;
  }
}

.contact-view-value-billing {
  width: 75%;
  max-width: 100%;
  max-height: 64px;
  font-size: 16px;
  line-height: 19px;
  color: #373956;
  word-break: break-word;
  margin-left: 55px !important;
  overflow: auto;

  &.h-overflow {
    max-height: fit-content !important;
  }

  &.multiple-selection-list {
    max-height: 120px;
    overflow: auto;

    .input-form-field {
      overflow: auto;
    }
  }
}

.label-height {
  min-height: 53px;
}

.search-details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  color: black;
  text-transform: capitalize;

  .contact-view-label {
    font-size: 14px !important;
    line-height: 20px;
    color: #000;
    margin: 0;
  }

  .contact-view-labels {
    font-size: 15px !important;
    line-height: 20px;
    color: #000;
    margin: 0;
    margin-bottom: 20px;
  }

  .contact-view-value {
    width: 50%;
    max-width: 100%;
    max-height: 64px;
    font-size: 14px;
    line-height: 19px;
    color: #373956;
    word-break: break-word;
    margin-left: 55px !important;
    overflow: auto;

    &.h-overflow {
      max-height: fit-content !important;
    }

    &.multiple-selection-list {
      max-height: 120px;
      overflow: auto;

      .input-form-field {
        overflow: auto;
      }
    }
  }
}

.search-details-billing {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: black;

  .contact-view-label {
    font-size: 14px !important;
    line-height: 20px;
    color: #000;
    margin: 0;
  }

  .contact-view-value {
    width: 75%;
    max-width: 100%;
    max-height: 64px;
    font-size: 14px;
    line-height: 19px;
    color: #373956;
    word-break: break-word;
    margin-left: 55px !important;
    overflow: auto;

    &.h-overflow {
      max-height: fit-content !important;
    }

    &.multiple-selection-list {
      max-height: 120px;
      overflow: auto;

      .input-form-field {
        overflow: auto;
      }
    }
  }

  .contact-view-value-notes {
    width: 75%;
    max-width: 100%;
    max-height: 64px;
    font-size: 14px;
    line-height: 19px;
    color: #373956;
    word-break: break-word;
    overflow: auto;

    &.h-overflow {
      max-height: fit-content !important;
    }

    &.multiple-selection-list {
      max-height: 120px;
      overflow: auto;

      .input-form-field {
        overflow: auto;
      }
    }
  }
}

.billing-details {
  display: flex;
  flex: 1 0;
  flex-direction: row;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;

  .contact-view-label {
    font-size: 12px;
    line-height: 14px;
    color: rgb(92 82 198 / 80%);
    margin-bottom: 2px;
    text-align: end;
    width: 25%;
  }

  .contact-view-value {
    width: 75%;
    font-size: 14px;
    line-height: 19px;
    color: #373956;
    word-break: break-word;
    text-align: left;
    overflow: auto;

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
      padding: 0;
    }

    .MuiOutlinedInput-multiline {
      padding: 9px;
    }

    .MuiOutlinedInput-root {
      border-radius: 0%;

      .MuiOutlinedInput-input {
        padding: 9px;
      }
    }
  }
}

.invoice-grid {
  height: 40vh;
}

.billing-form {
  padding: 3px 40px 0 20px !important;
}

.submit-approval {
  background-color: green !important;
  padding: 0;
  color: white !important;
  text-transform: none !important;
}

.Icon-root {
  color: black !important;
}

.approve-container {
  width: 165px;
  justify-content: space-between;
}

.terms-width {
  width: 40% !important;
}

.react-tel-input .selected-flag {
  z-index: 13;
  position: relative;
  width: 38px;
  margin-left: 4px;
}

.phone_input {
  .react-tel-input .country-list {
    position: relative !important;
  }
}

.react-tel-input input[type='tel'],
.react-tel-input input[type='text'] {
  padding-left: 36px !important;
}
