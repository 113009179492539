@import '/src/variables';

.loading-screen {
  padding-top: 40px;

  &__loader {
    color: $light-bluish !important;
    margin-bottom: 24px;
  }
}
