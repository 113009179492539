@import '/src/variables';

.resume-button {
  label {
    cursor: pointer;
    margin-bottom: 0;
  }
}

#view-contact-header-container {
  .main_grid_row .main_grid_2 {
    max-width: 10.833333%;
    flex: 0 0 10.833333%;
  }

  .main_grid_row .main_grid_8 {
    flex: 0 0 70.833333%;
    max-width: 70.833333%;
  }

  .main_grid_row .main_grid_3 {
    max-width: 18.333333%;
    flex: 0 0 18.333333%;
  }

  .main_grid_box .col_2 {
    max-width: 29.166667%;
    flex: 0 0 29.166667%;
  }

  .main_grid_box .col_8 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .activity-bars-head {
    display: flex;
    align-items: center;
  }

  .headeropen {
    height: 180px;
    transition: height 0.3s ease;
  }

  .headerclosed {
    height: 70px;
    transition: height 0.3s ease;
  }

  .logan-activity-head {
    height: 160px;
  }

  .main-sub-content {
    width: 100vw;
  }

  .main-sub-content-closed {
    width: 100vw;

    // height: 70px;
  }

  .avatar-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .avatar-upload {
    position: relative;

    // max-width: 150px;
  }

  .edit-image {
    position: absolute;
    top: 0;
    right: 0;
  }

  .avatar-preview {
    position: relative;
    border-radius: $border-radius-full;
    background-color: $mojo;
    box-sizing: border-box;
  }

  .avatar-edit {
    position: absolute;
    z-index: 1;
    width: 94px;
    height: 94px;
    border-radius: 100%;
  }

  .avatar-edit input + label {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }

  .avatar-upload:hover .avatar-edit input + label {
    opacity: 1;
  }

  .avatar-upload:hover .avatar-preview {
    opacity: 0.6;
  }

  .border-red {
    border: 1px solid $palette-error-main;
  }

  .border-green {
    border: 1px solid $palette-primary-main;
  }

  .avatar-preview > div {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .disabled-link {
    fill: $gray;
    cursor: not-allowed;
  }

  .off-limit-text-container-collapse {
    min-width: 145px;
    height: 24px;
    padding: 2px 2px 2px 7px;
    border-radius: 72px;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
    margin-left: 8px;
  }

  .off-limit-text-container {
    min-width: 145px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 72px;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
  }

  .button-green {
    background-color: $palette-primary-main;
  }

  .button-red {
    background-color: $palette-error-main;
  }

  .offLimit-border-hard {
    background-color: #a33532;
  }

  .offLimit-border-soft {
    background-color: #dd7702;
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
  }

  .rectangle-green-collpse {
    width: 7.5vw;
    height: 4vh;
    border-radius: $border-radius;
    border: 1px solid $palette-primary-main;
    background-color: $persian-green;
    cursor: pointer;
    justify-content: center;
  }

  .rectangle-green {
    width: 7.5vw;
    height: 4vh;
    border-radius: $border-radius;
    border: 1px solid $palette-primary-main;
    background-color: $persian-green;
    cursor: pointer;
    justify-content: center;
  }

  .rectangle-orange {
    width: 7.5vw;
    height: 4vh;
    border-radius: 4px;
    border: 1px solid $orange-color;
    background-color: $light-orange;
    white-space: nowrap;
    cursor: pointer;
    justify-content: center;
  }

  .view-resume-icon {
    color: $palette-primary-main;
  }

  .view-resume-icon-orange {
    color: $orange-color;
  }

  .view-resume-text {
    text-align: center;
    color: $palette-primary-main;
  }

  .view-resume-text-orange {
    text-align: center;
    color: $orange-color;
  }

  .candidate-text {
    font-weight: bolder;
  }

  .edit-icon-header {
    fill: $dark-cerulean;
    cursor: pointer;
  }

  .delete-icons {
    color: $palette-error-main;
    cursor: pointer;
  }

  .linkedin-icon-header {
    fill: $light-bluish;
    cursor: pointer;
  }

  .add-to-button {
    background-color: $palette-primary-main;
    color: $white;
    text-transform: none;
  }

  .content-title {
    color: $cool-gray;
  }

  .rectangle-blue {
    border-radius: $border-radius;
    background-color: $palette-heading-main;
    box-sizing: border-box;
    color: $white;
  }

  .circle-icon {
    box-sizing: border-box;
    fill: $white;
  }

  .content-project {
    border-radius: $border-radius;
    border: 1px solid $periwinkle-gray;
    background-color: $transparent-bluish-gray;
    box-sizing: border-box;
  }

  .screened {
    border-radius: 39px;
    border: 1px solid $light-radish;
    background-color: $sepia-tone;
    color: $light-radish;
    text-align: left;
  }

  .project-name {
    text-decoration: underline;
    cursor: pointer;
  }

  .project-content {
    border-bottom: 1px solid $cornflower-blue;
    display: flex;
    align-items: center;
  }

  .view-more {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $palette-heading-main;
    padding-left: 6px;
    font-weight: 600;
  }

  .add-tags-btn {
    cursor: pointer;
    color: $cool-gray;
  }

  .header-text {
    font-weight: 800;
  }

  .resume-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .off-limit-text {
    color: $white;
    text-transform: none;
  }

  .type-icon {
    fill: $white;
  }

  .btn-Style {
    border: 1px solid $palette-primary-main;
    background-color: $light-aqua;
    color: $palette-primary-main;
    text-transform: none;
    min-width: auto;
    padding: 4px 6px;
  }

  .screen-icon {
    fill: $screen-icon-color;
  }

  .content-icon {
    fill: $cool-gray;
    margin-right: 8px;
  }

  .custom-input-field {
    width: 14vw;

    // height: 1vh;
    .MuiInputBase-input {
      height: 1vh;
    }
  }

  .content-space {
    gap: 4px;
  }
}

.icon-open {
  transform: rotate(360deg);
}

.icon-closed {
  transform: rotate(180deg);
}

.contact-details-value {
  border: 1px solid transparent;
}

.contact-details-value:hover {
  // border: 1px solid $palette-bg-main;
}

.toggle-header {
  position: relative;

  .header-arrow-icon {
    color: $white;
    transition: transform 0.4s ease-in-out;
    position: absolute;
    border-radius: 50%;
    background-color: $palette-primary-main;
    left: 50%;
    bottom: 6px;
  }
}

.toggle-log-an-activity {
  position: absolute;
  top: 50%;

  .log-an-activity-arrow-icon {
    rotate: 90deg;
    color: $white;
    transition: transform 0.4s ease-in-out;
    position: absolute;
    border-radius: 50%;
    background-color: $palette-primary-main;
  }
}

.first-grid {
  min-width: 100%;
}

.view-reason-txt {
  cursor: pointer;
  color: $palette-error-main !important;
  margin-top: 7px !important;
}

#resume-dialog {
  .MuiDialog-paper {

    /* background-color: transparent; */
    box-shadow: none;
    height: 93vh;
    width: 42vw;
    max-width: none;
  }
}

#pdf-controls {
  justify-content: center;
}

@media (width > 767px) and (width < 1024px) {
  #view-contact-header-container {
    .rectangle-green {
      width: 12vw;
    }

    .rectangle-green-collpse {
      width: 8vw;
    }

    // .image-size {
    //   height: 90px;
    //   width: 90px;
    // }

    // .log-an-activity-profile-image {
    //   height: 70px;
    //   width: 70px;
    // }
  }
}

@media (width > 1081px) and (width <=1455px) {
  #view-contact-header-container {
    .rectangle-green {
      width: 7vw;
    }

    .rectangle-green-collpse {
      width: 6vw;
    }

    // .image-size {
    //   height: 100px;
    //   width: 100px;
    // }

    // .log-an-activity-profile-image {
    //   height: 95px;
    //   width: 95px;
    // }
  }
}

@media (width > 1439px) and (width < 1646px) {
  #view-contact-header-container {
    // .log-an-activity-profile-image {
    //   height: 125px;
    //   width: 125px;
    // }
  }
}

@media (width > 1023px) and (width <=1081px) {
  #view-contact-header-container {
    .rectangle-green {
      width: 8vw;
    }

    .rectangle-green-collpse {
      width: 7vw;
    }

    // .image-size {
    //   height: 100px;
    //   width: 100px;
    // }

    // .log-an-activity-profile-image {
    //   height: 95px;
    //   width: 95px;
    // }
  }
}

@media (width >= 768px) and (width <= 900px) {
  #view-contact-header-container {
    .headeropen {
      height: 300px;
    }
  }
}

@media (height <= 648px) {
  #view-contact-header-container {
    .off-limit-text-container-collapse {
      height: 34px;
    }

    .rectangle-orange {
      height: 34px;
    }
  }
}

@media (height <= 320px) {
  #view-contact-header-container {
    .off-limit-text-container-collapse {
      height: 34px;
    }

    .rectangle-orange {
      height: 34px;
    }
  }
}

.off-limit-indicator {
  right: -70%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: default;
}

.offlimit-caution.hard {
  fill: #a33532;
}

.offlimit-caution.soft {
  fill: #dd7702;
}

.enrich-contact-button {
  border: 1px solid $palette-primary-main;
  background-color: transparent;
  color: $palette-primary-main;
  text-transform: none;
  min-width: auto;
  margin-top: 5px;
  padding: 4px 6px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.enrich-contact-button:hover {
  background-color: $palette-primary-main;
  color: $white;
  cursor: pointer;
}

.project-Avatar-contact {
  width: 94px;
  height: 94px;
}

.icon-font-size-candidate {
  svg {
    fill: inherit !important;
    font-size: 20px;
    margin-right: 10px;
  }
}

#imagePreview {
  .image-size {
    width: 94px;
    height: 94px;
  }
}

.padding-left-30 {
  padding-left: 45px !important;
}

.isOffLimitApplied {
  position: absolute;
  top: 79px;
  left: -79px;
  width: 180px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
}

.file-upload-buttons {
  label {
    margin: 0;
  }
}

.hide-1550 {
  @media screen and (width <= 1550px) {
    display: none !important;
  }
}

.hide-1450 {
  @media screen and (width <= 1450px) {
    display: none !important;
  }
}

.hide-1200 {
  @media screen and (width <= 1200px) {
    display: none !important;
  }
}

.hide-992 {
  @media screen and (width <= 992px) {
    display: none !important;
  }
}

.hide-768 {
  @media screen and (width <= 768px) {
    display: none !important;
  }
}

.margin-right-left {
  margin: 0 50px 0 100px !important;
}

.banner-primary-color-new {
  font-size: 16px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  padding: 0 14px;

  svg {
    margin-right: 10px;
  }
}

.test-ellipsis-small-2,
.test-ellipsis {
  width: calc(100% - 40px);
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: pre;
  overflow: hidden;
  display: inline-block;
}

.test-ellipsis-small-2 {
  max-width: 200px;

  @media screen and (width <= 768px) {
    display: none;
  }
}

.test-ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
