#profile-label-comp {
  .avatar-style {
    max-width: 30px;
    max-height: 30px;
  }

  .profile-label {
    width: 100%;
    height: 32px;
    background-color: rgba(149, 159, 183, 0.05);
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
  }

  .profile-label-text {
    font-weight: lighter;
    color: rgb(0 0 0 / 49%);
    display: flex;
    align-items: center;
  }
}
