@import '/src/variables';

.log_an_activity {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  width: 100%;

  // padding: 8px 0;
  ::-webkit-scrollbar {
    appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(0 0 0 / 50%);
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }
}

.log_an_activity_inside {
  flex: 1; /* Use flex to fill remaining space */
  overflow-y: auto; /* Use auto to enable vertical scrolling if needed */
  // scrollbar-width: thin;
}

.details_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
