.rs-btn {
  font-weight: 700 !important;
}

.rs-input::placeholder {
  font-size: 0.8rem;
  color: #333;
}

.rs-input-group.rs-input-group-inside {
  border: 1px solid darkgray;
}

.rs-input-group.rs-input-group-inside:hover {
  border-color: black;
}
