@import '/src/variables';

.custom-header {
  color: $white;
  font-size: smaller;
  font-weight: 800;
  text-align: center;
}

.action-icon {
  color: $white;
  cursor: pointer;
}

// .ag-theme-alpine .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left, .ag-cell-range-single-cell) {
//   border: none;
// }

// .ag-theme-alpine .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right, .ag-cell-range-single-cell) {
//   border: none;
// }

// .ag-theme-alpine .ag-pinned-left-header,
// .ag-theme-alpine .ag-pinned-right-header,
// .ag-theme-alpine .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right, .ag-cell-range-single-cell),
// .ag-theme-alpine .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left, .ag-cell-range-single-cell) {
//   border: none;
// }

// .ag-theme-alpine .ag-header-cell-resize::after {
//   background-color: $white;
//   width: 1px;
// }

.custom-checkbox {
  box-sizing: border-box;
  padding: 0;
  color: $white !important;
}

.css-1c6zgjm-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1c6zgjm-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: $white;
}

.primary-checkbox {
  color: gray;
}

.primary-checkbox.Mui-checked {
  color: $palette-primary;
}

.primary-checkbox.Mui-checked + .MuiTypography-root {
  font-weight: bold;
}

.columns-icon {
  right: 10px;
}

.grid-menu-custom-action {
  max-width: 300px;
  width: 100%;
  position: relative;
  top: 8px;
  padding: 0 0 0 30px;
}

.global-search-companies {
  height: calc(100vh - 200px);
}

.fit-score-container {
  // background: #f00;
  & > :first-child {
    overflow: unset;

    .fit-score-sub-container {
      & > :first-child,
      & > :last-child {
        overflow: unset;

        & > :first-child {
          overflow: unset;
          height: 100%;

          .fitscore-scroll-container {
            overflow: scroll;
            height: 100%;
            background: #e9e9ed;
            width: 100%;
            padding-bottom: 50px;

            .fit-score-item {
              background: #fff;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}
