@import '/src/variables';

.transcribe-popup-content {
  z-index: inherit !important;
  height: 80vh !important;
  width: 60vw !important;
  padding: 1em !important;
  background: #f4f4f4 !important;
  border-radius: 5px;
  overflow: auto;
}
