.list-work-benches,
.all-candidates {
  .count-container {
    display: flex;
    padding: 0 30px;
    justify-content: flex-end;

    .action-container {
      min-width: 2%;
    }
  }
}

.text-link {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
  }
}
