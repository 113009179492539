.profile-action-section {
  padding: 20px;
  background: #fafafa;
  border-bottom: 1px solid #dddde2;
}

.action-container {
  margin-right: 20px;
  cursor: pointer;

  .action-icon {
    color: #df7928;
    font-size: 15px;
    margin-right: 5px;
  }

  .action-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #5c52c6;
  }
}

.editable {
  color: #de9f6d !important;
  margin-bottom: 4px;
}
