@import '/src/variables';

.workbench-search {
  padding: 2px;
  border-radius: 4px;
  background-color: rgb(149 159 183 / 9%);
  height: 50px;
  font-size: 14px !important;

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiAutocomplete-hasClearIcon.MuiAutocomplete-inputRoot {
    padding-right: 0;
  }

  .MuiInputBase-input .MuiInput-input {
    color: $black;
  }
}

.list-info,
.query-info {
  padding: 16px;
  width: 100%;
  border-radius: 12px !important;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);

  .list-details,
  .query-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 0.875rem;
    margin-bottom: 8px;

    strong {
      font-weight: 600;
    }
  }

  .list-description,
  .query-description {
    margin-top: 8px;
    font-size: 0.875rem;

    .editable-description {
      border: none;
      outline: none;
      background: transparent;
      font-size: 0.875rem;
      width: 100%;
    }

    .icon {
      font-size: 1rem;
    }
  }
}

.query-accordion-container {
  width: 100%;
  border-radius: 12px !important;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  margin-top: 10px;
}

.query-accordion-container::before {
  display: none;
}

.query-accordion-container.MuiAccordion-root {
  overflow: hidden;
}

.query-accordion-summary {
  background-color: var(--background-white);
  border-radius: 12px;
  height: 60px;
}

.query-accordion-summary.Mui-expanded {
  border-radius: 12px 12px 0 0;
}

.query-accordion-summary .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}
