@import '/src/variables';

.paper-custom-info {
  width: 350px;

  .abolute-position {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: $frosted-white;
  }
}

#custom-popup {
  .show-off-limits-title {
    font-size: 14px;
  }

  .flex-1 {
    flex: 1;
  }

  .gap-5 {
    gap: 5px;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
  }

  .gap-10 {
    gap: 7px;
  }

  .show-off-limits-header {
    padding: 9px 12px;
  }

  .custom-padding {
    padding: 0 16px 0 5px;
  }

  .off-limit-entry {
    padding: 0;
    padding: 5px;
    margin-bottom: 5px;

    .edit-offlimit-icon {
      color: $palette-primary-main;
      font-size: 12px;
      cursor: pointer;
      margin-left: 3px;
    }

    > h6,
    .content-container .title {
      font-size: 12px;
      font-weight: 700;
      align-items: center;
    }

    .content-container {
      display: flex;

      .text-container {
        display: flex;
      }

      .value {
        font-size: 10px;
      }

      .icon-container {
        width: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }

  #direct-offlimits.off-limit-entry {
    .not-found {
      align-items: center;
      justify-content: center;

      > span {
        font-size: 14px;
        margin: 0;
      }

      > svg {
        font-size: 16px !important;
      }
    }
  }

  .soft.off-limit-entry {
    border: 2px solid $vivid-orange;
    background-color: $light-cream;

    h6,
    .cuation-icon,
    .content-container .title {
      color: $vivid-orange;
    }
  }

  .hard.off-limit-entry {
    border: 2px solid $deep-crimson;
    background-color: $soft-rose;

    h6,
    .cuation-icon,
    .content-container .title {
      color: $deep-crimson;
    }
  }
}
