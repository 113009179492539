.custom-dropdown-list {
  cursor: pointer;
}

.drawer-open-content {
  font-size: 12px;
}

.drawer-content {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.MuiFormLabel-root.Mui-focused {
  color: #777 !important;
  font-size: 18px;
}

.blue_label .MuiFormLabel-root {
  color: #003c5b !important;
}
