.loader-container {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1500;
  align-items: center;
  justify-content: center;
  background: rgb(252 252 252 / 72%);
}

.hl-loader {
  top: 0%;
}
