@import '/src/variables';

#create-contact-from-resume {
  max-width: 33vw;
  min-width: 30vw;

  .search-bar {
    background-color: $white !important;
    border: 1px solid $cool-gray;
    border-radius: 4px;

    .MuiAutocomplete-root {
      width: 30vw;
      display: flex;
      align-items: center;
    }
  }

  .head-content {
    display: flex;
    gap: 16px;
  }

  .head-content > .MuiFormControl-root > .MuiInputBase-root {
    padding-right: 0 !important;
  }

  .label-text {
    color: $palette-heading-main;
  }

  .input-data {
    width: 16vw;
    position: relative;
  }
}

.expand-collapse-button {
  color: $palette-heading-main;
}

.match-found-text {
  color: $brick-orange;
  font-weight: 600;
}

#resume {
  .font-size {
    word-break: break-all;
    color: $palette-primary-main;
  }

  label {
    margin-bottom: 0;
  }

  .resumeParent {
    border-radius: 4px;
    height: 39px;
    width: 125px;
    border: 1px solid $palette-primary-main;
    display: flex;
    background-color: $palette-primary-main;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    .label-text {
      word-break: break-all;
      color: $white;
    }
  }
}

@media screen and (width <= 786px) {
  #resume .resumeParent {
    height: 34px;
    width: 100px;
  }
}

@media screen and (width <= 1024px) and (width >= 1024px) {
  #resume .resumeParent {
    height: 36px;
    width: 100px;
  }
}

.selected-resume {
  background-color: $palette-primary-main;
  color: $white;
}

.bg-custom-grey {
  background-color: $cool-gray !important;
}

.font-color-red {
  color: $error-color;
}
