@import '/src/variables';

.gray {
  color: $switch-dark-gray;
}

.gray_bg {
  background-color: $switch-dark-gray;
}

.blue {
  color: $dark-cerulean;
}

.linkedin_disabled {
  fill: $gray;
  cursor: not-allowed;
}

.red {
  color: $reddish-orange;
}

.offlimit_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $reddish-orange;
}

.green {
  color: $mint-green;
}

.base_popup {
  background-color: $white;
  width: 150px;
  padding: 0 4px;
}

.green_bg {
  background-color: $strong-cyan;
  color: $white;
}

.green_bg2 {
  background-color: $greenish-blue;
  color: $white;
}

.red_bg {
  background-color: $reddish-orange;
  color: $white;
}

.yellow {
  color: $orange-color;
}

.white_bg {
  background-color: $white;
}

.border_black {
  border: 1px solid $switch-dark-gray;
  border-bottom: none;
  border-radius: 8px;
  padding: 0 8px;
}

.body {
  height: 50vh;
  width: 100%;
}

.btn_prop {
  text-transform: none;
  text-decoration: underline;
}

.scoresLegend {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px;
}

.grid {
  display: grid;
  grid-template-rows: 38px calc(100% - 91px) 27px;
  gap: 8px;
  align-items: center;
}

.gridItem {
  padding: 10px;
  border-radius: 4px;
}

.categoryName {
  font-weight: bold;
}

.categoryDescription {
  margin: 10px 0;
}

.scoreIndicators {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.greenDot {
  background-color: $mint-green;
  color: $mint-green;
}

.yellowDot {
  background-color: $orange-color;
  color: $orange-color;
}

.stack_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.popup_body {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.w_80 {
  width: 80%;
}

.w_20 {
  width: 20%;
}

.popup_bg {
  background-color: $gray-lighter;
  border-radius: 12px;
  height: fit-content;
  padding: 12px;
  gap: 4px;
}

.close_option {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  color: $switch-dark-gray;
}

.btn_drop {
  height: 28px;
  border-radius: 0%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn_drop_body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}

.btn_reject {
  color: $black;
  cursor: pointer;
  width: 100%;
  text-transform: capitalize;
}

.box {
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  translate: -40% 4px;
}

.btn_drop_icon {
  border-radius: 0%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0;
  height: 28px;
  width: 20px;
  min-width: 0 !important;
}

.product_one_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table_body {
  height: 50vh;
  width: 100%;
}

.filter_popup {
  width: 100%;
  max-width: 1000px;
}

.extButton {
  height: 42px;
}

.showScore {
  padding: 2px 0 0 6px;
  margin: 0;
  height: 42px;
}

.customPositionPaper {
  margin-left: 35px;

  .btn_prop {
    justify-content: flex-start;
    text-decoration: none;
  }
}

.actionDropdownContainer {
  width: 200px;
}

.loadLoaderSkeleton {
  width: 100%;
  height: 42px;
  background: #eee;
  border-radius: 4px;
  margin: 5px 0;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
