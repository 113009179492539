@import '/src/variables';

#invoice-summary-grid {
  .ag-theme-alpine {
    padding: 0 !important;

    .ag-header {
      border: 1px solid $white;
      border-top: none;
      background-color: $white;
      color: $black;

      .ag-header-cell-text {
        color: $black;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
      }

      .ag-header-cell-label {
        justify-content: center;
      }
    }
  }
}

.team-information-table {
  padding: 20px;
  border: none !important;
}

.search-approval-container {
  .search-header {
    height: 100px;
  }
}

.query-label {
  text-transform: uppercase;
}

.query-input-invoice {
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }
}

.query-height {
  height: auto;
}

.active-button {
  background-color: $palette-primary !important;
  color: $white !important;
}

.header-style {
  padding: 20px 0 0;
  text-transform: capitalize;
}

.row-height {
  height: 60px;
}

.search-header {
  background-color: $white;
}

.search-header .job-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 19px;
  margin-left: 0;
}

.query-toolbar {
  border-bottom: 1px solid $white;
  box-shadow: 2px 1px 7px $light-aqua;
  padding: 23px 0 23px 21px !important;

  .accordion {
    background-color: $palette-primary-main;
  }

  .title-text {
    color: $white;
  }
}

.query {
  .interval-field {
    .MuiFormControl-root {
      max-width: 382px;
    }
  }
}
