.team-information-table {
  background: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 60%); // did the billing/invoices section of bd
  border: 3px solid #2a364c;
  overflow: hidden;
  border-radius: 4px;
  text-align: center !important;
  white-space: nowrap;
  padding: 0;

  &.revenue-table {
    text-align: center !important;
    font-size: 14px !important;
  }

  .table-main-header {
    padding: 10px 20px;
    background: #e9e9ea;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #374464;
    text-align: center;
  }

  .table-header {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    padding: 10px 20px;
    background: #2a364c;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    /* &.read-only {
      // background: #ccc !important;
    } */
  }

  .table-content {
    padding: 30px 20px 10px;

    &.validation-error {
      .sum-value,
      & .Mui-disabled {
        color: red;
      }
    }
  }

  .team-info-row {
    margin-bottom: 25px;
  }

  .team-info-field {
    flex: 1 0;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
  }

  .name-field {
    flex: 1.8 0;
  }

  .team-profile-image {
    width: 20px;
    height: 20px;
    min-width: 20px;

    .default-profile-icon {
      font-size: 18px;
    }
  }
}

.teamInfo .inputfieldinfo div {
  border: 1px solid black;
  border-radius: 4px;
  padding-left: 10px;
}

.teamInfo .disabled-input-field-info div {
  border: 1px solid rgb(0 0 0 / 24%);
  border-radius: 4px;
  padding-left: 10px;
}

.teamInfo .red div {
  border: 1px solid red;
  border-radius: 4px;
  width: 60%;
  padding-left: 8px;
  display: inline-block;
  margin: 0 auto;

  /* margin-left: 10px; */
  margin-right: 28px;
}

.teamInfo .disabled-red div {
  border: 1px solid rgb(255 0 0 / 24%);
  border-radius: 4px;
  width: 60%;
  padding-left: 8px;
  display: inline-block;
  margin: 0 auto;

  /* margin-left: 10px; */
  margin-right: 28px;
}

.content {
  color: gray !important;
}
