#basic-details-view {
  .page-label {
    color: black;
    text-transform: capitalize;
    font-size: 16px;
    word-break: break-word;
  }
  .multiple-view-selection-list {
    display: block;
    overflow: auto;
  }
}
