@import '/src/variables';

#profileDetails {
  width: 100%;
  max-width: 100%;

  .text-label {
    color: $palette-heading-main;
    font-weight: 600;
  }

  .highLightsFont {
    color: $palette-heading-main;
  }

  .section-content-data {
    background-color: $gray-lighter;
    border-radius: 8px;
  }

  .gap-10 {
    gap: 15px;
    background: $white;
    width: fit-content;
  }

  .basic-info-section {
    background-color: $gray-lighter;
  }

  .section-content-data-external {
    background-color: $gray-lighter;
    border-radius: 8px;
  }

  .professional-section-content-data {
    background-color: $gray-lighter;
    border-radius: 8px;
  }

  .label-text {
    color: $palette-heading-main;
  }
  .communication-section {
    border-radius: 8px;
    display: flex;
    background-color: $grayish-blue;
    flex-flow: column wrap;
    gap: 32px;
  }

  .head-container {
    .sub-head-content {
      gap: 15px;
      flex-flow: row wrap;
    }
  }
  .remove-another-button {
    cursor: pointer;
    align-self: center;
    align-items: center;
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }
  .resume {
    flex: 1;
    min-height: 50px;
  }

  .input-field-data {
    width: 300px;
    min-width: 300px;
    position: relative;
  }

  .input-field-data-add {
    width: 100%;
  }

  @media only screen and (width >=768px) {
    .input-field-data-large {
      width: 300px;
    }
  }

  @media only screen and (width >=992px) {
    .input-field-data-large {
      width: 632px;
    }
  }

  @media only screen and (width >=1229px) {
    .input-field-data-large {
      width: 964px;
    }
  }

  .justify-center {
    justify-content: center;
  }

  .set-position {
    margin-top: -10px;
  }

  .align-center {
    align-items: center;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .fetch-data {
    width: 96px;
    height: 39px;
    padding: 2px;
    border-radius: 4;
    background-color: $palette-primary-main;
    color: $white;
    text-transform: capitalize;
    align-items: center;
    border: 0;
  }

  .animate-fetch-data {
    position: absolute;
    opacity: 0;
    transition:
      opacity 0.4s,
      transform 0.4s ease;
    transform: scaleX(0);
    transform-origin: right;
  }

  .animate-fetch-data.visible {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: right;
    transition:
      opacity 0.4s,
      transform 0.4s ease;
  }

  .divider {
    width: 10px;
    min-height: 10px;
  }

  .my-cards {
    flex-direction: column;
  }

  .or-size {
    height: 0;
  }

  .address-divider {
    border: 1px solid $divider-color;
  }

  textarea {
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .white-color {
    color: $white;
  }

  .form-section {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .flip {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform: rotateX(180deg);
  }

  .flip2 {
    transform-style: preserve-3d;
    transform: rotateX(180deg);
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .linkedin {
    align-items: end;
    justify-content: space-between;
    flex: 2;
  }

  .avatar-upload {
    position: relative;
    max-width: 150px;
  }

  .avatar-edit {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  .avatar-edit input {
    display: none;
  }

  .avatar-edit input + label {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }

  .avatar-upload:hover .avatar-edit input + label {
    opacity: 1;
  }

  .avatar-upload:hover .avatar-preview {
    opacity: 0.6;
  }

  .avatar-preview {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 100%;
  }

  .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .avatarStyle {
    height: 100px;
    width: 100px;
  }

  .editIcon {
    color: $palette-heading-main;
  }

  .close-icon {
    color: $palette-error-main;
  }
}

.content-gap {
  row-gap: 32px;
  column-gap: 32px;
}
