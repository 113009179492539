@import '/src/variables';

#log-an-activity-container {
  z-index: 10;

  .MuiTab-root.Mui-selected {
    color: $palette-heading-main;
    font-weight: bold;
  }

  .MuiTabs-indicator {
    background-color: $palette-heading-main;
  }

  .activity-container {
    overflow: hidden;
    box-shadow: none;

    .side-nav-header {
      border-bottom: 1px solid $light-black;
      color: $palette-heading-main;
    }

    .note-label {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .export-tab {
      font-weight: bold;
      color: $cool-gray;
    }
  }

  .autocomplete-inside {
    border-radius: 4px;
    text-align: left;
    width: 100%;
    min-width: auto;
    height: 3vh;
  }

  .autocomplete-outside {
    border-radius: 4px;
    text-align: left;
    width: 26vw;
    min-width: auto;
    height: 3vh;
  }
}

#activity-tab {
  .MuiListItem-root:hover {
    background-color: transparent;
  }

  .text-color {
    color: $palette-heading-main;
  }

  .activity-tab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  label {
    margin-bottom: 0;
    outline: none;
  }

  .close-icon-style {
    color: $palette-error-main;
  }

  .notes-header {
    display: flex;
    align-items: center;
  }
}

.activity-footer {
  display: flex;
  justify-content: space-between;
}

.file-uploader {
  width: 18vw;
}

.activity-editor .date-time-picker {
  margin-bottom: 10px;
}
