@import '/src/variables';

#setup-page {
  .setup-page__card {
    background-color: $white;
    padding: 1rem 3rem;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 14px 11px 6px -7px rgb(0 0 0 / 10%);
  }
}

#tag-setup-container {
  &.list-tag {
    height: 75vh;

    /* .range-label {
      margin-top: 3px;
      margin-bottom: 0;
    } */
  }
}

#add-tag-button {
  background-color: $palette-primary-main;
  padding: 4px 8px;
  color: $white;
  border-radius: 5px;
}

#view-tag-drawer {
  .tag-section {
    height: 100vh;
  }

  .header-section {
    flex: 15%;
    gap: 40px;
  }

  .body-section {
    flex: 85%;
  }

  .view-body-section {
    gap: 30px;
  }

  .tag-status {
    color: $palette-primary-main;
    border-bottom: 3px solid $palette-primary-main;
    display: inline-block;
    cursor: pointer;
  }

  .edit-icon {
    max-width: 20px;
    max-height: 20px;

    img {
      height: 14px;
      color: $white;
    }
  }

  .green-bg {
    background-color: $palette-primary-main !important;
    border-radius: $border-radius !important;
    color: $white !important;
  }

  .tag-text {
    font-weight: 800;
    color: $black;
  }

  .edit-gap {
    gap: 22px;
  }

  .input-field-data {
    min-width: 300px;
  }

  .edit-section-gap {
    gap: 32px;
  }

  #select-menu {
    .MuiOutlinedInput-input {
      padding: 11.5px 14px;
    }
  }

  #tag-input {
    .MuiOutlinedInput-input {
      padding: 12.5px 14px;
    }
  }

  #module-input {
    .css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
      padding-top: 12px;
      padding-bottom: 6px;
      padding-left: 6px;
    }
  }
}

.color-label {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#tag-confirmation {
  .dialog-content {
    font-weight: lighter;
    color: $palette-heading-main;
  }
}

/* Ensure the filter menu button is visible */
.ag-theme-alpine-1 .ag-header-cell-menu-button {
  opacity: 1 !important; /* Ensure the button is visible */
  transition:
    opacity 0.2s ease 0s,
    border 0.2s ease 0s; /* Smooth transition */
}

/* Change the color of the menu icon */
.ag-theme-alpine-1 .ag-header-cell-menu-button .ag-icon-menu {
  color: white; /* Change this to your desired color */
  opacity: 1 !important; /* Ensure the icon is visible */
}

/* Optionally, add hover effect */
.ag-theme-alpine-1 .ag-header-cell:hover .ag-header-cell-menu-button {
  opacity: 1 !important; /* Ensure the button is visible on hover */
}

#configuration-matrix-container {
  background-color: #fff;
  margin-top: 64px;

  .back-btn {
    color: $palette-primary-main;
  }

  #configuration-matrix-box {
    max-width: 70%;
    background-color: #e4f1fa;
    padding: 16px;

    .configuration-title {
      font-size: 13px;
      width: 100%;
      text-align: center;
      color: #195373;
      letter-spacing: 0;
      margin-bottom: 17px;
      font-weight: 700;
    }

    #configuration-matrix {
      background-color: $pale-sky-blue;
      margin: 0;
      width: unset;

      .congiguration-switch {
        .MuiSwitch-track {
          background-color: $light-steel-blue;
        }

        .Mui-checked + .MuiSwitch-track {
          background-color: $dark-slate-blue;
        }
      }

      .table-head .MuiTableCell-head {
        background-color: $pale-sky-blue;
        font-size: 14px;
        font-weight: 700;
      }

      .table-body > th {
        font-size: 11px;
      }

      .tick-icon {
        background-color: $indigo;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: $white;
          font-size: 10px;
        }
      }

      .minus-icon {
        background-color: $cool-gray;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: $white;
          font-size: 10px;
        }
      }
    }
  }
}
