@import '/src/variables';

.SwitchStyle {
  .MuiSwitch-switchBase {
    &.Mui-checked {
      color: $white;

      & .MuiSwitch-thumb::before {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23fff" class="bi bi-check2" viewBox="0 0 16 16"> <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/> </svg>');
      }

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: $switch-light-mint;
      }
    }
  }

  // for disable ripple effect
  .MuiSwitch-switchBase:hover {
    background-color: transparent;
  }

  .Mui-checked .MuiSwitch-thumb {
    background-color: $switch-back-mint;
  }

  & .MuiSwitch-thumb {
    background-color: $switch-dark-gray;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23fff" class="bi bi-dash" viewBox="0 0 16 16"> <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/> </svg>');
    }
  }

  & .MuiSwitch-track {
    background-color: $switch-back-gray;
  }
}
