@import '/src/variables';

.custom-switch {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 35px; /* Width of the switch */
  height: 20px; /* Height of the switch */
  position: relative;
  padding-left: 0 !important;

  .switch-track {
    background-color: $cool-gray; /* Track color */
    border-radius: 10px; /* Rounded corners */
    width: 26px;
    height: 4px; /* Track height */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .switch-thumb {
    background-color: $cool-gray; /* Thumb color */
    border-radius: 50%; /* Round thumb */
    width: 14px; /* Thumb width */
    height: 14px; /* Thumb height */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    transition: transform 0.2s; /* Smooth transition */
  }

  &.checked {
    .switch-thumb {
      transform: translate(12px, -50%); /* Move thumb to the right when checked */
      background-color: $palette-secondary-main; /* Change thumb color when checked */
    }

    .switch-track {
      background-color: $palette-secondary-main; /* Change track color when checked */
    }
  }
}
