@import '/src/variables';

.background-white {
  background-color: $white;
}

.custom-color-white {
  color: $white !important;
  background-color: var(--palette-primary-opacity-50) !important;
}

// .custom-color-white :hover {
//   color: $black !important;
// }

.custom-vh-80 {
  min-height: 88vh;
}

.background-color-header {
  background-color: $palette-primary-main;
  color: $white;
}

.background-color-selected {
  background-color: rgba($light-grey, 50%) !important;

  // color: $white;
}

.remove-workbench-button {
  background-color: $error-color;
  color: $white;
}

.remove-query-button {
  background-color: $error-color;
  color: $white;
}

.remove-workbench-button:hover {
  background-color: $error-color;
  color: $white;
}

.remove-query-button:hover {
  background-color: $error-color;
  color: $white;
}

.workbench-table {
  height: 70vh;
}

.custom-tertiary-query {
  background-color: $palette-primary-main;
  color: $white;
}

.custom-tertiary-query:hover {
  background-color: $white;
  color: $palette-primary-main;
}
