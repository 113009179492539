@import '/src/variables';

#compensation-container {
  .drawer-tab-layout {
    height: 52vh;
  }

  .MuiPaper-root {
    &.paper-container {
      background-color: $gray-lighter;
      border-radius: 8px;
    }
  }

  .compensation-text {
    color: $palette-heading-main;
    font-weight: 600;
  }

  .compensation-content {
    color: $indigo;
  }

  .compensation-header {
    display: flex;
    justify-content: space-between;
  }

  .compensation-autocomplete {
    .MuiAutocomplete-root {
      width: 100%;
      max-width: 100px;
    }
  }

  .compensation-autocomplete-code {
    .MuiAutocomplete-root {
      min-width: 150px;
      width: 100%;
    }
  }

  .compensation-input {
    .compensation-field {
      width: 100%;
      min-width: 300px;
      height: 40px;
    }
  }

  .make-compulsory {
    display: flex;
  }

  .currency-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .input-field {
      .MuiAutocomplete-input {
        background-color: transparent;
        border: none;
      }
    }
  }

  .table-close-icon {
    color: $palette-error-main;
    cursor: pointer;
  }

  .compensation-check-typo {
    display: flex;
    align-items: center;
    color: $palette-heading-main;
    font-size: 8px;
  }

  #compensation-lti {
    .compensation-field {
      width: 100%;
      max-width: 100px;
    }
  }

  .bonus-type {
    .MuiAutocomplete-root {
      width: 300px;
    }
  }

  .compensation-table {
    width: 60vw;
  }

  .text-area {
    width: 49vw;
  }

  .remove-another-button {
    cursor: pointer;
    width: 24px;
  }

  @media (width >=768px) and (width <=1023px) {
    .bonus-type {
      .MuiAutocomplete-root {
        min-width: 150px !important;
      }
    }

    .compensation-input {
      .compensation-field {
        min-width: 100px;
      }
    }

    .compensation-autocomplete {
      .MuiAutocomplete-root {
        max-width: 100px;
      }
    }

    .compensation-autocomplete-code {
      .MuiAutocomplete-root {
        min-width: 100px;
      }
    }

    #compensation-lti {
      .compensation-field {
        max-width: 100px;
      }
    }
  }

  @media (width >=1024px) {
    .bonus-type {
      .MuiAutocomplete-root {
        min-width: 200px;
      }
    }

    .compensation-autocomplete {
      .MuiAutocomplete-root {
        max-width: 150px;
      }
    }

    .compensation-input {
      .compensation-field {
        min-width: 200px;
        height: 40px;
      }
    }

    .compensation-autocomplete-code {
      .MuiAutocomplete-root {
        min-width: 150px;
      }
    }
  }

  @media (width >=1440px) {
    .compensation-autocomplete {
      .MuiAutocomplete-root {
        min-width: 158px;
      }
    }

    .compensation-input {
      .compensation-field {
        min-width: 300px;
        height: 40px;
      }
    }

    .compensation-autocomplete-code {
      .MuiAutocomplete-root {
        width: 158px;
      }
    }

    #compensation-lti {
      .compensation-field {
        max-width: 158px;
      }
    }
  }
}
