.layout-header {
  padding: 10px 30px;
  border-bottom: 1px solid #dddde2;
}

.layout-side-nav-items {
  display: none;
  overflow: auto;

  .MuiTabs-vertical {
    width: 100%;
    overflow-y: auto;
  }
}

.layout-side-nav {
  width: 40px;
  min-width: 40px;
  transition: width 0.3s linear;
  background: #f4f4f5;
  border-right: 1px solid #dddde9;
  overflow: auto;

  .MuiTabs-scroller {
    white-space: normal;
    overflow-y: auto;
  }

  &.open {
    width: 237px;
    min-width: 237px;

    .layout-side-nav-items {
      display: flex;
    }
  }
}

.master-search-side-nav {
  .side-nav-item {
    &:hover {
      background-color: transparent !important;
      cursor: default !important;

      .side-nav-text {
        color: #686a7e !important;
        font-weight: 400;
      }
    }

    .side-nav-item.active {
      .active {
        background-color: transparent !important;
      }
    }

    .side-nav-text {
      color: #686a7e !important;
      font-weight: 400 !important;
      background-color: transparent !important;
    }
  }
}

.layout-content-wrapper {
  height: calc(100% - 57px);
  overflow: auto;
}

.layput-actions {
  padding: 14px 30px;
  background: rgb(244 244 244 / 50%);
  border-top: 1px solid #dddde9;
}

.layout-content {
  height: 100%;
  overflow: auto;

  &.read-only > :not(.references-activity) {
    color: gray !important;

    :not(.references-activity) * {
      color: gray !important;
    }

    &:hover > :not(.references-activity) * {
      color: gray !important;
      pointer-events: none;
    }
  }
}

.layout-content.layout-with-actions {
  height: calc(100% - 74px);
}

.tab-content {
  padding: 20px;
}

.tab-view-content {
  flex: 2 0;
}

.tab-view-right-nav {
  flex: 1 0;
}

.sub-menu-master {
  margin-left: 20px;
}
