@import '/src/variables';

.job-container {
  background-color: $palette-bg-main;
  min-height: 99vh;
  width: 100%;
}

.job-title-container {
  background-color: $white;
  width: 100%;
  display: flex;
  margin-top: 10vh;
}

.card-button {
  z-index: 0;
}
