@import '/src/variables';

#document {
  .doc-container {
    overflow-y: auto;
    max-height: 70vh;
  }
}

#add-document {
  .formParent {
    form {
      position: relative;
      border: 2px dashed $dash-border-color;
      height: 18vh;
      width: 18vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      // gap: 20px;
    }

    form p {
      text-align: center;
    }

    form input {
      cursor: pointer;
      position: absolute;
      margin: 0;
      padding: 0;
      outline: none;
      opacity: 0;
    }

    form button:active {
      border: 0;
    }

    .uploadFileOutlinedIcon {
      color: $cool-gray;
    }
  }

  .upload-file-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      color: $palette-heading-main;
      font-size: 16px;
    }
  }

  .add-another {
    width: fit-content;
  }

  .name-input-box {
    width: 18vw;
  }

  .AutoCompleteStyle {
    width: 18vw;
  }

  .paper-container {
    background-color: $gray-lighter;
    border-radius: 8px;
  }
}
