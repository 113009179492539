.bd-view-popup-content.bd-view-popup-content {
  width: 90vw !important;
  height: 90vh !important;
  background: #f5f5f6 !important;

  .bd-view {
    height: 100% !important;
    overflow: auto;
  }

  .bd-view-header {
    height: 67px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: #c3c3c35c;

    p {
      flex-grow: 1;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #31394d;
    }
  }
}

.bd-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bd-details {
  margin-bottom: 25px;
  padding-right: 10px;
  width: 100%;
  align-items: flex-end;
}

.bd-details:last-child {
  margin-right: 0;
}

.bd-details-label {
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.09em !important;
  text-transform: uppercase;
  flex: 1.25 0;
}

.bd-details-value {
  font-size: 14px;
  line-height: 16px;
  color: #31394d;
  flex: 2 0;
}

.bd-view-content {
  background: #fff;
  padding: 30px;
  height: calc(100% - 117px);
  overflow: auto;
}

.bd-view-footer {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  // background: #c3c3c35c;

  .section-icon {
    color: #f1a06b;
  }

  .edit-details-button {
    color: #2a364c;
  }
}
