@import '/src/variables';

#card-slider-component {
  overflow-y: auto;
  display: contents;

  .card-slider-container {
    overflow: hidden;
  }

  .card-Container {
    display: flex;

    // gap: 10px;
    flex-direction: row;

    .arrowIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 5%;
    width: inherit;

    .MuiIconButton-root:hover {
      color: $palette-primary-main;
    }
  }
  .visible {
    visibility: visible;
  }
  .invisible {
    visibility: hidden;
  }
}
