@import '/src/variables';

#professional_experience {
  .industry-job-layout {
    width: 100%;
    min-height: 21vh;
    height: auto;
    display: flex;
    align-items: center;
    gap: 30px;
    background-color: $gray-lighter;

    .compensation-typo {
      color: $palette-heading-main;
    }
  }

  .work-experience-layout {
    width: 100%;
    height: auto;
    margin-top: 30px;
    background-color: $white;

    .work_experience_cards {
      width: 100%;
      height: fit-content;
      border-radius: 8px;
      background-color: $gray-lighter;
    }

    .add-document #button-add {
      width: 7vw;
    }
  }
}

/* .country-tab-change {
  .css-1q60rmi-MuiAutocomplete-endAdornment {
    top: calc(50% - 24px);
  }
} */

/* IndustrySelection */
#industrySelection {
  .industry-list {
    height: 40px;
    position: relative;

    .check-shift {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

/* AddWorkExperience */
#addWorkExperience {
  .footer-submit-item {
    display: flex;
    gap: 10px;
  }

  .MuiInputBase-root {
    height: 40px;
  }
}

.work-experience-input {
  height: 40px;
  width: 300px;
}

.board-gap {
  column-gap: 20px;
}

.error-message {
  color: $palette-error-main;
}

.work_section-content {
  // column-gap: 10px;
  // row-gap: 10px;
  background-color: $gray-lighter;
  border-radius: 8px;
}

.text-color {
  color: $palette-heading-main;
}

.check-gap {
  column-gap: 130px;
}

.close-icon {
  cursor: pointer;
}

.date-responsive {
  .DatePickerStyle .MuiInputBase-root {
    width: 300px;
  }
}

@media (width >=768px) and (width <=1023px) {
  .work-container {
    width: 426px;
    height: 400px;
  }

  .input-field {
    min-width: 138px;
  }

  .check-gap {
    column-gap: 59px;
  }

  .work-experience-input {
    height: 40px;
    width: 190px;
  }

  .auto-responsive {
    .MuiAutocomplete-root {
      width: 190px;
    }
  }

  .date-responsive {
    .DatePickerStyle .MuiInputBase-root {
      max-width: 190px;
    }
  }
}

.info-panel {
  width: 100%;
  display: block;
  text-align: left;
  padding: 10px;
  background: #ccd6db;
  border-left: 3px solid #1b4965;
  border-radius: 3px;
}
