@import '/src/variables';

#Contact_view_drawer {
  // z-index: 9999;
  .MuiDrawer-paper {
    background: rgb(240 241 246) !important;
  }

  .drawer-body {
    height: 100vh;
    justify-content: right;
  }

  .contact-details-head {
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    background-color: transparent;
    box-shadow: none !important;

    .sub-contact-details {
      background-color: $bluish-gray !important;
      height: 100%;
    }
  }
}

.drawer-head {
  flex-shrink: 0;
  background-color: transparent;
}

.contact-details-container .contact-details-candidate-score-ai-rating p.MuiTypography-root {
  order: 1;
  margin-top: 20px;
}

.contact-details-container .contact-details-candidate-score-ai-rating {
  max-width: 33.3%;
  flex-direction: column;
  text-align: center;
}

.contact-details-box::after {
  position: absolute;
  content: '';
  background: #eee;
  height: 70px;
  width: 1px;
  right: -23px;
  top: 21px;
}

.contact-details-box:last-child::after {
  display: none;
}

.candidate-score-switch.SwitchStyle .MuiSwitch-thumb {
  background-color: #b8b8b8;
}

.candidate-score-switch.SwitchStyle .MuiSwitch-track {
  opacity: 1;
  margin-top: 3px;
  height: 11px;
  background-color: #b8b8b8;
}

.candidate-score-switch.SwitchStyle .MuiSwitch-thumb::before {
  display: none;
}

.mark-my-styles .MuiSlider-mark:nth-child(8) {
  margin-left: -6px;
}

.mark-my-styles {
  width: calc(100% - 20px);
  margin-left: 12px;
  margin-right: 10px;
}

.contact-details-box span.ai-r-label {
  max-width: 160px !important;
  display: -webkit-box;
  padding: 0 !important;
}

.contact-details-container .contact-details-box span.ai-r-label {
  max-width: 100% !important;
}
