@import '/src/variables';

#company-tab-container {
  &.list-contacts {
    height: calc(100vh - 130px);

    /* .range-label {
      margin-top: 3px;
      margin-bottom: 0;
    } */
  }

  // .ag-theme-alpine .ag-header-cell,
  // .ag-theme-alpine .ag-header-group-cell {
  //   padding-left: 9px;
  //   padding-right: 18px;
  // }

  .industry-content div {
    // white-space: break-spaces;
  }

  .tool-bar {
    flex-grow: 1;
  }

  .MuiPaper-root {
    background-color: $white;
    box-shadow: none;
  }

  .disabled-link {
    fill: $gray;
    cursor: not-allowed;
  }

  .MuiToolbar-root {
    padding: 0;
    min-height: fit-content;
  }

  .company_name {
    font-weight: 800;
  }

  .company-display {
    display: inline;
  }

  .company-display:hover {
    cursor: pointer;
  }

  .add-button {
    text-transform: none;
    background-color: $palette-primary-main;
    color: $white;
    text-align: left;
    line-height: normal;
    height: 40px;
  }

  .linkedin-icon-header {
    fill: $light-bluish;
    cursor: pointer;
  }

  .cell-text {
    // color: $black;
  }

  .details-width {
    box-sizing: border-box;

    // color: $black;

    a:hover {
      // color: $black;
    }
  }

  .link {
    // color: $black;
    text-decoration-color: $black;
  }

  .fs-14.company_name {
    font-size: 0.875rem;
  }

  .content-wrap {
    text-align: left;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // color: $black;
  }

  .ag-theme-alpine {
    .ag-checkbox .ag-input-wrapper {
      font-size: 19px;
    }

    .ag-cell-value {
      // display: grid !important;
    }

    .ag-checkbox-input-wrapper {
      font-size: 18px;
      line-height: normal;

      input {
        cursor: pointer;
        width: 20px !important;
        height: 20px !important;
      }
    }

    // .ag-react-container {
    //   display: flex;
    //   justify-content: left;
    // }

    .ag-ltr {
      .ag-selection-checkbox {
        margin-right: 0;
      }

      // .ag-cell-wrapper {
      //   justify-content: center;

      //   /* override ag grid theme styles */
      //   .ag-selection-checkbox {
      //     margin-right: 0;
      //   }
      // }
    }
  }

  // .ag-theme-alpine .ag-cell-value {
  //   .tag-container {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 3px;
  //     align-items: center;
  //   }
  //   .tag-pointer {
  //     cursor: pointer;
  //   }
  // }
  .tag-border {
    text-decoration: underline;
    text-align: center;
    width: fit-content;
  }
}

#change-status {
  .status-dropdown {
    width: 25vw;
  }

  .tag-auto-complete {
    width: 100%;
  }
}
