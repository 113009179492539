@import '/src/variables';

.fileUploader {
  .font-size {
    word-break: break-all;
    color: $palette-primary-main;
  }
  label {
    margin-bottom: 0 !important;
  }

  .formParent {
    border-radius: 4px;
    height: 5vh;
    // width: 300px;
    border: 1px solid $palette-primary-main;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    /* span {
      font-size: 14px;
    } */
  }

  .formParent:hover {
    background-color: $palette-primary-main;

    .font-size {
      word-break: break-all;
      color: $white;
    }
  }
}
