@import '/src/variables';
.bold {
  font-weight: bold;
}
.light {
  font-weight: bold;
  color: $gray;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.justify-content-space-between {
  justify-content: space-between;
}
.job-view-container {
  background-color: $white;
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  .job-view-header {
    display: flex;
    flex-direction: row;
    .job-title-part {
      line-height: 1rem;
    }
    .stat-part {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .stat {
        .job-number-id {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
.tab-view {
  background-color: $palette-bg-main;
  .tab-title {
    min-width: 5vw;
    width: fit-content;
    color: $palette-primary-main;
  }
}
.job-posting {
  width: 100%;
}
.button-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
