@import '/src/variables';

.DatePickerStyle {
  .MuiInputBase-input {
    padding: 5px 14.5px;

    // height: 32px;
  }

  // .css-2a2z0p-MuiFormLabel-root-MuiInputLabel-root{
  //     font-size: 0.75rem !important;
  //     margin-top: -4px;
  // }
  .css-2a2z0p-MuiFormLabel-root-MuiInputLabel-root .Mui-focused {
    color: $palette-heading-main;
  }

  .Mui-focused {
    color: $palette-heading-main;

    .MuiOutlinedInput-notchedOutline {
      border-color: $palette-heading-main;
    }
  }

  .MuiFormLabel-root {
    font-size: 0.875rem;
    margin-top: -6px;
  }

  .MuiOutlinedInput-root {
    height: 100%;
  }

  .required::after {
    content: '*';
    color: $error-color;
    margin-left: 5px;
  }
}

.DatePickerRequired {
  .required::after {
    content: '*';
    color: $error-color;
    margin-left: 5px;
  }
}
