@import '/src/variables';

#custom-popup {
  .MuiPaper-root {
    // min-width: 42vw;
    max-width: stretch;
    overflow: unset;
  }

  .custom-popup-title {
    padding: 0 !important;
    font-weight: bold;
    font-size: 16px;
    color: $palette-heading-main !important;
  }

  .icon-section {
    display: flex;
    align-items: center;
  }

  .close-icon {
    font-size: 20px;
    height: 24px;
    width: 24px;
  }

  .filter-popup {
    border-radius: $border-radius;
    background-color: $gray-lighter !important;
  }

  .filter-text {
    font-weight: bolder !important;
  }

  .filter-text-heading {
    color: $palette-heading-main;
  }

  .search-bar {
    background-color: $white !important;
    border: 1px solid $cool-gray;
    border-radius: $border-radius;

    .MuiAutocomplete-root {
      width: 30vw;
      display: flex;
      align-items: center;
    }
  }

  .dialog-content {
    overflow: unset;
  }
}
