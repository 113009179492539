@import '/src/variables';

#team-info {
  overflow: auto;

  .tab-view-content {
    flex: 2 0;

    // .team-info-table-wrapper {
    //   max-height: 425px;
    // }
  }

  // .team-info-table {
  //   border: none;
  //   background: $white;
  //   box-shadow: 0 0 8px $box-shadow-team;
  //   overflow: hidden;
  //   border-radius: $border-radius;
  //   white-space: nowrap;
  // }

  .ign-team-information-table {
    background: #fff;
    box-shadow: 0 0 8px rgb(219 226 243 / 60%);
    border-radius: 4px;
    border: 1px solid #808080;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    padding: 0;
  }

  .ign-team-information-table.revenue-table {
    text-align: right;
  }

  .accordion {
    background-color: $palette-primary-main;
  }

  .title-text {
    color: $white;
  }

  .read-only {
    color: $gray;
  }

  .layout-content {
    height: 100%;
    overflow: auto;

    &.read-only > :not(.references-activity) {
      color: $gray;

      :not(.references-activity) * {
        color: $gray;
      }

      &:hover > :not(.references-activity) * {
        color: $gray;
        pointer-events: none;
      }
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: $white;
  }

  .profile-image-container {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .image-containers {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $image-border;
    display: flex;
    justify-content: center;
  }

  .css-vn73je-MuiTableCell-root {
    text-align: center;
  }

  .table-header {
    background: $content-background;
    font-weight: 700;
    line-height: 18px;
    color: $palette-heading-main;
    text-align: center;
  }

  .table-heading {
    line-height: 20px;
    color: $palette-title-bar-main;
    padding: 10px 20px;
    background: $palette-primary-main;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .divider-hr {
    height: 2px;
    width: 100%;
    border-width: 0;
    background-color: $palette-primary-main;
  }

  .close-icon {
    color: $palette-error-main;
  }

  .total-content {
    color: $gray;
  }

  .css-1s1ppv9-MuiFormHelperText-root.Mui-error {
    color: $error-color;
  }

  .validation-error {
    .sum-value {
      color: $error-color;
    }
  }

  .text-red {
    color: $error-color;
  }

  #percentage-table {
    .MuiPaper-root {
      width: 50vw;
    }
  }
}

#education-experiences .DatePickerStyle .MuiInputBase-root {
  width: 18vw !important;
}

#education-experiences .billing-notes-field .MuiInputBase-root {
  width: 38vw;
}

.no-data-added {
  justify-content: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 3px;
}

.team-add-button button {
  min-width: 180px;
  margin-bottom: 4px;
  margin-left: 11px;
}

.team-info-table-input {
  input {
    text-align: center;
  }
}

.title-basis {
  position: absolute;
  top: -40px;
}
