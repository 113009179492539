@import '/src/variables';

.athena-container {
  background-color: $palette-bg-main;
  min-height: 90vh;
  width: 100%;
}

.athena-title-container {
  background-color: $white;
  width: 100%;
  display: flex;
}

.athena-header {
  background-color: $palette-primary;
  min-height: 8vh;
}

.header-logo {
  width: 10vw;
  color: $white;
}

.header-text-white {
  color: $white !important;
}

.progress-container {
  position: relative;
}

.progress-label {
  min-width: 10px;
}

.progress-label-next {
  position: absolute;
  left: 9px;
}

.progress-label-back {
  position: absolute;
  left: 13px;
}

.progress-visited {
  color: $palette-primary !important;
}

.progress-current {
  color: $mint-green !important;
}

.progress-un-visited {
  color: $switch-dark-gray !important;
}

.question {
  background-color: rgba($palette-primary, 20%);
  z-index: 0;
}

.question-font {
  z-index: 2;
  color: $palette-heading-main;
}

.question-grid {
  min-height: 50vh;
}

.question-slider {
  height: 15px;
  color: rgba($palette-primary, 50%);
}

.question-skip {
  color: $error-color;
  text-decoration: underline;
}

.question-skip:hover {
  color: $error-color;
  text-decoration: underline;
  animation: none;
}

.athena-finish-button {
  background-color: $error-color;
  color: $white;
}

.athena-finish-button:hover {
  background-color: $error-color;
  color: $white;
}

.athena-question-box {
  background-color: $white !important;
}

.athena-survey-bg-p {
  background-color: rgba($palette-primary, 20%);
}

.athena-confirm-button {
  background-color: $palette-primary !important;
  color: $white;
}

.athena-confirm-button :hover {
  color: $white;
}
