/* CSS global variables */
$palette-primary-main: var(--palette-primary-main);
$palette-primary-main-5: var(--palette-primary-main-5);
$palette-primary-main-10: var(--palette-primary-main-10);
$palette-primary-main-20: var(--palette-primary-main-20);
$palette-primary-main-30: var(--palette-primary-main-30);
$palette-primary-main-40: var(--palette-primary-main-40);
$palette-primary-main-50: var(--palette-primary-main-50);
$palette-secondary-main: var(--palette-secondary-main);
$palette-heading-main: var(--palette-heading-main);

// $palette-primary-light: var(--palette-primary-light);
$palette-error-main: var(--palette-error-main);
$palette-bg-main: var(--palette-bg-main);
$palette-text-main: var(--palette-text-main);
$palette-title-bar-main: var(--palette-title-bar-main);

/* new theme colors */
$palette-primary: #009d81;
$mint-green: #23cea7;
$success-green: #2b3;
$cool-gray: #959fb7;
$error-color: #c3423f;
$indigo: #1b4965;
$orange-color: #ffa500;
$black: #000;
$white: #fff;
$gray: #808080;
$light-grey: #e0e4ec;
$or-divider: #f1f3f8;
$content-background: #f0f1f6;
$notification-menu-background: #fafafa;
$smoke-black: rgb(92 97 119 / 6%);
$dark-cerulean: #19628b;
$greenish-blue: #007e6b;
$reddish-orange: #b75306;
$divider-color: #f5f6fa;
$dark-gray: #333;
$tera-cotta: #2d97ca;
$pale-sky-blue: #c3e2f4;
$light-steel-blue: #959fb780;
$dark-slate-blue: #1b496580;
$frosted-white: #fcfcfcb8;
$soft-rose: #f7d2d1;
$vivid-orange: #dd7702;
$light-cream: #fff4c6;
$deep-crimson: #a33532;

// $curious-blue: #32b19a;
$light-radish: #943f0c;
$turquoise: #32b099;
$light-bluish: #0077b7;
$gray-lighter: #fbfcfc;
$transparent-white-color: rgb(255 255 255 / 0%);
$mojo: rgb(195 66 63 / 9%);
$persian-green: rgb(0 157 129 / 15%);
$light-orange: rgb(255 165 0 / 15%);
$cornflower-blue: rgb(149 159 183 / 9%);
$bluish-gray: rgb(149 159 183 / 10%);
$periwinkle-gray: rgb(149 159 183 / 19%);
$transparent-bluish-gray: rgb(236 240 243 / 29%);
$sepia-tone: rgb(148 63 12 / 4%);
$grayish-blue: rgb(149 159 183 / 3.5%);
$light-aqua: rgb(0 157 129 / 10%);
$light-black: rgb(0 0 0 / 15%);
$box-shadow: rgb(0 0 0 / 50%);
$side-nav-bar-gradient: linear-gradient(0deg, $white, 5%, $palette-primary-main);
$input-focus: #f1f4f5;
$switch-dark-gray: #949fb7;
$switch-back-gray: #c8cdda;
$switch-back-mint: #25cea7;
$switch-light-mint: #9fe5d1;
$tab-background-color: #dce3e9;
$hot-candidate: #ebb6b0;
$candidate1-tag-color: #7a350d;
$candidate1-tag-bg: rgb(122 53 13 / 24%);
$candidate2-tag-color: #4e5261;
$candidate2-tag-bg: rgb(220 227 233 / 24%);
$candidate3-tag-bg: rgb(27 73 101 / 24%);
$light-grayish-navy: #707692;
$strong-cyan: #016456;
$border-radius: 4px;
$border-radius-full: 50%;
$brick-orange: #c84006;
$box-shadow-team: rgb(219 226 243 / 60%);
$image-border: #dedee3;

/* MUI Icons */
$mui-notification-icon: $gray;
$mui-approval-icon: $gray;
$mui-help-icon: $gray;
$mui-profile-icon: $gray;
$mui-profile-color: $gray;
$dash-border-color: #c7c8c9;
$screen-icon-color: #8fccea;

:export {
  primary: $palette-primary;
  secondary: $mint-green;
  text: $dark-gray;
  heading: $indigo;
  error: $error-color;
  background: $content-background;
  titleBarBackground: $white;
  success: $mint-green;
  borderRadius: $border-radius;
  borderColor: $gray;
  white: $white;
  turquoise: $turquoise;
}
