.add-bd-view {
  height: 100%;
  overflow: auto;
}

.add-bd-header {
  padding: 20px 30px;
  background-color: #2a364c;
  height: 58px;
}

.add-bd-header-name {
  font-weight: bold;
  font-size: 19px;
  line-height: 28px;
  color: #fff;
  margin-right: auto;
}

.bd-close-icon {
  width: 24px;
  height: 24px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.add-bd-input {
  width: 50% !important;
  max-width: 100%;
  max-height: 64px;
  margin-bottom: 20px !important;
  padding-right: 20px !important;
  margin-left: 55px !important;
  border-radius: 0%;
  font-size: 14px !important;
  overflow: auto;

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    padding: 0;
  }

  .MuiOutlinedInput-root {
    border-radius: 0%;

    .MuiOutlinedInput-input {
      padding: 9px;
    }
  }
}

.outline {
  border: 1px solid black;
}

.add-bd-content {
  margin: 0 auto;
  padding: 36px 10px;
  height: calc(100% - 70px);
  overflow-y: auto;
  overflow-x: hidden;
}

.partner_container {
  flex: 1 0;
}

.add-bd-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 20px;
  background-color: #c3c3c35c;
  bottom: 0;
  position: absolute;
  width: 100%;

  .button {
    outline: none;
    width: 104px;
    margin-left: 38px;
    padding: 4px 10px;
  }
}

.bd-detail-item-fee {
  justify-content: center !important;

  .form-control-div {
    .add-bd-input-form-control {
      position: relative;
      right: 25px;
      margin-bottom: 20px !important;
      margin-right: 20px !important;
      margin-left: 55px !important;
      border-radius: 0%;
      font-size: 14px !important;
    }
  }
}

.bd-details-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 50%;

  .bd-input-label {
    margin-bottom: 20px;
    text-transform: capitalize;
    color: #000 !important;
    border-radius: 0% !important;
    font-size: 15px !important;
  }
}

.industry-label {
  font-size: 15px !important;
}

.outlined-box {
  border: 1px solid #ccc;
  border-radius: 0% !important;
  overflow: hidden;
  padding-left: 9px;
  width: 100% !important;

  &:hover {
    border: 1px solid #000;
  }
}

.outlined-box-show {
  padding-left: 9px;
  width: 100% !important;
}

#is_actual_overridden_revenue {
  text-align: right;
}

.MuiStepper-root {
  padding: 0%;
}

.overflow-hide {
  overflow: hidden !important;
}

.team-info-field {
  font-size: 14px;
}

.billing-note-size {
  font-size: 14px !important;
}
