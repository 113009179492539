.confirmation-popup-content.confirmation-popup-content {
  height: auto !important;
  width: auto !important;
}

.confirmation-content {
  padding: 20px;
  overflow: auto;

  .confirmation-header {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #31394d;
    white-space: normal !important;
  }

  .confirmation-message {
    font-size: 16px;
    line-height: 19px;
    color: #373956;
    margin-top: 10px;

    &.jobhistory-popup {
      font-size: 20px;

      .jobhistory-message-container {
        display: flex;

        .jobhistory-content {
          display: flex;
          flex-direction: column;
          font-size: 14px;

          &:last-child {
            text-indent: 2rem;
          }
        }
      }
    }
  }
}

.action-buttons {
  text-align: right;
  padding: 10px 20px;
  background: #f5f5f6;
  white-space: normal !important;
}
