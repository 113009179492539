@import '/src/variables';

#project-approval {
  .table-header {
    font-size: 15px;
    line-height: 20px;
    color: $white;
    background: $palette-primary;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .grid-container {
    height: 50vh;
  }

  // #project-approval-grid {
  //   .ag-theme-alpine {
  //     padding: 0 !important;

  //     .ag-header {
  //       border: 1px solid $palette-primary-main;
  //       border-top: none;
  //       background-color: $palette-primary-main;
  //       color: $white;

  //       .ag-header-cell {
  //         background-color: $palette-primary-main !important;
  //       }

  //       .ag-header-cell-text {
  //         color: $white;
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 13px;
  //         line-height: 18px;
  //       }

  //       .ag-header-cell-label {
  //         justify-content: center;
  //         background-color: $palette-primary-main;
  //       }
  //     }
  //   }
  // }

  .team-information-table {
    padding: 20px;
    border: none !important;
  }

  .search-approval-container {
    .search-header {
      height: 100px;
    }
  }
}
