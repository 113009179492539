@import '/src/variables';

#container-communication {
  flex-direction: column;

  .form-section {
    gap: 15px;
  }

  .communication-content {
    width: 60vw;
  }

  .communication-section {
    border-radius: 8px;
    display: flex;
    background-color: $grayish-blue;
    flex-flow: column wrap;
    gap: 32px;
  }

  .head-container {
    .sub-head-content {
      gap: 25px;
      flex-flow: row wrap;
    }
    //  .relation-container{

    //  }

    .label-text {
      background-color: $transparent-white-color;
      box-sizing: border-box;
      color: $palette-heading-main;
      text-align: left;
      line-height: normal;
      margin-bottom: 10px;
    }

    .checkbox-text {
      font-size: x-small;
      margin-top: 10px;
    }

    .input-field {
      border-radius: 4px !important;
      margin-top: 12px;
    }

    .input-field-data {
      min-width: 300px;
    }

    .add-another-button {
      align-self: center;
      display: inline-block;

      button {
        color: $turquoise;
        text-transform: capitalize;
      }
    }

    .remove-another-button {
      cursor: pointer;
      align-self: center;
      align-items: center;
    }

    .table-close-icon {
      color: $palette-error-main;
      cursor: pointer;
    }

    .sub-label-primary {
      height: 21%;
      margin-bottom: 10px;
    }

    .primary-checkbox {
      display: flex;
      position: relative;

      label {
        margin-right: 0 !important;
      }
    }
  }
  .my-cards {
    flex-direction: column;
    width: 100%;
  }

  .form-section {
    /* background-color: gray; */

    /* margin-bottom: 20px; */
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .section-content-data {
    background-color: $gray-lighter;
    border-radius: 8px;
    row-gap: 32px;
  }
  .content-gap {
    row-gap: 32px;
    column-gap: 32px;
  }

  .address-divider {
    border: 1px solid $divider-color;
  }
  .close-icon {
    color: $palette-error-main;
  }
  .set-position {
    margin-top: -10px;
  }

  @media only screen and (width >=768px) {
    .input-field-data-large {
      width: 300px;
    }
  }

  @media only screen and (width >=992px) {
    .input-field-data-large {
      width: 632px;
    }
  }

  @media only screen and (width >=1229px) {
    .input-field-data-large {
      width: 964px;
    }
  }

  .flip {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform: rotateX(180deg);
  }

  .flip2 {
    transform-style: preserve-3d;
    transform: rotateX(180deg);
  }
}
