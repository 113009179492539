@import '/src/variables';

.side-nav-container {
  transition: width 0.3s linear;
  width: 70px;
  // background-image: $side-nav-bar-gradient;
  background: $palette-primary-main;
  overflow: hidden auto;
}

.side-nav-container.open {
  width: 190px;
}

.rotate-icon-open {
  transform: rotate(-270deg);
}

.rotate-icon-closed {
  transform: rotate(-90deg);
}

.side-nav-items {
  width: fit-content;
}

.side-nav-items a:hover {
  text-decoration: none;
}

.side-nav-container.open .side-nav-items {
  display: flex;
}

.side-nav-bar {
  position: relative;
  overflow: hidden auto;
  top: calc(100vh - 86vh);
  align-items: center;
}

.hamburger-span {
  position: relative;
  background-color: $content-background;
}

.hamburger-icon {

  /* font-size: 30px !important; */
  cursor: pointer;

  /* margin-left: -16px; */
  color: $palette-title-bar-main;

  /* margin-top: 60vh; */
}

.arrow-icon {
  color: $palette-primary-main;
  transition: transform 0.4s ease-in-out;
  top: 40vh;
  right: 14px;
  background-color: $white;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  position: relative;
}

.nav-item p {
  opacity: 0.8;
}

.nav-item:hover p {
  opacity: 1;
}

.side-nav-top-actions {
  min-width: 237px;
}

.side-nav-container.open .add-new {
  visibility: visible;
}

/* .side-nav-group {
  margin-top: 30px;
  overflow: auto;
}

.side-nav-group:first-child {
  margin-top: 0;
}
 */
.nav-stack{
  height: 100%;
  justify-content: space-around;
  max-height: 670px;
  min-height: 550px;
}
.nav-item {
  cursor: pointer;
  white-space: nowrap;
  color: $palette-title-bar-main;
  font-weight: 500;
  padding: 5px;
  font-size: 15px;
  // height: 34px;
}
.nav-content{
  line-height: 1.1;
  padding-left: 8px !important;
}

.nav-item.active {
  color: $palette-heading-main;
  background: $palette-title-bar-main;
  width: auto;
  border-radius: $border-radius;
  box-shadow: 1px 2px 2px 0 $box-shadow;
}

.label-space {
  font-weight: 500;
}

.nav-item:hover {
  color: $palette-heading-main;
  background: $palette-title-bar-main;
 // height: 34px;
  border-radius: $border-radius;
  box-shadow: 1px 2px 2px 0 $box-shadow;
}

@media (width > 1439px) {
  .side-nav-container {
    width: 72px;
  }

  .arrow-icon {
    right: 14px;
  }
}
