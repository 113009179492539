@import '/src/variables';

.SingleSelectStyle {
  border-radius: 4px;
  //background-color: $white  ;
  color: $black;
  text-align: left;

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    // box-shadow: $palette-heading-main 0 0 0 1.5px;
    // border-color: transparent;
    border-radius: 4px;
    color: $black;
  }

  .MuiInputBase-root {
    padding: 1px;
  }

  .MuiOutlinedInput-notchedOutline {
    // border: 1px solid $palette-heading-main;
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 1px;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: wrap;
  }

  & .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 0;
  }

  & .MuiIconButton-root {
    padding: 3px 0;
    right: 9px;
  }

  /*   // .MuiAutocomplete-popupIndicator {
  //   transition: transform 0.4s ease;
  // }

  // .Mui-focused .MuiAutocomplete-popupIndicatorselected {
  //   transform: rotate(360deg);
  // } */
}
